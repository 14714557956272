import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import useQueryParams from "app/hooks/useQueryParams";
import { getWallets } from "app/api/wallets";
import { GetWalletsParams } from "app/api/wallets/types";
import { ProfileProject } from "app/models/profile";
import { useProject } from "app/providers/ProjectProvider";

interface Props {
  keepPreviousData?: boolean;
}

const useGetWalletDeposits = (props: Props = {}) => {
  const { keepPreviousData = false } = props;

  const project = useProject() as ProfileProject;
  const { queryParams, updateQueryParams } = useQueryParams<GetWalletsParams>({
    page: 1,
    type: "deposit",
  });

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ["wallets", ...Object.values(queryParams)],
    () => getWallets(project.id, queryParams),
    { keepPreviousData: keepPreviousData }
  );

  const goToNextPage = () => {
    if (data) {
      updateQueryParams("page", data.data.page + 1);
    }
  };

  const goToPreviousPage = () => {
    if (data) {
      updateQueryParams("page", data.data.page - 1);
    }
  };

  return {
    isLoading,
    isFetching,
    data: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getDeposits: refetch,
    goToNextPage,
    goToPreviousPage,
    queryParams,
    updateQueryParams,
  };
};

export default useGetWalletDeposits;
