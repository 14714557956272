import tw, { styled } from "twin.macro";

interface Props {
  semiBold?: boolean;
  medium?: boolean;
  big?: boolean;
}

const Header = styled.h1((props: Props) => {
  const { big, medium, semiBold } = props;

  return [
    tw`text-[2.8rem] font-bold`,
    big && tw`text-[3.6rem]`,
    medium && tw`text-[3rem]`,
    semiBold && tw`font-semibold`,
  ];
});

export default Header;
