import tw, { styled } from "twin.macro";
import { useNavigate } from "react-router-dom";

import Modal from "app/components/ui/Modal";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";

import useModifyWebhook from "../hooks/useModifyWebhook";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export default function DeleteWebhookModal(props: Props) {
  const { isOpen, close } = props;

  const { deleteHook, isDeleting } = useModifyWebhook();
  const navigate = useNavigate();

  const deleteWebHook = () => {
    deleteHook(undefined, {
      onSuccess: () => {
        close();
        navigate("/developers/webhooks");
      },
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={close}
      title="Delete webhook"
      subTitle="Deleting a webhook cannot be undone."
    >
      <ModalContent>
        <div className="main">
          <Text>
            After deleting this endpoint no future webhook will be sent to its
            URL.
          </Text>
        </div>

        <div className="actions">
          <Button variant="secondary" fullWidth onClick={close}>
            Cancel
          </Button>

          <Button
            variant="destructive"
            fullWidth
            onClick={deleteWebHook}
            loading={isDeleting}
          >
            Delete webhook
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  .main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 24px 32px 24px;

    p {
      ${tw`text-gray600`}
    }
  }

  .actions {
    display: flex;
    gap: 12px;
    padding: 24px 24px 24px 24px;

    ${tw`border border-t-gray200`}
  }
`;
