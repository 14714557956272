import { ButtonHTMLAttributes, ReactNode } from "react";
import tw, { styled } from "twin.macro";
import clsx from "clsx";

import Loader from "./Loader";
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ReactNode;

  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  variant?: "primary" | "destructive" | "secondary" | "tertiary" | "plain";
  text?: ReactNode;

  padded?: boolean;
}

function Button(props: React.PropsWithChildren<Props>) {
  const {
    loading,
    disabled,
    className,
    fullWidth,
    variant,
    text,
    padded,
    ...rest
  } = props;

  return (
    <ButtonEl
      disabled={loading || disabled}
      className={clsx({ fullWidth, padded }, variant, className)}
      {...rest}
    >
      {loading ? (
        <Loader size="20" className="loader" />
      ) : (
        <>
          {!!props.icon && <span>{props.icon}</span>}

          {text ?? props.children}
        </>
      )}
    </ButtonEl>
  );
}

const ButtonEl = styled.button`
  ${tw`flex items-center justify-center font-semibold text-base leading-[20px] h-[36px] px-[8px] rounded-[4px] gap-[8px] transition-colors border border-transparent`};

  ${tw`focus:outline-none focus:ring-[4px] focus:ring-offset-0`}

  svg {
    ${tw`w-[16px] h-[16px]`};
    path {
      ${tw`fill-current`};
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.padded {
    ${tw`px-[16px]`};
  }

  &.primary {
    ${tw`bg-primary600 text-white`};

    ${tw`hover:bg-primary700 hover:shadow-buttonHovered`};

    ${tw`focus:ring-primary100`};

    .loader {
      ${tw`border-t-white border-r-white`};
    }
  }

  &.secondary {
    ${tw`bg-white border-gray300 text-gray700`};

    ${tw`hover:border-neutral hover:shadow-buttonHovered`};

    ${tw`focus:ring-gray300`};

    .loader {
      ${tw`border-t-gray700 border-r-gray700`};
    }
  }

  &.destructive {
    ${tw`bg-red600 text-white`};

    ${tw`hover:border-red700 hover:bg-red700 hover:shadow-buttonHovered`};

    ${tw`focus:ring-red100`};

    .loader {
      ${tw`border-t-white border-r-white`};
    }
  }

  &.tertiary {
    ${tw`focus:ring-gray300`};

    .loader {
      ${tw`border-t-gray900 border-r-gray900`};
    }
  }

  &.plain {
    ${tw`text-primary900 hover:text-primary800 inline px-[0px]`}
  }

  &:disabled {
    ${tw`opacity-50 shadow-buttonDefault`};
  }
`;

export default Button;
