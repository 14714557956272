import tw, { styled } from "twin.macro";

import Badge from "app/components/ui/Badge";
import SubHeader from "app/styles/SubHeader";
import CodeView from "../../../components/ui/CodeView";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";

import { ReactComponent as RefreshIcon } from "app/assets/icons/refresh.svg";
import { WebhookEventsItem } from "app/models/webhook";

import useModifyWebhook from "../hooks/useModifyWebhook";

interface Props {
  event: WebhookEventsItem;
}
export default function EventView({ event }: Props) {
  const isSuccess = event.http_status < 400 && event.http_status !== 0;

  const { isResendingEvent, resendWebhookEvent } = useModifyWebhook();

  return (
    <Container>
      <div tw="px-[24px] mb-[8px]">
        <div tw="flex items-center justify-between">
          <SubHeader big bold tw="text-textPrimary">
            {event.type}
          </SubHeader>

          <div tw="flex">
            <Button
              variant="secondary"
              text="Resend"
              icon={<RefreshIcon />}
              onClick={() => resendWebhookEvent(event.id)}
              loading={isResendingEvent}
            />

            {/* <Menu
              options={[
                {
                  content: "Retry",
                  onAction: () => resendWebhookEvent(event.id),
                },
              ]}
            /> */}
          </div>
        </div>

        <SubHeader tw="mt-[24px] mb-[16px]">Response</SubHeader>

        <Badge status={isSuccess ? "success" : "error"}>
          {event.http_status} {isSuccess ? "OK" : "ERR"}
        </Badge>
      </div>

      {event.response_body && Object.keys(event.response_body).length > 0 ? (
        <CodeView
          lang="json"
          code={JSON.stringify(event.response_body, null, 2)}
        />
      ) : (
        <EmptyData>
          <Text small>No response body</Text>
        </EmptyData>
      )}

      <SubHeader tw="px-[24px] py-[16px] w-full border-t border-t-neutral">
        Request
      </SubHeader>

      <CodeView
        lang="js"
        code={`${JSON.stringify(event.request_body, null, 2)}`}
      />
    </Container>
  );
}

const Container = styled.div`
  ${tw`pt-[24px]`};
`;

const EmptyData = styled.div`
  ${tw`p-[24px] flex items-center justify-center text-textSecondary`};
`;
