import { Wallet } from "app/models/wallet";
import request from "../request";
import {
  CreateWalletPayload,
  GetBalanceSummaryParams,
  GetBalanceSummaryResponse,
  GetWalletsParams,
  GetWalletsResponse,
} from "./types";
import { BaseResponse } from "../types";

export const getWallets = (projectID: string, params: GetWalletsParams) => {
  return request.get<GetWalletsResponse>(`/projects/${projectID}/wallets`, {
    params,
  });
};

export const getWallet = (projectID: string, walletID: string) => {
  return request.get<Wallet>(`/projects/${projectID}/wallets/${walletID}`);
};

export const createWallet = (
  projectID: string,
  payload: CreateWalletPayload
) => {
  return request.post<BaseResponse>(`/projects/${projectID}/wallets`, payload);
};

export const archiveWallet = (projectID: string, walletID: string) => {
  return request.post<BaseResponse>(
    `/projects/${projectID}/wallets/${walletID}/archive`
  );
};

export const unArchiveWallet = (projectID: string, walletID: string) => {
  return request.post<BaseResponse>(
    `/projects/${projectID}/wallets/${walletID}/unarchive`
  );
};

export const getBalanceSummary = (
  projectID: string,
  params: GetBalanceSummaryParams
) => {
  return request.get<GetBalanceSummaryResponse>(
    `/projects/${projectID}/balances/summary`,
    { params }
  );
};
