import Pagination from "./ui/Pagination";

interface Props {
  currentPage: number;
  totalPages: number;
  goToNext: VoidFunction;
  goToPrev: VoidFunction;
}

const PaginationWrapper = (props: Props) => {
  const {
    currentPage: page,
    totalPages: total_pages,
    goToNext,
    goToPrev,
  } = props;

  return (
    <Pagination
      currentPage={page.toString()}
      totalPages={total_pages.toString()}
      disableNext={total_pages <= page}
      disablePrevious={page <= 1}
      nextPageAction={goToNext}
      previousPageAction={goToPrev}
    />
  );
};

export default PaginationWrapper;
