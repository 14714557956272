import tw, { styled } from "twin.macro";

import Modal from "app/components/ui/Modal";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";

import useModifyWebhook from "../hooks/useModifyWebhook";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export default function DisableWebhookModal(props: Props) {
  const { isOpen, close } = props;

  const { disable, isDisabling } = useModifyWebhook();

  const disableHook = () => {
    disable(undefined, {
      onSuccess: close,
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={close}
      title="Disable a webhook endpoint"
      subTitle="Disabling a webhook is temporary, you can always re-enable."
    >
      <ModalContent>
        <div className="main">
          <Text>
            This webhook endpoint may be temporarily disabled so that it will
            not receive notifications until it is enabled again.
          </Text>

          <Text>
            Raven will not retry any notifications that are generated while the
            endpoint is disabled.
          </Text>
        </div>

        <div className="actions">
          <Button variant="secondary" fullWidth onClick={close}>
            Cancel
          </Button>

          <Button
            variant="destructive"
            fullWidth
            onClick={disableHook}
            loading={isDisabling}
          >
            Disable webhook endpoint
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  .main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 24px 32px 24px;

    p {
      ${tw`text-gray600`}
    }
  }

  .actions {
    display: flex;
    gap: 12px;
    padding: 24px 24px 24px 24px;

    ${tw`border border-t-gray200`}
  }
`;
