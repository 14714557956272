import useCryptoCurrencies from "app/hooks/useCryptoCurrencies";
import * as React from "react";

export default function DashboardHooksCall(props: React.PropsWithChildren) {
  const { children } = props;

  useCryptoCurrencies();

  return <>{children}</>;
}
