import {
  DisableTwoFAPayload,
  EnableTwoFAPayload,
  GetProfileResponse,
  GetTwoFAResponse,
  UpdateProfilePayload,
} from "./types";

import request from "../request";
import { BaseResponse } from "../types";

export const getProfile = () => {
  return request.get<GetProfileResponse>("/me");
};

export const updateProfile = (payload: UpdateProfilePayload) => {
  return request.put<BaseResponse>("/me", payload);
};

export const getTwoFA = () => {
  return request.get<GetTwoFAResponse>(`/me/two_fa`);
};

export const enableTwoFA = (payload: EnableTwoFAPayload) => {
  return request.post<BaseResponse>(`/me/two_fa`, payload);
};

export const disableTwoFA = (payload: DisableTwoFAPayload) => {
  return request.delete<BaseResponse>(`/me/two_fa`, { data: payload });
};
