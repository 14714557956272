import React, { ButtonHTMLAttributes } from "react";
import tw, { css } from "twin.macro";
import styled from "@emotion/styled";
import copyToClipboard from "app/lib/copyToClipboard";
import Card from "app/components/ui/Card";
import Text from "app/styles/Text";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

export default function CopyButton(props: Props) {
  const { text, ...rest } = props;
  const [isCopied, setIsCopied] = React.useState(false);

  const copy = () => copyToClipboard(text).then(() => setIsCopied(true));

  React.useEffect(() => {
    let id: any;
    if (isCopied) {
      id = setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }

    return () => {
      clearTimeout(id);
    };
  }, [isCopied]);

  return (
    <ButtonEl {...rest} onClick={copy} disabled={isCopied}>
      <CardStyle isCopied={isCopied}>
        <Text semiBold>{isCopied ? "Copied" : "Copy"}</Text>
      </CardStyle>
    </ButtonEl>
  );
}

const CardStyle = styled(Card)<{ isCopied?: boolean }>`
  padding: 8px;
  border-radius: 4px;
  ${tw`transition-colors ease-in-out duration-500`};
  ${(props) =>
    props.isCopied
      ? css`
          ${tw`bg-gray200 border-gray300 text-red50`}
        `
      : css``}
`;

const ButtonEl = styled.button``;
