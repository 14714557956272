import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { createProject } from "app/api/project";
import { useToast } from "app/providers/ToastProvider";

export default function useCreateProject() {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const handleSuccess = (message: string) => {
    queryClient.invalidateQueries(["projects"]);
    queryClient.invalidateQueries(["profile"]);
    showToast("", message, "success");
  };

  const handleError = (error: AxiosError<{}>) => {
    const errorMessage = parseError(error) as string;
    showToast("", errorMessage, "error");
  };

  const { isLoading: isCreating, mutate: createNewProject } = useMutation(
    createProject,
    {
      onSuccess: (_, v) =>
        handleSuccess(`Project ${v.name} created successfully`),
      onError: handleError,
    }
  );

  return { isCreating, createProject: createNewProject };
}
