import React, { Fragment, PropsWithChildren } from "react";
import { Dialog, Transition } from "@headlessui/react";
import tw, { styled } from "twin.macro";
import clsx from "clsx";
import { ClassNames } from "@emotion/react";

import { ReactComponent as Cancel } from "app/assets/icons/x-close.svg";
import SubHeader from "app/styles/SubHeader";
import Text from "app/styles/Text";

interface Props {
  open: boolean;
  onClose: VoidFunction;
  title?: React.ReactNode;
  subTitle?: string;
  showClose?: boolean;
  className?: string;
}
export default function Modal(props: PropsWithChildren<Props>) {
  const {
    open,
    onClose,
    children,
    title,
    showClose = true,
    subTitle,
    className,
  } = props;

  const showHeader = Boolean(title || showClose);

  return (
    // @ts-ignore
    <Transition show={open} as={Fragment}>
      <Dialog onClose={onClose} as={DialogEl}>
        {({ open }) => (
          <>
            <ClassNames>
              {({ css }) => (
                //  @ts-ignore
                <Transition.Child
                  as={Fragment}
                  enter={css(tw`transition-opacity ease-out duration-500`)}
                  enterFrom={css(tw`opacity-0`)}
                  enterTo={css(tw`opacity-100`)}
                  leave={css(tw`ease-in duration-200`)}
                  leaveFrom={css(tw`opacity-100`)}
                  leaveTo={css(tw`opacity-0`)}
                >
                  <Backdrop aria-hidden="true" />
                </Transition.Child>
              )}
            </ClassNames>

            <Container>
              <div className="body">
                <ClassNames>
                  {({ css }) => (
                    // @ts-ignore
                    <Transition.Child
                      as={Fragment}
                      enter={css([tw`transition ease-out duration-300`])}
                      enterFrom={css([tw`opacity-0 scale-95`])}
                      enterTo={css([tw`opacity-100 scale-100`])}
                      leave={css(tw`transition ease-in duration-200`)}
                      leaveFrom={css([tw`opacity-100 scale-100`])}
                      leaveTo={css([tw`opacity-0 scale-95`])}
                    >
                      <Dialog.Panel
                        as={DialogContent}
                        className={clsx({ open }, className)}
                      >
                        {showHeader ? (
                          <div
                            className={clsx("header", { "no-title": !title })}
                          >
                            <div>
                              {title ? (
                                typeof title === "string" ? (
                                  <Dialog.Title as={SubHeader} medium>
                                    {title}
                                  </Dialog.Title>
                                ) : (
                                  <Dialog.Title>{title}</Dialog.Title>
                                )
                              ) : null}
                              {subTitle ? (
                                <Dialog.Title as={Text} tw="text-gray600">
                                  {subTitle}
                                </Dialog.Title>
                              ) : null}
                            </div>

                            {showClose ? (
                              <button
                                type="button"
                                aria-label="Close"
                                onClick={onClose}
                              >
                                <Cancel />
                              </button>
                            ) : null}
                          </div>
                        ) : null}

                        {children}
                      </Dialog.Panel>
                    </Transition.Child>
                  )}
                </ClassNames>
              </div>
            </Container>
          </>
        )}
      </Dialog>
    </Transition>
  );
}

const DialogEl = styled.div`
  ${tw`relative z-50`};
`;

const Backdrop = tw.div`fixed inset-0 bg-[rgba(52, 64, 84, 0.7)] backdrop-blur`;

const Container = styled.div`
  ${tw`fixed inset-0 overflow-y-auto mobile:overflow-y-hidden`};

  > div.body {
    ${tw`flex min-h-full items-center justify-center mobile:block`};
  }
`;
Container.displayName = "ModalContainer";

const DialogContent = styled.div`
  ${tw` w-mobile min-h-[200px] max-w-full rounded-[12px] bg-white shadow-[0px 0px 4px rgba(0, 0, 0, 0.1), 0px 8px 40px rgba(0, 0, 0, 0.2)]`};

  /* ${tw`mobile:translate-y-[100vh] mobile:min-h-0`}; */

  > .header {
    ${tw`py-[16px] pr-[16px] pl-[20px] flex items-center justify-between border-b border-b-gray200`};

    &.no-title {
      ${tw`border-b-0 justify-end`};
    }

    > button {
      ${tw`w-[36px] h-[36px] self-start flex items-center justify-center text-gray500`};

      svg {
        ${tw`w-[20px] h-[20px] text-inherit`};

        path {
          ${tw`fill-current`};
        }
      }
    }
  }
`;
