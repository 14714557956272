import tw, { styled } from "twin.macro";

import Button from "./Button";
import Text from "app/styles/Text";

interface Props {
  totalPages: string;
  currentPage: string;

  disablePrevious?: boolean;
  disableNext?: boolean;

  previousPageAction: () => void;
  nextPageAction: () => void;
}

export default function Pagination(props: Props) {
  const {
    totalPages,
    currentPage,
    previousPageAction,
    nextPageAction,
    disablePrevious,
    disableNext,
  } = props;

  return (
    <Container>
      <Text tw="text-gray700">
        Page {currentPage} of {totalPages}
      </Text>

      <div className="actions">
        <Button
          variant="secondary"
          onClick={previousPageAction}
          disabled={disablePrevious}
        >
          Previous
        </Button>

        <Button
          variant="secondary"
          onClick={nextPageAction}
          disabled={disableNext}
        >
          Next
        </Button>
      </div>
    </Container>
  );
}

const Container = styled.div`
  ${tw`py-[12px] px-[24px] flex justify-between items-center`};

  .actions {
    display: flex;
    gap: 12px;
  }
`;
