import { forwardRef, InputHTMLAttributes, useState } from "react";
import tw, { styled } from "twin.macro";
import clsx from "clsx";

import Text from "app/styles/Text";
import uniqid from "app/lib/uniqid";
import { ReactComponent as AlertIcon } from "app/assets/icons/alert-circle.svg";
import { ReactComponent as EyeIcon } from "app/assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "app/assets/icons/eye-off.svg";

type HTMLInputType = InputHTMLAttributes<HTMLInputElement>;

interface Props extends HTMLInputType {
  label: string;
  labelHidden?: boolean;
  assistiveText?: string;
  error?: boolean;
  errorMessage?: string;
  containerClassName?: string;
}
function TextField(props: Props, ref: any) {
  const {
    label,
    labelHidden = false,
    id = uniqid(),
    containerClassName,
    assistiveText,
    error,
    errorMessage,
    disabled,
    type,
    ...rest
  } = props;

  const isPasswordType = type === "password";
  const [showText, setShowText] = useState(false);
  const toggleShowText = () => setShowText((s) => !s);

  return (
    <Container className={containerClassName}>
      <label htmlFor={id} className={clsx({ hidden: labelHidden })}>
        {label}
      </label>

      <InputContainer className={clsx({ error, disabled })}>
        <input
          ref={ref}
          id={id}
          disabled={disabled}
          type={isPasswordType ? (showText ? "text" : "password") : type}
          {...rest}
        />

        {error && <AlertIcon />}

        {isPasswordType && !error && (
          <button
            aria-label="Toggle password visiblity"
            type="button"
            onClick={toggleShowText}
          >
            {showText ? <EyeOffIcon /> : <EyeIcon />}
          </button>
        )}
      </InputContainer>

      {!!assistiveText ? (
        <Text className="assistive-text">{assistiveText}</Text>
      ) : null}

      {!!errorMessage ? (
        <Text className="error-message">{errorMessage}</Text>
      ) : null}
    </Container>
  );
}

export default forwardRef(TextField);

const Container = styled.div`
  ${tw`max-w-full`};

  > label {
    ${tw`block mb-[6px] text-base font-medium text-gray700`};

    &.hidden {
      ${tw`hidden`};
    }
  }

  > p {
    ${tw`mt-[6px]`};

    &.assistive-text {
      ${tw` text-gray600`};
    }

    &.error-message {
      ${tw`text-red700`};
    }
  }
`;

const InputContainer = styled.div`
  ${tw`w-full flex items-center px-[14px] py-[10px] h-[40px] rounded-[8px] border border-gray300 shadow-xs focus-within:border-primary700 transition-colors`};

  &.error {
    ${tw`border-red500 shadow-xsError100`};

    svg {
      ${tw`w-[16px] h-[16px] text-red700`};
      path {
        ${tw`stroke-current`};
      }
    }
  }

  &.disabled {
    ${tw`bg-gray50 shadow-xs border-gray300`};
  }

  > button {
    svg {
      ${tw`w-[16px] h-[16px] text-gray400`};

      path {
        ${tw`stroke-current stroke-[2px]`};
      }
    }
  }

  input {
    ${tw`grow bg-transparent outline-none text-[1.6rem] placeholder:text-gray500`};

    &:-webkit-autofill {
      &,
      &:hover,
      &:focus,
      &:active {
        transition: background-color 600000s 0s, color 600000s 0s;
      }
    }

    &[data-autocompleted] {
      background-color: transparent !important;
    }
  }
`;
