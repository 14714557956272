import {
  GetCryptocurrenciesParams,
  GetCryptocurrenciesResponse,
} from "./types";

import request from "../request";

export const getCryptocurrencies = (params: GetCryptocurrenciesParams = {}) => {
  return request.get<GetCryptocurrenciesResponse>(`/cryptocurrencies`, {
    params,
  });
};
