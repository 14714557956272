import tw, { styled } from "twin.macro";
import { useNavigate } from "react-router-dom";

import Page from "app/components/ui/Page";
import Table from "app/components/ui/Table";

import { ReactComponent as GlobeIcon } from "app/assets/icons/globe-03.svg";
import { ReactComponent as Plus } from "app/assets/icons/plus.svg";

import Badge from "app/components/ui/Badge";
import Button from "app/components/ui/Button";
import useModalState from "app/hooks/useModalState";
import AddEndpointModal from "./components/AddEndpointModal";
import LoaderContainer from "app/components/ui/LoaderContainer";
import useGetWebhooks from "./hooks/useGetWebhooks";
import PaginationWrapper from "app/components/PaginationWrapper";
import EmptyState from "app/components/ui/EmptyState";

export default function Webhooks() {
  const navigate = useNavigate();

  const { isOpen, closeModal, openModal } = useModalState();

  const {
    isLoading,
    error,
    getWebhooks,
    webhooks,
    gotoNextPage,
    gotoPreviousPage,
    currentPage,
    totalPages,
  } = useGetWebhooks();

  return (
    <Page
      title="Webhooks"
      secondarySection={
        <Button variant="primary" icon={<Plus />} onClick={openModal}>
          Add endpoint
        </Button>
      }
    >
      <LoaderContainer
        loading={isLoading}
        error={!!error}
        errorMessage={error}
        onRetry={getWebhooks}
      >
        {webhooks && (
          <>
            {webhooks.length > 0 ? (
              <Table
                headings={[
                  { content: "URL" },
                  { content: "Error rate" },
                  { content: "Status" },
                ]}
              >
                {webhooks.map((webhook) => (
                  <Table.Row
                    key={webhook.id}
                    role="button"
                    onClick={() => navigate(webhook.id)}
                  >
                    <Table.Cell>
                      <div tw="flex gap-[12px] items-center">
                        <Globe />
                        {webhook.url}
                      </div>
                    </Table.Cell>

                    <Table.Cell> {`${webhook.error_rate}%`} </Table.Cell>

                    <Table.Cell tw="capitalize">
                      <Badge
                        status={
                          webhook.status === "enabled" ? "success" : "error"
                        }
                        outline
                      >
                        {webhook.status}
                      </Badge>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table>
            ) : (
              <EmptyState
                title="Listen to Raven events"
                subTitle="Create webhook endpoints, so that Raven can notify your integration when asynchronous events occur."
              >
                <Button variant="primary" tw="px-[47px]" onClick={openModal}>
                  <Plus /> Add endpoint
                </Button>
              </EmptyState>
            )}

            {webhooks.length > 0 && (
              <PaginationWrapper
                currentPage={currentPage}
                totalPages={totalPages}
                goToNext={gotoNextPage}
                goToPrev={gotoPreviousPage}
              />
            )}
          </>
        )}
      </LoaderContainer>

      <AddEndpointModal isOpen={isOpen} close={closeModal} />
    </Page>
  );
}

const Globe = styled(GlobeIcon)`
  ${tw`w-[24px] h-[24px] text-gray700`};

  path {
    ${tw`stroke-current`};
  }
`;
