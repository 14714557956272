import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { login } from "app/api/auth";
import { useToast } from "app/providers/ToastProvider";

export default function useLogin() {
  const showToast = useToast();

  const { isLoading, data, mutate, error } = useMutation(login, {
    onError: (e) => {
      const errorMessage = parseError(e as AxiosError<{}>) as string;
      showToast("", errorMessage, "error");
    },
  });

  return {
    isLoading,
    data: data?.data,
    login: mutate,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
  };
}
