import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { ToastVariant, useToast } from "app/providers/ToastProvider";
import {
  changeProjectMemberRole,
  inviteProjectMember,
  reactivateProjectMember,
  removeProjectMember,
  suspendProjectMember,
} from "app/api/project";
import { useProject } from "app/providers/ProjectProvider";
import { ProfileProject } from "app/models/profile";

const useProjectMemberActions = () => {
  const showToast = useToast();
  const queryClient = useQueryClient();
  const project = useProject() as ProfileProject;

  const handleSuccess = (message: string, variant: ToastVariant) => {
    queryClient.invalidateQueries(["members"]);
    queryClient.invalidateQueries(["profile"]);
    showToast("", message, variant);
  };

  const handleError = (error: AxiosError<{}>) => {
    const errorMessage = parseError(error) as string;
    showToast("", errorMessage, "error");
  };

  const { isLoading: isInvitingMember, mutate: sendMemberInvite } = useMutation(
    inviteProjectMember,
    {
      onSuccess: () => handleSuccess("Invite(s) sent", "success"),
      onError: handleError,
    }
  );

  const { isLoading: isDeletingMember, mutate: deleteMember } = useMutation(
    (member_id: string) => removeProjectMember(project.id, member_id),
    {
      onSuccess: (d) => handleSuccess(d.data.message || "", "info"),
      onError: handleError,
    }
  );

  const { isLoading: isSuspendingMember, mutate: suspendMember } = useMutation(
    (member_id: string) => suspendProjectMember(project.id, member_id),
    {
      onSuccess: (d) => handleSuccess(d.data.message || "", "info"),
      onError: handleError,
    }
  );

  const { isLoading: isReactivatingMember, mutate: reactivateMember } =
    useMutation(
      (member_id: string) => reactivateProjectMember(project.id, member_id),
      {
        onSuccess: (d) => handleSuccess(d.data.message || "", "success"),
        onError: handleError,
      }
    );

  const { isLoading: isChangingMemberRole, mutate: changeMemberRole } =
    useMutation(changeProjectMemberRole, {
      onSuccess: (d) => handleSuccess(d.data.message || "", "info"),
      onError: handleError,
    });

  return {
    isInvitingMember,
    sendMemberInvite,
    isSuspendingMember,
    suspendMember,
    isDeletingMember,
    deleteMember,
    isReactivatingMember,
    reactivateMember,
    isChangingMemberRole,
    changeMemberRole,
  };
};

export default useProjectMemberActions;
