import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import parseError from "app/lib/parseError";
import {
  activateAPIKey,
  createAPIKey,
  deactivateAPIKey,
  deleteAPIKey,
} from "app/api/api_keys";
import { CreateAPIKeyPayload } from "app/api/api_keys/types";
import { ProfileProject } from "app/models/profile";
import { useProject } from "app/providers/ProjectProvider";
import { useToast } from "app/providers/ToastProvider";

const useAPIKeys = () => {
  const showToast = useToast();
  const queryClient = useQueryClient();
  const project = useProject() as ProfileProject;

  const handleSuccess = (res: AxiosResponse<any>) => {
    showToast(
      "",
      res.data.message || "API key has been successfully created",
      "success"
    );
    queryClient.invalidateQueries(["api-keys"]);
  };

  const handleError = (error: AxiosError<{}>) => {
    const errorMessage = parseError(error) as string;
    showToast("", errorMessage || "", "error");
  };

  const { isLoading: isCreating, mutate: create } = useMutation(
    (d: CreateAPIKeyPayload) => createAPIKey(project.id, d),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  const { isLoading: isDeleting, mutate: deleteKey } = useMutation(
    (apiKeyID: string) => deleteAPIKey(project.id, apiKeyID),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  const { isLoading: isActivating, mutate: activate } = useMutation(
    (apiKeyID: string) => activateAPIKey(project.id, apiKeyID),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  const { isLoading: isDeactivating, mutate: deactivate } = useMutation(
    (apiKeyID: string) => deactivateAPIKey(project.id, apiKeyID),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  return {
    isCreating,
    create,
    //
    isDeleting,
    deleteKey,
    //
    isActivating,
    activate,
    //
    isDeactivating,
    deactivate,
  };
};

export default useAPIKeys;
