import Button from "app/components/ui/Button";
import Text from "app/styles/Text";

interface Props {
  resend: () => void;
}

export default function EmailSuccess(props: Props) {
  const { resend } = props;

  return (
    <>
      <Button fullWidth variant="primary" tw="mt-[32px]">
        Open email app
      </Button>
      <div tw="flex mt-[32px] justify-center">
        <Text subdued>
          Didn't receive the email?{" "}
          <Button variant="plain" onClick={resend}>
            Click to resend
          </Button>
        </Text>
      </div>
    </>
  );
}
