import { WebhookEndpoint, WebhookEvent } from "app/models/webhook";
import {
  CreateWebhookPayload,
  GetWebhookEventsParams,
  GetWebhookEventsResponse,
  GetWebhooksParams,
  GetWebhooksResponse,
} from "./types";

import request from "../request";
import { BaseResponse } from "../types";

export const getWebhookEndpoints = (
  projectID: string,
  params: GetWebhooksParams
) => {
  return request.get<GetWebhooksResponse>(
    `/projects/${projectID}/webhook_endpoints`,
    {
      params,
    }
  );
};

export const getWebhookEndpoint = (webhookID: string, projectID: string) => {
  return request.get<WebhookEndpoint>(
    `/projects/${projectID}/webhook_endpoints/${webhookID}`
  );
};

export const createWebhookEndpoint = (
  projectID: string,
  payload: CreateWebhookPayload
) => {
  return request.post<BaseResponse>(
    `/projects/${projectID}/webhook_endpoints`,
    payload
  );
};

export const deleteWebhookEndpoint = (projectID: string, webhookID: string) => {
  return request.delete<BaseResponse>(
    `/projects/${projectID}/webhook_endpoints/${webhookID}`
  );
};

export const activateWebhookEndpoint = (
  projectID: string,
  webhookID: string
) => {
  return request.put<BaseResponse>(
    `/projects/${projectID}/webhook_endpoints/${webhookID}/activate`
  );
};

export const deactivateWebhookEndpoint = (
  projectID: string,
  webhookID: string
) => {
  return request.put<BaseResponse>(
    `/projects/${projectID}/webhook_endpoints/${webhookID}/deactivate`
  );
};

export const getWebhookEvents = (
  webhookEndpointID: string,
  params?: GetWebhookEventsParams
) => {
  return request.get<GetWebhookEventsResponse>(
    `/webhook_endpoints/${webhookEndpointID}/webhooks`,
    { params }
  );
};

export const getWebhookEvent = (
  webhookEndpointID: string,
  webhookEventID: string
) => {
  return request.get<WebhookEvent>(
    `/webhook_endpoints/${webhookEndpointID}/webhooks/${webhookEventID}`
  );
};

export const resendWebhook = (
  webhookEndpointID: string,
  webhookEventID: string
) => {
  return request.post<BaseResponse>(
    `/webhook_endpoints/${webhookEndpointID}/webhooks/${webhookEventID}/resend`
  );
};
