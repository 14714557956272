import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateProfile } from "app/api/profile";
import { BaseResponse } from "app/api/types";
import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import { AxiosError, AxiosResponse } from "axios";

const useUpdateProfile = () => {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const handleSuccess = (d: AxiosResponse<BaseResponse>) => {
    showToast("", d.data.message || "", "success");
    queryClient.invalidateQueries(["profile"]);
  };

  const handleError = (error: AxiosError<{}>) => {
    const errorMessage = parseError(error) as string;
    showToast("", errorMessage, "error");
  };

  const { isLoading: isUpdating, mutate: update } = useMutation(updateProfile, {
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return {
    isUpdating,
    update,
  };
};

export default useUpdateProfile;
