import { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import { BaseResponse } from "app/api/types";
import { createWallet } from "app/api/wallets";
import { CreateWalletPayload } from "app/api/wallets/types";
import { ProfileProject } from "app/models/profile";
import { useProject } from "app/providers/ProjectProvider";
import { useToast } from "app/providers/ToastProvider";

const useCreateWallet = () => {
  const project = useProject() as ProfileProject;

  const queryClient = useQueryClient();
  const showToast = useToast();

  const handleSuccess = (res: AxiosResponse<BaseResponse>) => {
    queryClient.invalidateQueries(["wallets"]);
    showToast("", res.data.message || "", "success");
  };

  const handleError = (err: AxiosError<{}>) => {
    const errorMessage = parseError(err) as string;
    showToast("", errorMessage, "error");
  };

  const { isLoading, mutate: create } = useMutation(
    (d: CreateWalletPayload) => createWallet(project.id, d),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  return {
    isCreating: isLoading,
    create,
  };
};

export default useCreateWallet;
