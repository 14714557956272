import tw, { styled } from "twin.macro";

import Modal from "app/components/ui/Modal";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";

import useModifyWebhook from "../hooks/useModifyWebhook";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export default function EnableWebhookModal(props: Props) {
  const { isOpen, close } = props;

  const { enable, isEnabling } = useModifyWebhook();

  const enableHook = () => {
    enable(undefined, {
      onSuccess: close,
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={close}
      title="Enable a webhook endpoint"
      subTitle="This webhook is disabled and no longer receives notifications."
    >
      <ModalContent>
        <div className="main">
          <Text>
            This webhook is disabled and no longer receives notifications. This
            may have been done automatically because we detected an extended
            period of failures.
          </Text>

          <Text>
            If you've corrected the issue, you can re-enable the webhook
            endpoint here.
          </Text>

          <Text>
            Raven will not retry any notifications that were generated in the
            intervening period, and if we continue to detect failures on this
            endpoint, we will disable the endpoint again.
          </Text>
        </div>

        <div className="actions">
          <Button variant="secondary" fullWidth onClick={close}>
            Cancel
          </Button>

          <Button
            variant="primary"
            fullWidth
            loading={isEnabling}
            onClick={enableHook}
          >
            Enable webhook endpoint
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  .main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 24px 32px 24px;

    p {
      ${tw`text-gray600`}
    }
  }

  .actions {
    display: flex;
    gap: 12px;
    padding: 24px 24px 24px 24px;

    ${tw`border border-t-gray200`}
  }
`;
