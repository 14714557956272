import Prism from "prismjs";
import clsx from "clsx";
import tw, { styled } from "twin.macro";
import { useEffect, useRef } from "react";
import { Global, css } from "@emotion/react";

Prism.manual = true;

Prism.plugins.NormalizeWhitespace.setDefaults({
  "remove-indent": true,
  "remove-trailing": true,
  "remove-initial-line-feed": true,
  "tabs-to-spaces": true,
  "left-trim": true,
  "right-trim": true,
});

Prism.hooks.add("complete", (env) => {
  const element = env.element;
  const matchedTokens = element.querySelectorAll(
    ".token.string-property.property"
  );
  matchedTokens.forEach((tP) => {
    if (tP.textContent?.match('"id"')) {
      const idValueNode = tP.nextElementSibling?.nextElementSibling;
      if (idValueNode) {
        idValueNode.classList.add("idvalue");
      }
    }
  });
});

interface Props {
  code: string;
  lang: string;
  className?: string;
}
export default function CodeView(props: Props) {
  const { lang, code, className } = props;

  const codeEl = useRef<HTMLElement>(null);

  useEffect(() => {
    if (codeEl.current) {
      Prism.highlightElement(codeEl.current);
    }
    // Prism.highlightAll();
  }, [code]);

  return (
    <Container>
      <Global styles={style} />
      <Pre className={clsx("line-numbers", className)}>
        <code ref={codeEl} className={`language-${lang} `}>
          {code}
        </code>
      </Pre>
    </Container>
  );
}

const Container = styled.div``;

const style = css`
  code[class*="language-"],
  pre[class*="language-"] {
    color: #000;
    background: 0 0;
    text-shadow: 0 1px #fff;
    font-size: 1.3rem;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5px;
    /* -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4; */
    /* -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; */
  }
  code[class*="language-"] ::-moz-selection,
  code[class*="language-"]::-moz-selection,
  pre[class*="language-"] ::-moz-selection,
  pre[class*="language-"]::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
  }
  code[class*="language-"] ::selection,
  code[class*="language-"]::selection,
  pre[class*="language-"] ::selection,
  pre[class*="language-"]::selection {
    text-shadow: none;
    background: #b3d4fc;
  }
  @media print {
    code[class*="language-"],
    pre[class*="language-"] {
      text-shadow: none;
    }
  }
  pre[class*="language-"] {
    padding: 1em;
    margin: 0.5em 0;
    overflow: auto;
  }
  :not(pre) > code[class*="language-"],
  pre[class*="language-"] {
    background: transparent;
  }
  :not(pre) > code[class*="language-"] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
  }
  .token.cdata,
  .token.comment,
  .token.doctype,
  .token.prolog {
    color: #708090;
  }
  .token.punctuation {
    color: #999;
  }
  .token.namespace {
    opacity: 0.7;
  }
  .token.boolean {
    color: #0570de;
  }
  .token.constant,
  .token.deleted,
  .token.property,
  .token.symbol,
  .token.tag,
  .token-operator {
    color: #1a1b25;
  }

  .token.number {
    color: #fc526a;
  }
  .token.string {
    color: #228403;
  }

  .token.string.idvalue {
    color: #625afa;
  }

  .language-css .token.string,
  .style .token.string,
  .token.entity,
  .token.url {
    color: #9a6e3a;
    background: hsla(0, 0%, 100%, 0.5);
  }
  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: #c84801;
  }
  .token.class-name,
  .token.function {
    color: #dd4a68;
  }
  .token.important,
  .token.regex,
  .token.variable {
    color: #e90;
  }
  .token.bold,
  .token.important {
    font-weight: 700;
  }
  .token.italic {
    font-style: italic;
  }
  .token.entity {
    cursor: help;
  }
`;

const Pre = styled.pre`
  ${tw`border-t border-t-gray100 overflow-hidden`};

  &[class*="language-"].line-numbers {
    ${tw`whitespace-pre-wrap pl-0`};
  }

  > code {
    ${tw`pl-[92px] block pt-[24px] pb-[32px] leading-[21.1px]`};
  }

  .line-numbers-rows {
    ${tw`block w-[68px] left-0 px-[24px] pt-[24px] pb-[32px] font-bold  h-auto leading-[21px] border-r-gray100`};

    /* span {
      ${tw`text-center`};
    } */
  }
`;
