import clsx from "clsx";
import { useEffect, useState } from "react";
import tw, { css, styled } from "twin.macro";

import Table from "app/components/ui/Table";
import Tabs from "app/components/ui/Tabs";
import useGetWebhookEvents from "../hooks/useGetWebhookEvents";
import LoaderContainer from "app/components/ui/LoaderContainer";
import NetworkLoader from "app/components/ui/NetworkLoader";

import { ReactComponent as ErrorIcon } from "app/assets/icons/x-close.svg";
import { ReactComponent as SuccessIcon } from "app/assets/icons/check.svg";
import { WebhookEventsItem } from "app/models/webhook";
import { formatDateStr } from "app/utils/helpers";

import EventView from "./EventView";

//TODO filter by status

export default function WebhookEvents() {
  const [activeEvent, setActiveEvent] = useState<WebhookEventsItem>();
  const [activeStatus, setActiveStatus] = useState(0);

  const { isLoading, isFetching, error, events, retry } = useGetWebhookEvents();

  useEffect(() => {
    if (events && !activeEvent) {
      setActiveEvent(events[0]);
    }
  }, [activeEvent, events]);

  return (
    <Tabs
      tw="mt-[48px]"
      selected={activeStatus}
      onSelect={setActiveStatus}
      tabs={[{ label: "All" }, { label: "Succeeded" }, { label: "Failed" }]}
    >
      <LoaderContainer
        loading={isLoading}
        error={!!error}
        errorMessage={error}
        onRetry={retry}
      >
        {isFetching && <NetworkLoader />}
        {events && (
          <div tw="flex">
            <div tw="basis-1/2 flex-grow flex-shrink-0">
              <WebhookEventsTable
                activeEventID={activeEvent?.id}
                onEventSelected={setActiveEvent}
                events={events}
              />
            </div>

            <div
              css={[
                tw`transition-[flex-basis_border-color] basis-0 border-l border-l-transparent max-w-[50%]`,
                activeEvent && tw`border-l-neutral basis-1/2 flex-grow`,
              ]}
            >
              {activeEvent && <EventView event={activeEvent} />}
            </div>
          </div>
        )}
      </LoaderContainer>
    </Tabs>
  );
}

interface WebhookEventsTableProps {
  activeEventID?: string;
  onEventSelected: (event: WebhookEventsItem) => void;
  events: WebhookEventsItem[];
}
function WebhookEventsTable({
  activeEventID,
  onEventSelected,
  events,
}: WebhookEventsTableProps) {
  const selectEvent = (event: WebhookEventsItem) => () => {
    onEventSelected(event);
  };

  return (
    <Table
      headings={[
        { content: "Name", styles: css(tw`pl-[16px]`) },
        { content: "Time" },
      ]}
    >
      {events.map((event) => {
        const isActive = activeEventID === event.id;
        const isSuccess = event.http_status < 400 && event.http_status !== 0;

        return (
          <Table.Row
            key={event.id}
            role="button"
            css={eventRowStyles}
            onClick={selectEvent(event)}
            className={clsx({ active: isActive })}
          >
            <Table.Cell>
              <div className="event-info">
                <EventIcon className={clsx({ error: !isSuccess })}>
                  {isSuccess ? <SuccessIcon /> : <ErrorIcon />}
                </EventIcon>

                <pre tw="text-textPrimary">{event.type}</pre>
              </div>
            </Table.Cell>

            <Table.Cell tw="text-textSecondary">
              {formatDateStr(event.created_at, "hh:mm:ssa")}
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table>
  );
}

const eventRowStyles = css`
  ${tw`h-[40px] border-l-2 border-l-transparent transition-colors`};

  .event-info {
    ${tw`flex items-center gap-[12px] pl-[16px]`};
  }

  &.active {
    ${tw`bg-gray25 border-l-primary700`};
  }
`;

const EventIcon = styled.div`
  ${tw`rounded-full w-[24px] h-[24px] bg-primary100 text-primary700 flex items-center justify-center`};

  svg {
    ${tw`w-[12px] h-[12px] text-inherit`};

    path {
      ${tw`stroke-current`};
    }
  }

  &.error {
    ${tw`bg-error100 text-critical`};
  }
`;
