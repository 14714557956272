import { ReactNode } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import tw, { styled } from "twin.macro";

import {
  DASHBOARD_SIDEBAR_WIDTH,
  DASHBOARD_TOPBAR_HEIGHT,
} from "app/constants/variables";
import LoaderContainer from "./ui/LoaderContainer";
import useAuth from "app/hooks/useAuth";
import useGetProfile from "app/hooks/useGetProfile";
import { useProfile } from "app/providers/ProfileProvider";
import NetworkLoader from "./ui/NetworkLoader";
import DashboardHooksCall from "./DashboardHooksCall";
interface Props {
  // header: ReactNode;
  topnav: ReactNode;
}

export default function DashboardLayout(props: Props) {
  const { topnav } = props;

  const isLoggedIn = useAuth();
  const location = useLocation();

  const { isLoading, isFetching, error, refetch } = useGetProfile(isLoggedIn);

  const profile = useProfile();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location.pathname }} />;
  }

  return (
    <>
      {isFetching && !isLoading ? <NetworkLoader /> : null}

      <LoaderContainer
        screen
        loading={isLoading && !profile}
        error={!!error}
        onRetry={refetch}
      >
        {profile && (
          <Container>
            <header className="topbar">{topnav}</header>
            <section className="body">
              <DashboardHooksCall>
                <Outlet />
              </DashboardHooksCall>
            </section>
          </Container>
        )}
      </LoaderContainer>
    </>
  );
}

const Container = styled.section`
  ${tw`w-full h-screen flex flex-col`};

  > .body {
    ${tw`flex-grow w-full h-full flex`};

    ${tw`px-[32px] py-[32px] overflow-x-hidden overflow-y-auto mobile:px-[16px] mobile:py-[12px]`};
  }

  > .topbar {
    height: ${DASHBOARD_TOPBAR_HEIGHT}px;
    ${tw`sticky top-0 flex-shrink-0 z-10 bg-white min-w-page`};
  }

  > .sidebar {
    width: ${DASHBOARD_SIDEBAR_WIDTH}px;

    ${tw`sticky top-0 h-full flex-shrink-0`};

    ${tw`mobile:hidden`};
  }
`;
