import { PropsWithChildren, ReactNode } from "react";
import tw, { styled } from "twin.macro";

import { ReactComponent as Logo } from "app/assets/images/logomark.svg";
import Header from "app/styles/Header";
import Text from "app/styles/Text";
import { isString } from "app/utils/helpers";

interface Props {
  title: string;
  description: ReactNode;
}
export default function AuthPageContainer(props: PropsWithChildren<Props>) {
  const { children, title, description } = props;

  return (
    <Container>
      <div tw="mb-[32px]">
        <Logo tw="w-[48px] h-[48px] mb-[24px]" />

        <Header semiBold medium>
          {title}
        </Header>

        {isString(description) ? (
          <Text subdued medium tw="mt-[12px]">
            {description}
          </Text>
        ) : (
          description
        )}
      </div>

      {children}
    </Container>
  );
}

const Container = styled.main`
  ${tw`min-h-screen w-[400px] max-w-full m-auto mobile:px-[16px] flex flex-col justify-center`}
`;
