import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useParams } from "react-router-dom";

import parseError from "app/lib/parseError";
import { getWebhookEndpoint } from "app/api/webhook";
import { ProfileProject } from "app/models/profile";
import { useProject } from "app/providers/ProjectProvider";

const useGetWebhook = () => {
  const { id } = useParams<{ id: string }>();
  const project = useProject() as ProfileProject;

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ["webhooks", id],
    () => getWebhookEndpoint(id as string, project.id)
  );

  return {
    isLoading,
    isFetching,
    webhook: data?.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    retry: refetch,
  };
};

export default useGetWebhook;
