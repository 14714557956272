import * as Sentry from "@sentry/react";
import {
  HistoryRouterProps,
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";
import history from "./lib/history";

// import AppThemeProvider from "./providers/AppThemeProvider";
import QueryProvider from "./providers/QueryProvider";
import TokenProvider from "./providers/TokenProvider";
import Routes from "./routes";
import ToastProvider from "./providers/ToastProvider";
import ProjectProvider from "./providers/ProjectProvider";

function App() {
  return (
    <Sentry.ErrorBoundary
      fallback={() => <h1>Error</h1>}
      beforeCapture={(scope) => {
        scope.setTag("location", "app");
      }}
    >
      <TokenProvider>
        <QueryProvider>
          {/* <AppThemeProvider> */}
          <ToastProvider>
            <ProjectProvider>
              <HistoryRouter
                history={history as unknown as HistoryRouterProps["history"]}
              >
                <Routes />
              </HistoryRouter>
            </ProjectProvider>
          </ToastProvider>
          {/* </AppThemeProvider> */}
        </QueryProvider>
      </TokenProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
