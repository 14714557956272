import * as Yup from "yup";
import tw, { styled } from "twin.macro";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import Modal from "app/components/ui/Modal";
import TextField from "app/components/ui/TextField";
import Button from "app/components/ui/Button";
import TextArea from "app/components/ui/TextArea";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import { UpdateProjectPayload } from "app/api/project/types";
import { ProjectItem } from "app/models/project";

import useProjectActions from "../hooks/useProjectActions";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string()
    .required("Description is required")
    .max(160, "Cannot exceed 160 characters"),
});

interface Props {
  open: boolean;
  closeModal: () => void;
  project: ProjectItem;
}

export default function EditProjectModal(props: Props) {
  const { open, closeModal, project } = props;
  const { isUpdating, updateSelectedProject } = useProjectActions();

  const {
    register,
    formState: { errors, isDirty },
    setValue,
    getValues,
    handleSubmit,
  } = useForm<Omit<UpdateProjectPayload, "id">>({
    values: {
      name: project.name,
      description: project.description,
    },
    resolver: yupResolver(schema),
  });

  useUpdatedEffect(() => {
    setValue("name", project.name);
    setValue("description", project.description);
  }, [project]);

  const onSubmit = handleSubmit((v) =>
    updateSelectedProject(
      {
        id: project.id,
        name: v.name,
        description: v.description,
      },
      {
        onSuccess: closeModal,
      }
    )
  );

  const errorMessage = errors.description?.message;
  const characterCount = getValues("description").length;
  const maxCharacterLimit = 160;

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title="Edit project"
      subTitle="Update the details of an existing project"
    >
      <ModalContent onSubmit={onSubmit}>
        <div className="main">
          <TextField
            label="Project name"
            containerClassName="input"
            {...register("name")}
            error={!!errors.name}
            errorMessage={errors.name?.message}
          />

          <TextArea
            label="Project Description"
            id="area"
            assistiveText={
              !errorMessage
                ? `${maxCharacterLimit - characterCount} characters left`
                : ""
            }
            containerClassName="textarea"
            {...register("description")}
            error={!!errors.description}
            errorMessage={errorMessage}
          />

          {/* <Card tw="rounded-[12px] w-full">
            <div className="upload">
              <Upload />

              <Text>
                <button>Add a project logo</button> or drag and drop
              </Text>

              <Text small>SVG, PNG, JPG or GIF (max. 800x400px)</Text>
            </div>
          </Card> */}
        </div>

        <div className="action">
          <Button
            type="button"
            onClick={closeModal}
            variant="secondary"
            fullWidth
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            fullWidth
            type="submit"
            loading={isUpdating}
            disabled={!isDirty}
          >
            Save changes
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.form`
  .main {
    padding: 20px 24px 32px 24px;

    .upload {
      padding: 16px 24px;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        ${tw`text-gray600`}
      }

      button {
        font-weight: 600;
        font-size: 14px;
        ${tw`text-primary900`}
      }

      svg {
        margin-bottom: 12px;
        ${tw`w-[40px] h-[40px]`}
      }
    }

    .textarea {
      margin: 16px 0;
    }

    .check {
      margin-top: 20px;
      > p {
        width: 100%;
      }
    }

    .input {
      > label {
        font-weight: 500;
        font-size: 14px;
      }
    }

    .consent {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-bottom: 1px solid #eaecf0;
      padding-bottom: 16px;
      p {
        ${tw`text-gray600`}
      }
    }
  }
  .action {
    padding: 24px;
    border-top: 1px solid #eaecf0;
    display: flex;
    gap: 12px;
  }
`;
