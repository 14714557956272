import tw from "twin.macro";
import styled from "@emotion/styled";
import React, { ButtonHTMLAttributes } from "react";

import copyToClipboard from "app/lib/copyToClipboard";
import { ReactComponent as Copy } from "app/assets/icons/copy-06.svg";
import { ReactComponent as Check } from "app/assets/icons/check-outlined.svg";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
}

export default function CopyButton(props: Props) {
  const { text, ...rest } = props;
  const [isCopied, setIsCopied] = React.useState(false);

  const copy = () => copyToClipboard(text).then(() => setIsCopied(true));

  React.useEffect(() => {
    let id: any;
    if (isCopied) {
      id = setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }

    return () => clearTimeout(id);
  }, [isCopied]);

  return (
    <ButtonEl onClick={copy} {...rest} disabled={isCopied}>
      <div>{isCopied ? <Check tw="fill-[#1EAD3D]" /> : <Copy />}</div>
    </ButtonEl>
  );
}

const ButtonEl = styled.button`
  > div {
    ${tw`transition-all ease-in-out duration-500`};
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;
