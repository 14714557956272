import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import { getAPIKeys } from "app/api/api_keys";
import { ProfileProject } from "app/models/profile";
import { useProject } from "app/providers/ProjectProvider";
import useQueryParams from "app/hooks/useQueryParams";
import { GetAPIKeysParams } from "app/api/api_keys/types";

const useGetAPIKeys = () => {
  const project = useProject() as ProfileProject;
  const { queryParams, updateQueryParams } = useQueryParams<GetAPIKeysParams>({
    page: 1,
  });

  const { isLoading, isFetching, error, refetch, status, data } = useQuery(
    ["api-keys", project.id, ...Object.values(queryParams)],
    () => getAPIKeys(project.id, queryParams)
  );

  const getNextPage = () => {
    if (data) {
      updateQueryParams("page", data.data.page + 1);
    }
  };

  const getPreviousPage = () => {
    if (data) {
      updateQueryParams("page", data.data.page - 1);
    }
  };

  return {
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getAPIKeys: refetch,
    apiKeys: data?.data.data,
    status,
    getNextPage,
    getPreviousPage,
    currentPage: data?.data.page || 1,
    totalPages: data?.data.total_pages || 1,
  };
};

export default useGetAPIKeys;
