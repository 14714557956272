import * as React from "react";

import useGetInfiniteCryptoCurrencies from "./useGetInfiniteCryptoCurrencies";
import useUpdatedEffect from "./useUpdatedEffect";

const useCryptoCurrencies = () => {
  const {
    isFetching,
    isLoading,
    currencies,
    error,
    getCurrencies,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetInfiniteCryptoCurrencies();

  useUpdatedEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [currencies, hasNextPage]);

  const memoizedCurrencies = React.useMemo(
    () => currencies,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(currencies)]
  );

  const getCurrency = React.useCallback(
    (id: string) => {
      return (memoizedCurrencies || []).find(
        (currency) => currency.id.toLowerCase() === id.toLowerCase()
      );
    },
    [memoizedCurrencies]
  );

  return {
    isFetching,
    isLoading,
    currencies,
    error,
    getCurrencies,
    isFetchingNextPage,
    getCurrency,
  };
};

export default useCryptoCurrencies;
