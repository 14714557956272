import { useQuery } from "@tanstack/react-query";
import { getTwoFA } from "app/api/profile";
import parseError from "app/lib/parseError";
import { AxiosError } from "axios";

const useGetTwoFA = () => {
  const { isLoading, error, data, refetch, isFetching } = useQuery(
    ["two-fa"],
    getTwoFA
  );

  return {
    isLoading,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    twoFA: data?.data,
    retry: refetch,
    isFetching,
  };
};

export default useGetTwoFA;
