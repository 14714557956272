import tw, { styled } from "twin.macro";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { ReactComponent as Flag } from "app/assets/icons/flag.svg";

import Modal from "app/components/ui/Modal";
import SubHeader from "app/styles/SubHeader";
import Text from "app/styles/Text";
import TextField from "app/components/ui/TextField";
import Button from "app/components/ui/Button";
import { CreateWebhookPayload } from "app/api/webhook/types";
import useCreateWebhook from "../hooks/useCreateWebhook";

const schema = Yup.object().shape({
  url: Yup.string()
    .required("Webhook url is required")
    .url("Kindly input a valid endpoint"),
});
interface Props {
  isOpen: boolean;
  close: () => void;
}

export default function AddEndpointModal(props: Props) {
  const { isOpen, close } = props;

  const { create, isCreating } = useCreateWebhook();

  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<CreateWebhookPayload>({
    values: {
      url: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((v) =>
    create(v, {
      onSuccess: close,
    })
  );

  return (
    <Modal
      open={isOpen}
      onClose={close}
      title={
        <div tw="p-[12px] border border-gray200 rounded-[10px]">
          <Flag />
        </div>
      }
    >
      <ModalContent onSubmit={onSubmit}>
        <div className="content_header">
          <SubHeader medium>Listen to Raven events</SubHeader>

          <Text>
            Set up your webhook endpoint to receive live events from Raven
          </Text>
        </div>

        <div className="form">
          <TextField
            label="Endpoint URL"
            {...register("url")}
            error={!!errors.url}
            errorMessage={errors.url?.message}
          />
        </div>

        <div className="actions">
          <Button variant="secondary" fullWidth onClick={close}>
            Cancel
          </Button>

          <Button
            variant="primary"
            fullWidth
            type="submit"
            loading={isCreating}
          >
            Add endpoint
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.form`
  padding: 16px 24px 24px 24px;

  .content_header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 20px;

    h4 {
      ${tw`text-gray900`}
    }
    p {
      ${tw`text-gray600`}
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .actions {
    display: flex;
    gap: 12px;
    margin-top: 32px;
  }
`;
