import * as Yup from "yup";
import tw, { styled } from "twin.macro";

import Text from "app/styles/Text";
import Button from "app/components/ui/Button";
import TextField from "app/components/ui/TextField";
import useInvite from "../hooks/useInvite";
import { useForm } from "react-hook-form";
import { CreateAccountPayload } from "app/api/auth/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSearchParams } from "react-router-dom";

const schema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Must be at least 8 characters"),
});

export default function InviteForm() {
  const [searchParams] = useSearchParams();
  const { isLoading, invite } = useInvite();

  const token = searchParams.get("token");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Omit<CreateAccountPayload, "invite_token">>({
    resolver: yupResolver(schema),
    values: {
      first_name: "",
      last_name: "",
      password: "",
    },
  });

  const onSubmit = handleSubmit((v) =>
    invite({
      ...v,
      invite_token: token as string,
    })
  );

  console.log({ errors });

  return (
    <Form onSubmit={onSubmit}>
      <TextField
        label="First name"
        placeholder="John"
        {...register("first_name")}
        error={!!errors.first_name}
        errorMessage={errors.first_name?.message}
      />

      <TextField
        label="Last name"
        placeholder="Doe"
        {...register("last_name")}
        error={!!errors.last_name}
        errorMessage={errors.last_name?.message}
      />

      <TextField
        label="Password"
        placeholder="********"
        assistiveText="Must be at least 8 characters, and can't begin or end with a space."
        {...register("password")}
        error={!!errors.password}
        errorMessage={errors.password?.message}
      />

      <div tw="mt-[4px] flex flex-col gap-[32px]">
        <Button fullWidth variant="primary" type="submit" loading={isLoading}>
          Create account
        </Button>

        <Text tw="whitespace-nowrap mobile:whitespace-normal text-center">
          By proceeding, you are agreeing to the Terms and conditions
        </Text>
      </div>
    </Form>
  );
}

const Form = styled.form`
  ${tw`flex flex-col gap-[20px]`};
`;
