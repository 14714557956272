import Button from "app/components/ui/Button";
import Modal from "app/components/ui/Modal";
import Text from "app/styles/Text";

import useAPIKeys from "../hooks/useAPIKeys";

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  apiKey: string;
}
export default function DeleteAPIKeyModal(props: Props) {
  const { isOpen, onClose, apiKey } = props;

  const { isDeleting, deleteKey } = useAPIKeys();

  const deleteAPIKey = () => {
    deleteKey(apiKey, {
      onSuccess: onClose,
    });
  };

  return (
    <Modal title="Delete API key" open={isOpen} onClose={onClose}>
      <div tw="px-[24px] pt-[20px]">
        <Text subdued tw="leading-[20px]">
          You can't undo an API key delete operation. If you're not entirely
          sure you should delete a specific API key, consider disabling it
          instead.
        </Text>
      </div>

      <div tw="mt-[32px] flex gap-[12px] p-[24px] border-t border-t-gray200">
        <Button fullWidth variant="secondary" text="Cancel" onClick={onClose} />

        <Button
          fullWidth
          variant="destructive"
          text="Delete API Key"
          onClick={deleteAPIKey}
          loading={isDeleting}
        />
      </div>
    </Modal>
  );
}
