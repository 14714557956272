import { Outlet } from "react-router-dom";
import SideNavigation from "../../components/ui/SideNavigation";

export default function SettingsRoutes() {
  return (
    <SideNavigation
      title="Settings"
      links={[
        { url: "projects", label: "Projects", exact: false },
        { url: "members", label: "Members" },
      ]}
    >
      <Outlet />
    </SideNavigation>
  );
}
