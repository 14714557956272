import * as React from "react";
import AuthPageContainer from "app/components/AuthPageContainer";
import EmailForm from "./components/EmailForm";
import EmailSuccess from "./components/EmailSuccess";
import Text from "app/styles/Text";

export default function ForgotPassword() {
  const [activeStep, setActiveStep] = React.useState<"form" | "success">(
    "form"
  );
  const [email, setEmail] = React.useState("");

  const onReset = (d: string) => {
    setActiveStep("success");
    setEmail(d);
  };

  const resend = () => {
    setActiveStep("form");
  };

  return (
    <AuthPageContainer
      title={activeStep === "form" ? "Reset password" : "Check your email"}
      description={
        activeStep === "form" ? (
          "Enter the email associated with your account and you'll get instructions on how to reset your password"
        ) : (
          <Text subdued medium tw="mt-[12px]">
            We sent a password reset link to <br />
            {email}
          </Text>
        )
      }
    >
      {activeStep === "form" ? (
        <EmailForm onDone={onReset} />
      ) : (
        <EmailSuccess resend={resend} />
      )}
    </AuthPageContainer>
  );
}
