import { Outlet } from "react-router-dom";

import SideNavigation from "../../components/ui/SideNavigation";

export default function WalletsRoutes() {
  return (
    <SideNavigation
      title="Wallets"
      links={[
        { url: "deposits", label: "Deposits", exact: false },
        { url: "withdrawals", label: "Withdrawals" },
        { url: "vaults", label: "Vaults" },
      ]}
    >
      <Outlet />
    </SideNavigation>
  );
}
