import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "app/components/ui/Button";
import TextField from "app/components/ui/TextField";
import Text from "app/styles/Text";
import { ResetPasswordPayload } from "app/api/auth/types";

import useForgotPassword from "../hooks/useForgotPassword";

const schema = Yup.object().shape({
  email: Yup.string().required("Email is required").email(),
});

interface Props {
  onDone: (_: string) => void;
}

export default function EmailForm(props: Props) {
  const { onDone } = props;

  const { isLoading, resetPassword, error } = useForgotPassword();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<ResetPasswordPayload>({
    resolver: yupResolver(schema),
    values: { email: "" },
  });

  const onSubmit = handleSubmit((v) =>
    resetPassword(v, {
      onSuccess: () => onDone(v.email),
    })
  );

  return (
    <form onSubmit={onSubmit}>
      <TextField
        label="Email"
        type="email"
        placeholder="Enter your email"
        {...register("email")}
        error={!!errors.email || !!error}
        errorMessage={errors.email?.message || error}
      />

      <Button fullWidth variant="primary" tw="mt-[24px]" loading={isLoading}>
        Continue
      </Button>

      <div tw="flex mt-[32px] justify-center">
        <Text subdued>
          Remembered password?{" "}
          <Link
            to="/login"
            tw="font-semibold text-base text-primary900 hover:text-primary800"
          >
            Back to log in
          </Link>
        </Text>
      </div>
    </form>
  );
}
