import { ClassNames } from "@emotion/react";
import { Transition } from "@headlessui/react";
import Text from "app/styles/Text";
import { Fragment } from "react";
import tw from "twin.macro";

interface Props {
  show?: boolean;
}
export default function TestBanner({ show = false }: Props) {
  return (
    <ClassNames>
      {({ css }) => (
        <>
          {/* @ts-ignore */}
          <Transition
            show={show}
            as={Fragment}
            enter={css([tw`transition-opacity ease-linear duration-300`])}
            enterFrom={css([tw`opacity-0`])}
            enterTo={css([tw`opacity-100`])}
            leave={css([tw`transition-opacity ease-linear duration-300`])}
            leaveFrom={css([tw`opacity-100`])}
            leaveTo={css([tw`opacity-0`])}
          >
            <div tw="w-screen border-t border-t-attention absolute top-full overflow-hidden">
              {/* @ts-ignore */}
              <Transition.Child
                enter={css([
                  tw`transition-transform ease-in-out duration-300 transform`,
                ])}
                enterFrom={css([tw`-translate-y-full`])}
                enterTo={css([tw`translate-y-0`])}
                leave={css([
                  tw`transition-transform ease-in-out duration-300 transform`,
                ])}
                leaveFrom={css([tw`translate-y-0`])}
                leaveTo={css([tw`-translate-y-full`])}
              >
                <Text
                  semiBold
                  tw="mx-auto w-[69px] block p-[2px] text-center rounded-b-[3px] text-white text-[1rem] bg-attention"
                >
                  TEST DATA
                </Text>
              </Transition.Child>
            </div>
          </Transition>
        </>
      )}
    </ClassNames>
  );
}
