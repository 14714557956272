import { AxiosError, AxiosResponse } from "axios";
import { useQuery } from "@tanstack/react-query";

import { getProfile } from "app/api/profile";
import parseError from "app/lib/parseError";
import { useSetProfile } from "app/providers/ProfileProvider";
import Profile from "app/models/profile";
import { useProject, useSetProject } from "app/providers/ProjectProvider";
import storage from "app/lib/storage";
import { APP_PROJECT_ID_STORAGE_KEY } from "app/constants/variables";

const useGetProfile = (enabled = true) => {
  const setProfile = useSetProfile();

  const project = useProject();
  const setProject = useSetProject();

  const handleSuccess = (data: AxiosResponse<Profile>) => {
    setProfile(data.data);

    const projects = data.data.projects;
    if (!project) {
      const savedProjectID = storage.get(APP_PROJECT_ID_STORAGE_KEY);

      let correspondingProject;
      if (savedProjectID) {
        correspondingProject = projects.find((p) => p.id === savedProjectID);
      }
      if (!correspondingProject) {
        correspondingProject = projects[0];
      }
      setProject(correspondingProject);
    }
  };

  const { data, status, error, refetch, isFetching, isLoading } = useQuery(
    ["profile"],
    getProfile,
    {
      enabled,
      onSuccess: handleSuccess,
    }
  );

  return {
    profile: data,
    status,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    refetch,
    isFetching,
    isLoading,
  };
};

export default useGetProfile;
