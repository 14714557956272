import * as React from "react";
import tw, { styled } from "twin.macro";

import Text from "app/styles/Text";
import { isString } from "app/utils/helpers";
import { ReactComponent as CheckIcon } from "app/assets/icons/check.svg";
import { ReactComponent as MinusIcon } from "app/assets/icons/minus.svg";
import clsx from "clsx";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  className?: string;
  intermidiate?: boolean;
  medium?: boolean;
}

function CheckBox(props: Props, ref: any) {
  const { label, className, intermidiate, medium, ...rest } = props;

  return (
    <Label className={clsx({ medium }, className)}>
      <input ref={ref} type="checkbox" {...rest} />

      <div>{intermidiate ? <MinusIcon /> : <CheckIcon />}</div>

      {!!label ? isString(label) ? <Text>{label}</Text> : label : null}
    </Label>
  );
}

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  ${tw`text-gray700`};

  > div {
    ${tw`w-[16px] h-[16px] rounded-[4px] border border-gray300 hover:shadow-focused flex justify-center items-center`};

    svg {
      ${tw`hidden w-full h-full bg-transparent`};
    }
  }

  > p {
    ${tw`font-medium text-gray700`};
  }

  > input {
    display: none;

    :checked + div {
      ${tw`bg-primary50 border-primary600`};

      svg {
        ${tw`block text-primary600`};

        path {
          ${tw`stroke-current`};
        }
      }
    }
  }

  &.medium {
    > div {
      ${tw`w-[20px] h-[20px]`};
    }
  }
`;

export default React.forwardRef(CheckBox);
