import Button from "app/components/ui/Button";
import { useNavigate } from "react-router-dom";

export default function ChangePasswordSuccess() {
  const navigate = useNavigate();

  const routeToLogin = () => {
    navigate("/login");
  };

  return (
    <Button variant="primary" tw="mt-[32px]" onClick={routeToLogin}>
      Continue to Dashboard
    </Button>
  );
}
