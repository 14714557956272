import tw, { styled } from "twin.macro";
import { useState } from "react";

import Button from "app/components/ui/Button";
import CheckBox from "app/components/ui/CheckBox";
import Modal from "app/components/ui/Modal";
// import TextField from "app/components/ui/TextField";
import Text from "app/styles/Text";
import { ProjectItem } from "app/models/project";
import { capitalizeStr } from "app/utils/helpers";

import useProjectActions from "../hooks/useProjectActions";

interface Props {
  open: boolean;
  closeModal: () => void;
  project: ProjectItem;
}

export default function DeleteProject(props: Props) {
  const { open, closeModal, project } = props;
  const [isAcknowledged, setIsAcknowledged] = useState<boolean>(false);

  const { isDeleting, deleteSelectedProject } = useProjectActions();

  const toggleIsAcknowledged = () => {
    if (!isDeleting) {
      setIsAcknowledged((v) => !v);
    }
  };

  const deleteProject = () => {
    deleteSelectedProject(project.id, { onSuccess: closeModal });
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={`Confirm deletion of ${capitalizeStr(project.name)}`}
      subTitle="Deletion is permanent and cannot be undone"
    >
      <ModalContent>
        <div className="main">
          <div className="consent">
            <Text>
              Keep in mind this operation is irreversible and will result in a
              complete deletion of all the data associated with this project.
            </Text>

            <Text>
              Data including but not limited to members, transaction date and
              reports will be permanently deleted.
            </Text>
          </div>

          {/* <TextField
            label="Enter deletion code sent to tobi@busha.co"
            placeholder="Enter the deletion code"
            containerClassName="input"
          /> */}

          <CheckBox
            label="I acknowledge I understand that all of the project data will be deleted and want to proceed."
            className="check"
            onChange={toggleIsAcknowledged}
            checked={isAcknowledged}
          />
        </div>

        <div className="action">
          <Button
            variant="destructive"
            fullWidth
            disabled={!isAcknowledged}
            loading={isDeleting}
            onClick={deleteProject}
          >
            Delete this project
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  .main {
    padding: 20px 24px 32px 24px;

    .check {
      margin-top: 20px;
      > p {
        width: 100%;
      }
    }

    .input {
      margin-top: 16px;

      > label {
        font-weight: 500;
        font-size: 14px;
      }
    }

    .consent {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-bottom: 1px solid #eaecf0;
      padding-bottom: 16px;
      p {
        ${tw`text-gray600`}
      }
    }
  }
  .action {
    padding: 24px;
    border-top: 1px solid #eaecf0;
  }
`;
