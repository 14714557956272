import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { changePassword } from "app/api/auth";
import parseError from "app/lib/parseError";

const useChangePassword = () => {
  const { isLoading, data, mutate, error } = useMutation(changePassword);

  return {
    isLoading,
    data: data?.data,
    changePassword: mutate,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
  };
};

export default useChangePassword;
