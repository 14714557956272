import * as React from "react";
import AuthPageContainer from "app/components/AuthPageContainer";
import NewPasswordsForm from "./components/NewPasswordsForm";
import ChangePasswordSuccess from "./components/ChangePasswordSuccess";

export default function ChangePassword() {
  const [activeStep, setActiveStep] = React.useState<"form" | "success">(
    "form"
  );

  const onSuccess = () => {
    setActiveStep("success");
  };

  return (
    <AuthPageContainer
      title={
        activeStep === "form" ? "Set new password" : "Password reset successful"
      }
      description={
        activeStep === "form"
          ? "Your new password must be different to previously used passwords"
          : "Your password reset was successful. You can now continue to the dashboard."
      }
    >
      {activeStep === "form" ? (
        <NewPasswordsForm onSuccess={onSuccess} />
      ) : (
        <ChangePasswordSuccess />
      )}
    </AuthPageContainer>
  );
}
