import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { getProjectMembers } from "app/api/project";
import useQueryParams from "app/hooks/useQueryParams";
import { GetProjectsParams } from "app/api/project/types";

const useGetProjectMembers = (id: string) => {
  const { queryParams, updateQueryParams } = useQueryParams<GetProjectsParams>({
    page: 1,
  });

  const { isLoading, isFetching, error, refetch, status, data } = useQuery(
    ["members", id],
    () => getProjectMembers(id, { page: queryParams.page })
  );

  const gotoNextPage = () => {
    if (data) {
      updateQueryParams("page", data.data.page + 1);
    }
  };

  const gotoPreviousPage = () => {
    if (data) {
      updateQueryParams("page", data.data.page - 1);
    }
  };

  return {
    isMembersLoading: isLoading,
    isFetching,
    membersError: error
      ? (parseError(error as AxiosError<{}>) as string)
      : undefined,
    getMembers: refetch,
    members: data?.data.data,
    status,
    gotoNextPage,
    gotoPreviousPage,
    queryParams,
    updateQueryParams,
    currentPage: data?.data.page || 1,
    totalPages: data?.data.total_pages || 1,
  };
};

export default useGetProjectMembers;
