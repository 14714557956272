import { Project } from "app/models/project";

import {
  ChangeProjectMemberRolePayload,
  CreateProjectPayload,
  GetProjectMembersResponse,
  GetProjectsParams,
  GetProjectsResponse,
  InviteProjectMemberPayload,
  InviteProjectMemberResponse,
  UpdateProjectPayload,
} from "./types";

import request from "../request";
import { BaseResponse } from "../types";

export const getProjects = (params: GetProjectsParams) => {
  return request.get<GetProjectsResponse>(`/projects`, { params });
};

export const getProject = (id: string) => {
  return request.get<Project>(`/projects/${id}`);
};

export const createProject = (payload: CreateProjectPayload) => {
  return request.post<Project>("/projects", payload);
};

export const updateProject = ({ id, ...payload }: UpdateProjectPayload) => {
  return request.put<Project>(`/projects/${id}`, payload);
};

export const deleteProject = (id: string) => {
  return request.delete<BaseResponse>(`/projects/${id}`);
};

export const getProjectMembers = (id: string, params: GetProjectsParams) => {
  return request.get<GetProjectMembersResponse>(`/projects/${id}/members`, {
    params,
  });
};

export const inviteProjectMember = ({
  id,
  ...payload
}: InviteProjectMemberPayload) => {
  return request.post<InviteProjectMemberResponse>(
    `/projects/${id}/members`,
    payload
  );
};

export const removeProjectMember = (id: string, member_id: string) => {
  return request.delete<BaseResponse>(`/projects/${id}/members/${member_id}`);
};

export const suspendProjectMember = (id: string, member_id: string) => {
  return request.post<BaseResponse>(
    `/projects/${id}/members/${member_id}/suspend`
  );
};

export const reactivateProjectMember = (id: string, member_id: string) => {
  return request.post<BaseResponse>(
    `/projects/${id}/members/${member_id}/activate`
  );
};

export const changeProjectMemberRole = ({
  id,
  member_id,
  ...payload
}: ChangeProjectMemberRolePayload) => {
  return request.post<BaseResponse>(
    `/projects/${id}/members/${member_id}/change-role`,
    payload
  );
};
