import * as Yup from "yup";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Button from "app/components/ui/Button";
import Modal from "app/components/ui/Modal";
import useModalState from "app/hooks/useModalState";
import TextField from "app/components/ui/TextField";
import ListBox from "app/components/ui/ListBox";
import useCryptoCurrencies from "app/hooks/useCryptoCurrencies";
import LoaderContainer from "app/components/ui/LoaderContainer";
import Paths from "app/utils/paths";
import { CreateWalletPayload } from "app/api/wallets/types";
import { ReactComponent as Plus } from "app/assets/icons/plus.svg";

import useCreateWallet from "../hooks/useCreateWallet";

const schema = Yup.object().shape({
  asset_id: Yup.string().required("Kindly select an asset"),
  name: Yup.string().required("Wallet name is required"),
});

const CreateWalletModal = () => {
  const { isOpen, openModal, closeModal } = useModalState();

  const { isCreating, create } = useCreateWallet();

  const {
    isLoading: isLoadingCurrencies,
    isFetchingNextPage: isFetchingCurrencies,
    currencies,
    error: currenciesError,
    getCurrencies,
  } = useCryptoCurrencies();

  const {
    formState: { errors },
    register,
    watch,
    setValue,
    handleSubmit,
  } = useForm<CreateWalletPayload>({
    values: {
      name: "",
      asset_id: "XLM_TEST",
    },
    resolver: yupResolver(schema),
  });

  const handleCloseModal = useCallback(() => {
    if (!isCreating) {
      closeModal();
    }
  }, [isCreating, closeModal]);

  const createWallet = handleSubmit((v) => {
    create(v, { onSuccess: closeModal });
  });

  const assetIDValue = watch("asset_id");

  return (
    <>
      <Button
        variant="primary"
        icon={<Plus />}
        text="Create wallet"
        onClick={openModal}
      />
      <Modal
        title="Create wallet"
        subTitle="Give staff access to this project by sending them an invitation"
        open={isOpen}
        onClose={handleCloseModal}
      >
        <LoaderContainer
          loading={isLoadingCurrencies || isFetchingCurrencies}
          error={!!currenciesError}
          errorMessage={currenciesError}
          onRetry={getCurrencies}
        >
          {currencies && (
            <div tw="p-[24px] pt-[20px] flex flex-col gap-[32px]">
              <div tw="flex flex-col gap-[16px]">
                <TextField
                  label="Wallet name"
                  placeholder="Bitcoin"
                  {...register("name")}
                  error={!!errors.name}
                  errorMessage={errors.name?.message}
                />
                <ListBox
                  label="Assets"
                  options={currencies.map((currency) => ({
                    label: currency.name,
                    value: `${currency.id}_TEST`,
                    icon: Paths.coinSVG(currency.id.toUpperCase()),
                  }))}
                  placeholder="BTC"
                  value={assetIDValue}
                  onChange={(i) => {
                    setValue("asset_id", i.value);
                  }}
                  error={!!errors.asset_id}
                  errorMessage={errors.asset_id?.message}
                />
              </div>
              <div>
                <Button
                  fullWidth
                  variant="primary"
                  onClick={createWallet}
                  loading={isCreating}
                >
                  Create wallet
                </Button>
              </div>
            </div>
          )}
        </LoaderContainer>
      </Modal>
    </>
  );
};

export default CreateWalletModal;
