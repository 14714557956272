const Paths = {
  recommendation: (name: string) =>
    `https://firebasestorage.googleapis.com/v0/b/busha-mobile/o/recommendations%2F${name.toLowerCase()}.png?alt=media`,

  coinSVG: (symbol: string) =>
    `https://firebasestorage.googleapis.com/v0/b/busha-mobile/o/assets%2F${symbol.toUpperCase()}.svg?alt=media`,

  coinPNG: (symbol: string) =>
    `https://firebasestorage.googleapis.com/v0/b/busha-mobile/o/assets_png%2F${symbol.toUpperCase()}.png?alt=media`,
};

export default Paths;
