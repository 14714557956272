import React from "react";
import ReactDOM from "react-dom/client";
import { GlobalStyles as BaseStyles } from "twin.macro";
import { Global } from "@emotion/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "app";
import reportWebVitals from "./reportWebVitals";
import GlobalStyles from "app/styles";
import { SENTRY_DSN } from "app/constants/variables";

import "react-toastify/dist/ReactToastify.min.css";

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: process.env.NODE_ENV === "development" ? 0 : 0.5,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BaseStyles />
    <Global styles={GlobalStyles} />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
