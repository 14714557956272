import request from "../request";
import {
  ChangePasswordPayload,
  ChangePasswordResponse,
  CreateAccountPayload,
  CreateAccountResponse,
  LoginPayload,
  LoginResponse,
  ResetPasswordPayload,
  ResetPasswordResponse,
} from "./types";

export const login = (data: LoginPayload) => {
  return request.post<LoginResponse>("/login", data, {
    headers: {
      noToken: true,
    },
  });
};

export const resetPassword = (data: ResetPasswordPayload) => {
  return request.post<ResetPasswordResponse>("/reset_password", data, {
    headers: {
      noToken: true,
    },
  });
};

export const changePassword = (payload: ChangePasswordPayload) => {
  return request.post<ChangePasswordResponse>("/change_password", payload, {
    headers: {
      noToken: true,
    },
  });
};

export const createAccount = (payload: CreateAccountPayload) => {
  return request.post<CreateAccountResponse>("/register", payload, {
    headers: {
      noToken: true,
    },
  });
};
