import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import useQueryParams from "app/hooks/useQueryParams";
import { getProjects } from "app/api/project";
import { GetProjectsParams } from "app/api/project/types";

const useGetProjects = () => {
  const { queryParams, updateQueryParams } = useQueryParams<GetProjectsParams>({
    page: 1,
  });

  const { isLoading, isFetching, error, refetch, status, data } = useQuery(
    ["projects"],
    () => getProjects({ page: queryParams.page })
  );

  const gotoNextPage = () => {
    if (data) {
      updateQueryParams("page", data.data.page + 1);
    }
  };

  const gotoPreviousPage = () => {
    if (data) {
      updateQueryParams("page", data.data.page - 1);
    }
  };

  return {
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getProjects: refetch,
    projects: data?.data.data,
    status,
    gotoNextPage,
    gotoPreviousPage,
    queryParams,
    updateQueryParams,
    currentPage: data?.data.page || 1,
    totalPages: data?.data.total_pages || 1,
  };
};

export default useGetProjects;
