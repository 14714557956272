import * as React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { toast, ToastContainer, CloseButtonProps } from "react-toastify";

import Text from "app/styles/Text";
import { ReactComponent as Success } from "app/assets/icons/success.svg";
import { ReactComponent as Cancel } from "app/assets/icons/cancel.svg";
import { ReactComponent as InfoError } from "app/assets/icons/info-filled-error.svg";
import { ReactComponent as Info } from "app/assets/icons/info-filled.svg";

export type ToastVariant = "success" | "error" | "info";

interface ToastContextType {
  showToast: (title: string, subTitle: string, variant: ToastVariant) => void;
}

export const ToastContext = React.createContext<ToastContextType>({
  showToast: (_, __, ___) => {},
});
ToastContext.displayName = "ToastContext";

export default function ToastProvider(props: React.PropsWithChildren<any>) {
  const showToast = (
    title: string,
    subTitle: string,
    varaint: ToastVariant
  ) => {
    const message = (
      <Message>
        {title && (
          <Text semiBold small>
            {title}
          </Text>
        )}
        {subTitle && <Text small>{subTitle}</Text>}
      </Message>
    );

    if (varaint === "success") {
      toast.success(message, { icon: Success });
    } else if (varaint === "error") {
      toast.error(message, {
        icon: <InfoError className="icon-error" />,
      });
    } else if (varaint === "info") {
      toast.info(message, { icon: <Info className="icon-info" /> });
    } else {
      toast(message);
    }
  };

  const closeButton = ({ closeToast }: CloseButtonProps) => (
    <CloseButton onClick={closeToast}>
      <Cancel className="close-icon" />
    </CloseButton>
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      <Container hideProgressBar closeButton={closeButton} />
      {props.children}
    </ToastContext.Provider>
  );
}

export function useToast() {
  const { showToast } = React.useContext(ToastContext);

  return showToast;
}

const Container = styled(ToastContainer)`
  ${tw`mobile:px-[16px]`}

  .Toastify__toast {
    ${tw`rounded-[12px] flex items-center`}
  }

  .close-icon {
    ${tw`stroke-current`}
  }

  .Toastify__toast--success {
    ${tw`border-solid border-[1px] border-primary800 bg-success25 text-primary900`}
  }

  .Toastify__toast--error {
    ${tw`border-solid border-[1px] border-red700 bg-red50 text-red700`}
  }

  .Toastify__toast--info {
    ${tw`border-solid border-[1px] border-info90 bg-info10 text-info90`}
  }

  .Toastify__toast-icon {
    ${tw`self-center pt-[2px]`}
  }

  ${Text} {
    font-size: 1.4rem;
  }
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  width: 15px;
  height: 15px;
`;

const Message = styled.div`
  ${tw`grid gap-y-[4px]`};
`;
