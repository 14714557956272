import { styled } from "twin.macro";
import Modal from "app/components/ui/Modal";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";
import SubHeader from "app/styles/SubHeader";
import { ProfileProject } from "app/models/profile";
import Member from "app/models/member";
import useProjectMemberActions from "../hooks/useProjectMemberActions";

interface Props {
  open: boolean;
  closeModal: () => void;
  project: ProfileProject;
  selectedMember: Member;
}

export default function DeleteMember(props: Props) {
  const { open, closeModal, project, selectedMember } = props;

  const { isDeletingMember, deleteMember } = useProjectMemberActions();

  const handleDeleteMember = () => {
    deleteMember(selectedMember?.id);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={
        <img
          src="https://res.cloudinary.com/dhm8wnpd6/image/upload/v1684420523/Avatar_dcb9gf.svg"
          alt="Delete member"
        />
      }
    >
      <ModalContent>
        <div className="main">
          <div className="header">
            <SubHeader medium>Delete member from project</SubHeader>
            <Text subdued>
              <strong>
                {selectedMember?.first_name} {selectedMember?.last_name}{" "}
              </strong>
              {`(${selectedMember?.email})`} will no longer be able to access
              the {`${project.name}`} project. Once deleted, you will have to
              invite them again to re-initiate access.
            </Text>
          </div>
        </div>

        <div className="action">
          <Button variant="secondary" fullWidth onClick={closeModal}>
            Cancel
          </Button>
          <Button
            variant="destructive"
            fullWidth
            loading={isDeletingMember}
            onClick={handleDeleteMember}
          >
            Delete member
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  .main {
    padding: 16px 24px 4px 24px;

    .header {
      padding-bottom: 20px;
    }
  }

  .action {
    display: flex;
    gap: 12px;
    padding: 24px;
    border-top: 1px solid #eaecf0;
  }
`;
