import { PropsWithChildren } from "react";

import Header from "app/styles/Header";

interface Props {
  title?: React.ReactNode;
  secondarySection?: React.ReactNode;
  className?: string;
}
export default function Page(props: PropsWithChildren<Props>) {
  const { title, secondarySection, className, children } = props;

  const showHeader = !!(title || secondarySection);

  return (
    <div tw="w-full min-w-page" className={className}>
      {showHeader && (
        <div tw="flex justify-between items-center mb-[16px]">
          {typeof title === "string" ? (
            <Header tw="text-textPrimary">{title}</Header>
          ) : (
            title
          )}

          <div>{secondarySection}</div>
        </div>
      )}

      <div tw="w-full">{children}</div>
    </div>
  );
}
