import tw, { styled } from "twin.macro";
import { ReactNode } from "react";

import Badge from "app/components/ui/Badge";
import Menu from "app/components/ui/Menu";
import Table from "app/components/ui/Table";
import Text from "app/styles/Text";
import Member from "app/models/member";
import Profile from "app/models/profile";

import { capitalizeStr, formatDateStr } from "app/utils/helpers";
import { ReactComponent as Pencil } from "app/assets/icons/pencil.svg";
import { ReactComponent as Trash } from "app/assets/icons/trash.svg";
import { ReactComponent as Suspend } from "app/assets/icons/users-x.svg";
import { ReactComponent as Reactivate } from "app/assets/icons/users-check.svg";
import { ReactComponent as Resend } from "app/assets/icons/trend-up.svg";

type Props = {
  members: Member[];
  profile: Profile;
  editMember: (m: Member) => void;
  suspendMember: (m: Member) => void;
  reactivateMember: (m: Member) => void;
  deleteMember: (m: Member) => void;
  paginator?: ReactNode;
};

function MembersTable(props: Props) {
  const {
    members,
    profile,
    editMember,
    suspendMember,
    reactivateMember,
    deleteMember,
    paginator,
  } = props;

  return (
    <div>
      {members && (
        <>
          <Table
            headings={[
              {
                content: "Users",
              },
              {
                content: "Status",
              },
              {
                content: "Role",
              },
              {
                content: "Last login",
              },
              {
                content: "",
              },
            ]}
          >
            {members.map((m) => (
              <Table.Row
                key={m.id}
                tw="py-[16px] hover:bg-gray50 cursor-pointer"
              >
                <Table.Cell tw="w-[51%] h-[98px]">
                  <div
                    tw="flex gap-[12px] mr-[20px] items-center relative"
                    key={m.id}
                  >
                    <img
                      src={
                        "https://res.cloudinary.com/dhm8wnpd6/image/upload/v1684420523/Avatar_dcb9gf.svg"
                      }
                      alt={m.first_name}
                      tw="w-[40px] h-[40px]"
                      css={[
                        m.status.toLowerCase() === "invited" &&
                          tw`opacity-[0.6]`,
                      ]}
                    />

                    {m.id === profile?.id && <Active />}

                    <div>
                      <Text
                        mediumBold
                        tw="text-gray900 mb-[4px] flex items-center gap-[6px]"
                        css={[
                          m.status.toLowerCase() === "invited" &&
                            tw`text-gray600`,
                        ]}
                      >
                        {m.first_name} {m.last_name}
                        {m.id === profile?.id && (
                          <Badge status="purple">You</Badge>
                        )}
                      </Text>
                      <Text
                        subdued
                        css={[
                          m.status.toLowerCase() === "invited"
                            ? tw`text-gray400`
                            : tw`text-gray600`,
                        ]}
                      >
                        {m.email}
                      </Text>
                    </div>
                  </div>
                </Table.Cell>

                <Table.Cell
                  tw="w-[10%]"
                  css={[
                    m.status.toLowerCase() === "suspended" && tw`text-red800`,
                  ]}
                >
                  {capitalizeStr(m.status)}
                </Table.Cell>

                <Table.Cell tw="w-[8%]">
                  <Badge
                    status={
                      m.role.name.toLowerCase() === "admin" ||
                      m.role.name.toLowerCase() === "owner"
                        ? "success"
                        : "blueGray"
                    }
                    outline
                  >
                    {m.role.name}
                  </Badge>
                </Table.Cell>

                <Table.Cell tw="w-[12%]">
                  {formatDateStr(m.last_login_at, "MMM DD, YYYY, HH:mm A")}
                </Table.Cell>

                <Table.Cell tw="w-[5%] z-[999]">
                  {m.id !== profile?.id && (
                    <Menu
                      options={[
                        {
                          content: "Edit member",
                          icon: <Pencil />,
                          onAction: () => editMember(m),
                        },
                        {
                          content:
                            m.status.toLowerCase() === "suspended"
                              ? "Reactivate member"
                              : "Suspend member",
                          icon:
                            m.status.toLowerCase() === "suspended" ? (
                              <Reactivate />
                            ) : (
                              <Suspend />
                            ),
                          onAction:
                            m.status.toLowerCase() === "suspended"
                              ? () => reactivateMember(m)
                              : () => suspendMember(m),
                        },
                        {
                          content:
                            m.status.toLowerCase() === "invited"
                              ? "Resend invitation"
                              : undefined,
                          icon:
                            m.status.toLowerCase() === "invited" ? (
                              <Resend />
                            ) : undefined,
                        },
                        {
                          divide: true,
                          content: "Delete member",
                          icon: <Trash />,
                          critical: true,
                          onAction: () => deleteMember(m),
                        },
                      ]}
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table>
        </>
      )}
      {paginator && paginator}
    </div>
  );
}

const Active = styled.div`
  ${tw`absolute w-[10px] h-[10px] bg-success500 bottom-[5%] left-[4%] border-[1.5px] border-white rounded-[5px]`}
`;

export default MembersTable;
