import tw, { css } from "twin.macro";

const GlobalStyles = css`
  * {
    margin: 0;
    padding: 0px;
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
  }

  body,
  button {
    margin: 0;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    ${tw`text-gray900 bg-white`};
  }

  button {
    cursor: pointer;
  }

  code {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  .apexcharts-tooltip {
    background-color: transparent;
    border: none;
  }
`;

export default GlobalStyles;
