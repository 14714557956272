import { PropsWithChildren } from "react";
import tw, { styled } from "twin.macro";

import SubHeader from "app/styles/SubHeader";
import Text from "app/styles/Text";

interface Props {
  title: string;
  subTitle: string;
}

export default function EmptyState(props: PropsWithChildren<Props>) {
  const { title, subTitle, children } = props;

  return (
    <Container>
      <div>
        <SubHeader medium>{title}</SubHeader>

        <Text>{subTitle}</Text>

        {children}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${tw`mt-[200px]`}

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 400px;

    h4 {
      ${tw`text-gray900`}
    }

    p {
      ${tw`text-center text-gray600`}
    }
  }
`;
