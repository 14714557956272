import Page from "app/components/ui/Page";
import ProfileForm from "./components/ProfileForm";
import TwoFASection from "./components/TwoFASection";

export default function Profile() {
  return (
    <Page title="Profile">
      <ProfileForm />

      <TwoFASection />
    </Page>
  );
}
