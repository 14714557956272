import { Fragment, ReactNode, useCallback } from "react";
import { Menu as Dropdown, Transition } from "@headlessui/react";
import tw, { styled } from "twin.macro";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { ClassNames } from "@emotion/react";

import { ReactComponent as MoreIcon } from "app/assets/icons/dots-horizontal.svg";

interface Props {
  activator?: ReactNode;
  options: {
    content: ReactNode;
    icon?: ReactNode;
    onAction?: VoidFunction;
    url?: string;
    disabled?: boolean;
    critical?: boolean;
    divide?: boolean;
  }[];
}
export default function Menu(props: Props) {
  const { activator, options } = props;

  const navigate = useNavigate();

  const gotoUrl = useCallback(
    (url: string) => () => {
      navigate(url);
    },
    [navigate]
  );

  return (
    <Dropdown as="div" tw="relative w-fit">
      <Dropdown.Button>
        {activator ?? (
          <MoreButton>
            <MoreIcon />
          </MoreButton>
        )}
      </Dropdown.Button>

      <ClassNames>
        {({ css }) => (
          <>
            {/* @ts-ignore */}
            <Transition
              as={Fragment}
              enter={css([tw`transition ease-out duration-100`])}
              enterFrom={css([tw`transform opacity-0 scale-95`])}
              enterTo={css([tw`transform opacity-100 scale-100`])}
              leave={css([tw`transition ease-in duration-75`])}
              leaveFrom={css([tw`transform opacity-100 scale-100`])}
              leaveTo={css([tw`transform opacity-0 scale-95`])}
            >
              <Dropdown.Items as={MenuItems}>
                {options.map(
                  (
                    {
                      content,
                      disabled,
                      onAction,
                      url,
                      icon,
                      critical,
                      divide,
                    },
                    i
                  ) =>
                    content && (
                      <Dropdown.Item key={i.toString()} disabled={disabled}>
                        {({ active }) => (
                          <MenuItem
                            className={clsx({
                              active,
                              disabled,
                              critical,
                              divide,
                            })}
                            onClick={url ? gotoUrl(url) : onAction}
                          >
                            {icon ? icon : null}

                            {content}
                          </MenuItem>
                        )}
                      </Dropdown.Item>
                    )
                )}
              </Dropdown.Items>
            </Transition>
          </>
        )}
      </ClassNames>
    </Dropdown>
  );
}

const MenuItems = styled.div`
  ${tw`bg-white border border-gray200 shadow-lg rounded-[8px] py-[4px] min-w-[184px]`};

  ${tw`absolute right-0 origin-top-right z-10`};
`;

const MenuItem = styled.button`
  ${tw`h-[38px] w-full flex items-center gap-[8px] px-[10px] text-base text-gray700 font-medium transition-colors`};

  > svg {
    ${tw`h-[16px] w-[16px]`};

    path {
      ${tw`stroke-current`};
    }
  }

  &.active {
    ${tw`bg-gray50`};
  }

  &.critical {
    ${tw`text-critical`};
  }

  &.disabled {
    ${tw`text-gray200`};
  }

  &.divide {
    ${tw`border-t border-gray200`};
  }
`;

const MoreButton = styled.div`
  ${tw`w-[40px] h-[40px] flex items-center justify-center text-gray600`};

  svg {
    ${tw`w-[20px] h-[20px]`};
    path {
      ${tw`stroke-current`};
    }
  }
`;
