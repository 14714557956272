import { APIKeysItem } from "app/models/apiKeys";

import {
  CreateAPIKeyPayload,
  GetAPIKeysParams,
  GetAPIKeysResponse,
} from "./types";

import request from "../request";
import { BaseResponse } from "../types";

export const getAPIKeys = (projectID: string, params: GetAPIKeysParams) => {
  return request.get<GetAPIKeysResponse>(`/projects/${projectID}/api_keys`, {
    params,
  });
};

export const createAPIKey = (
  projectID: string,
  payload: CreateAPIKeyPayload
) => {
  return request.post<APIKeysItem>(`/projects/${projectID}/api_keys`, payload);
};

export const deleteAPIKey = (projectID: string, apiKeyID: string) => {
  return request.delete<BaseResponse>(
    `/projects/${projectID}/api_keys/${apiKeyID}`
  );
};

export const activateAPIKey = (projectID: string, apiKeyID: string) => {
  return request.put<BaseResponse>(
    `/projects/${projectID}/api_keys/${apiKeyID}/activate`
  );
};

export const deactivateAPIKey = (projectID: string, apiKeyID: string) => {
  return request.put<BaseResponse>(
    `/projects/${projectID}/api_keys/${apiKeyID}/deactivate`
  );
};
