export const TOKEN_STORAGE_KEY = "tokens";

export const SIGN_OUT_PATH = "signout";
export const LOGIN_PATH = "login";
export const HOME_PATH = "/";

export const APP_THEME_STORAGE_KEY =
  process.env.REACT_APP_THEME_STORAGE_KEY ?? "preferred-theme";

export const APP_PROJECT_ID_STORAGE_KEY =
  process.env.REACT_APP_PROJECT_STORAGE_KEY ?? "project-id";

export const COIN_IMG_BASE_URL =
  "https://res.cloudinary.com/busha-inc/image/upload/v1628857343/App/Currencies";

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const AUTH_URL = process.env.REACT_APP_AUTH_API_URL;
export const API_URL = process.env.REACT_APP_API_URL;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const FONT_BASE_URL =
  "https://res.cloudinary.com/busha-inc/raw/upload/v1651833681/fonts/Roobert";

export const BANK_IMG_BASE_URL =
  "https://res.cloudinary.com/busha-inc/image/upload/v1630583952/App/Banks";

export const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL;

export const UNAUTHORIZED_ERROR_CODE = 401;

export const DASHBOARD_SIDEBAR_WIDTH = 280;

export const DASHBOARD_TOPBAR_HEIGHT = 80;

export const DEFAULT_PROFILE_IMG =
  "https://res.cloudinary.com/busha-inc/image/upload/v1668529176/alice/v4/user.png";
