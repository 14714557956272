import { QRCodeSVG } from "qrcode.react";

import Button from "app/components/ui/Button";
import Text from "app/styles/Text";
import tw, { styled } from "twin.macro";

import LoaderContainer from "app/components/ui/LoaderContainer";
import NetworkLoader from "app/components/ui/NetworkLoader";
import CopyButton from "./CopyButton";
import useGetTwoFA from "../../hooks/useGetTwoFA";

interface Props {
  onContinue: (d: string) => void;
  onCancel: VoidFunction;
}
export default function CodeView(props: Props) {
  const { onCancel, onContinue } = props;

  const { twoFA, error, isLoading, retry, isFetching } = useGetTwoFA();

  const openVerificationView = () => {
    onContinue(twoFA?.secret as string);
  };

  return (
    <Container>
      <LoaderContainer
        loading={isLoading}
        error={!!error}
        errorMessage={error}
        onRetry={retry}
      >
        {isFetching && <NetworkLoader />}
        {twoFA && (
          <>
            <div tw="flex flex-col gap-[20px]">
              <Text subdued>Scan QR code</Text>

              <QRCodeSVG
                value={twoFA.url}
                tw="w-[200px] h-[200px] self-center"
              />

              <div className="divider">
                <span />
                <Text medium>or</Text>
                <span />
              </div>

              <Text subdued>
                Enter the key below in the authentication app you want to use.
                If your app asks for an account name, you can enter “Raven”.
              </Text>

              <Copiable>
                <pre>{twoFA.secret}</pre>
                <CopyButton text={twoFA.secret} />
              </Copiable>

              <Text subdued>
                Once your authentication app has generated it's own 6-digit
                code, come back to Busha and enter it
              </Text>
            </div>

            <div tw="flex gap-[12px] mt-[32px]">
              <Button
                fullWidth
                variant="secondary"
                text="Cancel"
                onClick={onCancel}
              />

              <Button
                fullWidth
                variant="primary"
                text="Continue"
                onClick={openVerificationView}
              />
            </div>
          </>
        )}
      </LoaderContainer>
    </Container>
  );
}

const Container = styled.div`
  ${tw`p-[24px] flex flex-col gap-[32px]`};

  .divider {
    ${tw`flex items-center gap-[16px] text-black/[0.95]`};

    span {
      ${tw`block grow h-[1px] bg-black/[0.12]`};
    }
  }
`;

const Copiable = styled.div`
  ${tw`bg-white w-full flex justify-between gap-[8px] border border-gray300 shadow-xs rounded-[8px] text-[2rem] leading-[24px] py-[10px] px-[14px]`};

  pre {
    ${tw`grow whitespace-nowrap overflow-hidden overflow-ellipsis`};
  }

  svg {
    ${tw`shrink-0 w-[20px] h-[20px] text-primary600`};

    path {
      ${tw`stroke-current`};
    }
  }
`;
