import NavigationItem from "app/models/navigationItem";

const appLinks: NavigationItem[] = [
  {
    label: "Home",
    url: "/",
  },
  {
    label: "Wallets",
    url: "/wallets",
    exact: false,
  },
  {
    label: "Transactions",
    url: "/transactions",
  },
  // {
  //   label: "Reports",
  //   url: "/reports",
  // },
  // {
  //   label: "Members",
  //   url: "/members",
  //   icon: Members,
  // },
  // {
  //   label: "Settings",
  //   url: "/settings",
  //   icon: Settings,
  // },
];

export default appLinks;
