import { useState } from "react";

import Button from "app/components/ui/Button";
import Header from "app/styles/Header";
import Page from "app/components/ui/Page";
import useModalState from "app/hooks/useModalState";
import PaginationWrapper from "app/components/PaginationWrapper";
import LoaderContainer from "app/components/ui/LoaderContainer";
import Member from "app/models/member";
import { useProfile } from "app/providers/ProfileProvider";
import { useProject } from "app/providers/ProjectProvider";

import { ReactComponent as Plus } from "app/assets/icons/plus.svg";

import useGetProjectMembers from "./hooks/useGetProjectMembers";
import useGetRoles from "./hooks/useGetRoles";
import MembersTable from "./component/MembersTable";
import AddMember from "./component/AddMember";
import SuspendMember from "./component/SuspendMember";
import ReactivateMember from "./component/ReactivateMember";
import DeleteMember from "./component/DeleteMember";
import EditMember from "./component/EditMember";

export default function Members() {
  const profileProject = useProject();
  const profile = useProfile();
  const [selectedMember, setSelectedMember] = useState<Member>();

  const {
    isMembersLoading,
    membersError,
    getMembers,
    members,
    currentPage,
    totalPages,
    gotoNextPage,
    gotoPreviousPage,
  } = useGetProjectMembers(profileProject?.id!);

  const { isRolesLoading, rolesError, getRoles, roles } = useGetRoles();

  const {
    isOpen: isInviteMemberModalOpen,
    closeModal: closeInviteMemberModal,
    openModal: openInviteMemberModal,
  } = useModalState();

  const {
    isOpen: isSuspendMemberModalOpen,
    closeModal: closeSuspendMemberModal,
    openModal: openSuspendMemberModal,
  } = useModalState();

  const {
    isOpen: isReactivateMemberModalOpen,
    closeModal: closeReactivateMemberModal,
    openModal: openReactivateMemberModal,
  } = useModalState();

  const {
    isOpen: isDeleteMemberModalOpen,
    closeModal: closeDeleteMemberModal,
    openModal: openDeleteMemberModal,
  } = useModalState();

  const {
    isOpen: isEditMemberModalOpen,
    closeModal: closeEditMemberModal,
    openModal: openEditMemberModal,
  } = useModalState();

  const isLoading = isMembersLoading || isRolesLoading;

  const error = membersError || rolesError;

  const onRetry = () => {
    if (membersError) {
      getMembers();
    }

    if (rolesError) {
      getRoles();
    }
  };

  const editMember = (m: Member) => {
    setSelectedMember(m);
    openEditMemberModal();
  };

  const suspendMember = (m: Member) => {
    setSelectedMember(m);
    openSuspendMemberModal();
  };

  const reactivateMember = (m: Member) => {
    setSelectedMember(m);
    openReactivateMemberModal();
  };

  const deleteMember = (m: Member) => {
    setSelectedMember(m);
    openDeleteMemberModal();
  };

  return (
    <Page>
      <div tw="w-full">
        <div tw="flex justify-between items-center mb-[17px]">
          <Header tw="text-[2.8rem]">Members</Header>

          <Button
            variant="primary"
            icon={<Plus />}
            onClick={openInviteMemberModal}
          >
            Invite member(s)
          </Button>
        </div>

        <LoaderContainer
          loading={isLoading}
          error={!!error}
          errorMessage={error}
          onRetry={onRetry}
        >
          <MembersTable
            members={members!}
            profile={profile!}
            editMember={editMember}
            suspendMember={suspendMember}
            reactivateMember={reactivateMember}
            deleteMember={deleteMember}
            paginator={
              members &&
              members.length > 0 && (
                <PaginationWrapper
                  currentPage={currentPage}
                  totalPages={totalPages}
                  goToNext={gotoNextPage}
                  goToPrev={gotoPreviousPage}
                />
              )
            }
          />

          <AddMember
            open={isInviteMemberModalOpen}
            closeModal={closeInviteMemberModal}
            roles={roles!}
            project={profileProject!}
          />

          <SuspendMember
            open={isSuspendMemberModalOpen}
            closeModal={closeSuspendMemberModal}
            project={profileProject!}
            selectedMember={selectedMember!}
          />

          <ReactivateMember
            open={isReactivateMemberModalOpen}
            closeModal={closeReactivateMemberModal}
            project={profileProject!}
            selectedMember={selectedMember!}
          />

          <DeleteMember
            open={isDeleteMemberModalOpen}
            closeModal={closeDeleteMemberModal}
            project={profileProject!}
            selectedMember={selectedMember!}
          />

          <EditMember
            open={isEditMemberModalOpen}
            closeModal={closeEditMemberModal}
            roles={roles!}
            project={profileProject!}
            selectedMember={selectedMember!}
          />
        </LoaderContainer>
      </div>
    </Page>
  );
}
