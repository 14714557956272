import DeleteWebhookModal from "./DeleteWebhookModal";
import DisableWebhookModal from "./DisableWebhookModal";
import EnableWebhookModal from "./EnableWebhookModal";
import { actionModalType } from "./WebhookInfo";

interface Props {
  isOpen: boolean;
  close: () => void;
  type: actionModalType | undefined;
}

export default function ActionModals(props: Props) {
  const { isOpen, close, type } = props;

  return (
    <>
      {type === "enable" && (
        <EnableWebhookModal isOpen={isOpen} close={close} />
      )}
      {type === "disable" && (
        <DisableWebhookModal isOpen={isOpen} close={close} />
      )}
      {type === "delete" && (
        <DeleteWebhookModal isOpen={isOpen} close={close} />
      )}
    </>
  );
}
