import { useState } from "react";

import Menu from "app/components/ui/Menu";
import Page from "app/components/ui/Page";
import Table from "app/components/ui/Table";

import { ReactComponent as CopyIcon } from "app/assets/icons/copy-06.svg";
import { ReactComponent as DisableIcon } from "app/assets/icons/x-close.svg";
import { ReactComponent as DeleteIcon } from "app/assets/icons/trash.svg";
import { ReactComponent as EnableIcon } from "app/assets/icons/check.svg";

import useModalState from "app/hooks/useModalState";
import LoaderContainer from "app/components/ui/LoaderContainer";
import Badge from "app/components/ui/Badge";
import NetworkLoader from "app/components/ui/NetworkLoader";
import PaginationWrapper from "app/components/PaginationWrapper";
import { formatDateStr } from "app/utils/helpers";

import CreateAPIKeyModal from "./components/CreateAPIKeyModal";
import DeleteAPIKeyModal from "./components/DeleteAPIKeyModal";
import useGetAPIKeys from "./hooks/useGetAPIKeys";
import ModifyAPIKeyModal from "./components/ModifyAPIKeyModal";
import SubHeader from "app/styles/SubHeader";
import Text from "app/styles/Text";

export default function APIKeys() {
  const {
    closeModal: closeDeleteModal,
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
  } = useModalState();
  const {
    closeModal: closeModifyModal,
    isOpen: isModifyModalOpen,
    openModal: openModifyModal,
  } = useModalState();

  const {
    isLoading,
    error,
    getAPIKeys,
    apiKeys,
    isFetching,
    currentPage,
    totalPages,
    getNextPage,
    getPreviousPage,
  } = useGetAPIKeys();
  const [selectedAPIKeyData, setSelectedAPIKeyData] = useState({
    key: "",
    isActive: false,
  });

  const handleDeleteKey = (apiKey: string) => {
    setSelectedAPIKeyData({ key: apiKey, isActive: false });
    openDeleteModal();
  };

  const handleModifyKeyStatus = (apiKey: string, isActive: boolean) => {
    setSelectedAPIKeyData({ key: apiKey, isActive });
    openModifyModal();
  };

  return (
    <>
      <Page
        title="API Keys"
        secondarySection={
          apiKeys && apiKeys.length !== 0 && <CreateAPIKeyModal />
        }
      >
        <LoaderContainer
          loading={isLoading}
          error={!!error}
          errorMessage={error}
          onRetry={getAPIKeys}
        >
          {isFetching && <NetworkLoader />}

          {apiKeys && (
            <>
              {apiKeys.length > 0 ? (
                <Table
                  headings={[
                    { content: "Name" },
                    { content: "Token" },
                    { content: "Status" },
                    { content: "Created" },
                    { content: "" },
                  ]}
                >
                  {apiKeys.map((apiKey) => (
                    <Table.Row key={apiKey.api_key}>
                      <Table.Cell tw="w-[30%] capitalize">
                        {apiKey.name}
                      </Table.Cell>

                      <Table.Cell>
                        <pre> {apiKey.api_key} </pre>
                      </Table.Cell>

                      <Table.Cell>
                        <Badge status={apiKey.is_active ? "success" : "error"}>
                          {apiKey.is_active ? "Active" : "Inactive"}
                        </Badge>
                      </Table.Cell>

                      <Table.Cell>
                        {formatDateStr(
                          apiKey.created_at,
                          "MMMM D, YYYY [at] hh:mma"
                        )}
                      </Table.Cell>

                      <Table.Cell>
                        <Menu
                          options={[
                            {
                              content: "Copy key",
                              icon: <CopyIcon />,
                              onAction: () => console.log("here"),
                            },
                            {
                              content: `${
                                apiKey.is_active ? "Disable" : "Enable"
                              } key`,
                              icon: apiKey.is_active ? (
                                <DisableIcon />
                              ) : (
                                <EnableIcon />
                              ),
                              onAction: () =>
                                handleModifyKeyStatus(
                                  apiKey.api_key,
                                  apiKey.is_active
                                ),
                            },
                            {
                              content: "Delete key",
                              icon: <DeleteIcon />,
                              onAction: () => handleDeleteKey(apiKey.api_key),
                              critical: true,
                            },
                          ]}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table>
              ) : (
                <div tw="h-[548px] max-h-full flex flex-col items-center justify-center">
                  <SubHeader medium> Listen to Raven events </SubHeader>

                  <Text subdued tw="text-center w-[400px] my-[16px]">
                    There's no API Keys for you to see yet. Once you create API
                    Keys, they will show up here.
                  </Text>

                  <CreateAPIKeyModal isInitial />
                </div>
              )}
            </>
          )}
          {apiKeys && apiKeys.length > 0 && (
            <PaginationWrapper
              goToNext={getNextPage}
              goToPrev={getPreviousPage}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          )}
          {selectedAPIKeyData && (
            <DeleteAPIKeyModal
              isOpen={isDeleteModalOpen}
              onClose={closeDeleteModal}
              apiKey={selectedAPIKeyData.key}
            />
          )}
          {selectedAPIKeyData && (
            <ModifyAPIKeyModal
              isOpen={isModifyModalOpen}
              onClose={closeModifyModal}
              apiKey={selectedAPIKeyData.key}
              isActive={selectedAPIKeyData.isActive}
            />
          )}
        </LoaderContainer>
      </Page>
    </>
  );
}
