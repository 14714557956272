import tw, { styled } from "twin.macro";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Modal from "app/components/ui/Modal";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";
import SubHeader from "app/styles/SubHeader";
import ListBox from "app/components/ui/ListBox";
import { ReactComponent as Flag } from "app/assets/icons/flag.svg";
import { InviteProjectMemberPayload } from "app/api/project/types";
import useProjectMemberActions from "../hooks/useProjectMemberActions";
import { ProfileProject } from "app/models/profile";
import Role from "app/models/role";
import TextField from "app/components/ui/TextField";

interface Props {
  open: boolean;
  closeModal: () => void;
  roles: Role[];
  project: ProfileProject;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .required("Email address is required")
    .email("Kindly input a valid email address"),
  role_id: Yup.string().required("Role is required"),
});

export default function AddMember(props: Props) {
  const { open, closeModal, roles, project } = props;
  const { isInvitingMember, sendMemberInvite } = useProjectMemberActions();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<Omit<InviteProjectMemberPayload, "id">>({
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((v) =>
    sendMemberInvite({
      id: project.id,
      email: v.email,
      role_id: v.role_id,
    })
  );

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title={
        <IconContainer>
          <Flag />
        </IconContainer>
      }
      tw="overflow-visible"
    >
      <ModalContent autoComplete="off" onSubmit={onSubmit}>
        <div className="main">
          <div className="header">
            <SubHeader medium>Add new member(s)</SubHeader>
            <Text subdued>
              Give staff access to this project by sending them an invitation
            </Text>
          </div>

          <TextField
            label="Email"
            containerClassName="input"
            {...register("email")}
            error={!!errors.email}
            errorMessage={errors.email?.message}
          />

          <Controller
            name="role_id"
            control={control}
            render={({ field: { value, onChange } }) => (
              <ListBox
                placeholder="Admin"
                label="Role"
                error={!!errors.role_id}
                errorMessage={errors.role_id?.message}
                options={roles.map((c) => ({
                  label: c.name,
                  value: c.id,
                }))}
                containerClassName="listbox"
                value={value}
                onChange={(i) => onChange(i.value)}
              />
            )}
          />
        </div>

        <div className="action">
          <Button
            variant="secondary"
            fullWidth
            type="reset"
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            variant="primary"
            fullWidth
            type="submit"
            loading={isInvitingMember}
          >
            Send invite
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

const IconContainer = styled.div`
  ${tw`flex justify-center items-center bg-white border-[1px] border-gray200 `}
  ${tw`rounded-[10px] shadow-[0px 1px 2px rgba(16, 24, 40, 0.05)] w-[48px] h-[48px]`}
`;

const ModalContent = styled.form`
  .main {
    padding: 16px 24px 24px 24px;

    .header {
      padding-bottom: 20px;
    }

    .listbox {
      margin-top: 16px;
      margin-bottom: 8px;
    }

    .input {
      > label {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .action {
    display: flex;
    gap: 12px;
    padding: 24px;
    border-top: 1px solid #eaecf0;
  }
`;
