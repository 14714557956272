import { ReactComponent as Arrow } from "app/assets/icons/arrow-down.svg";
import { ReactComponent as External } from "app/assets/icons/external.svg";
import { ReactComponent as QrCode } from "app/assets/icons/qr-code.svg";
import { ReactComponent as Copy } from "app/assets/icons/copy-06.svg";

import Text from "app/styles/Text";
import Badge from "app/components/ui/Badge";
import Menu from "app/components/ui/Menu";
import Pagination from "app/components/ui/Pagination";
import Table from "app/components/ui/Table";

interface Props {
  openModal: () => void;
}

export default function Addresses(props: Props) {
  const { openModal } = props;

  return (
    <>
      <Table
        headings={[
          { content: "Address" },
          {
            content: (
              <p tw="flex items-center gap-[3px]">
                Balance <Arrow />
              </p>
            ),
          },
          { content: "Last Block" },
          {
            content: (
              <p tw="flex items-center gap-[3px]">
                Creation time <Arrow />
              </p>
            ),
          },
        ]}
      >
        {new Array(10).fill(0).map((_, i) => (
          <Table.Row key={i.toString()}>
            <Table.Cell tw="w-[50%]">
              <div tw="flex gap-[6px]">
                33MbBFnTcFxLQ8qDPzQN6FtQ2JQVopHnVq{" "}
                {i === 0 && <Badge status="purple">Main Address</Badge>}
              </div>
            </Table.Cell>

            <Table.Cell>
              <Text mediumBold>0.555000 BTC</Text>

              <Text subdued>120,000 USD</Text>
            </Table.Cell>

            <Table.Cell>-</Table.Cell>

            <Table.Cell>Apr 2, 2023, 9:22 PM</Table.Cell>

            <Table.Cell>
              <Menu
                options={[
                  {
                    content: (
                      <p tw="flex items-center gap-[8px]">
                        <Copy tw="w-[16px] h-[16px]" /> Copy Address
                      </p>
                    ),
                  },
                  {
                    content: (
                      <p tw="flex items-center gap-[8px]">
                        <QrCode tw="w-[16px] h-[16px]" />
                        Show QR code
                      </p>
                    ),
                    onAction: openModal,
                  },
                  {
                    content: (
                      <p tw="flex items-center w-[200px] gap-[8px]">
                        <External tw="w-[16px] h-[16px]" />
                        View on Block explorer
                      </p>
                    ),
                  },
                ]}
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table>

      <Pagination
        currentPage="1"
        totalPages="10"
        previousPageAction={() => {}}
        nextPageAction={() => {}}
      />
    </>
  );
}
