import tw, { styled } from "twin.macro";

import { Link } from "react-router-dom";

interface TabItem {
  label: string;
  url?: string;
}
interface Props {
  tabs: TabItem[];
  selected: number;
  onSelect: (n: number) => void;
  className?: string;
}

function Tabs(props: React.PropsWithChildren<Props>) {
  const { tabs, selected, onSelect, className } = props;

  const onSelected = (index: number) => onSelect(index);

  return (
    <Container className={className}>
      <div className="tabs">
        {tabs.map((tab, index) =>
          tab.url ? (
            <Link
              className={selected === index ? "tab active" : "tab"}
              key={index}
              onClick={() => onSelected(index)}
              to={tab.url}
            >
              {tab.label}
            </Link>
          ) : (
            <button
              className={selected === index ? "tab active" : "tab"}
              key={index}
              onClick={() => onSelected(index)}
            >
              {tab.label}
            </button>
          )
        )}
      </div>

      {props.children}
    </Container>
  );
}

const Container = styled.div`
  > .tabs {
    ${tw`flex gap-[16px] border-b border-gray200`};

    > button,
    > a {
      ${tw`text-base text-center font-medium px-[4px] pb-[11px] text-gray500 relative transition-colors`};

      &::before {
        ${tw`content-[""] block h-[2px] w-full bg-transparent absolute left-0 bottom-[-1px] transition-colors`};
      }

      &.active {
        ${tw`text-primary700 border-primary700 before:bg-primary700`};
      }
    }
  }
`;

export default Tabs;
