import tw, { styled } from "twin.macro";
import { useState } from "react";

import { ReactComponent as Plus } from "app/assets/icons/plus.svg";
import { ReactComponent as Logo } from "app/assets/images/logomark.svg";
import { ReactComponent as Pencil } from "app/assets/icons/pencil.svg";
import { ReactComponent as Trash } from "app/assets/icons/trash.svg";
import { ReactComponent as Arrow } from "app/assets/icons/arrow-down.svg";

import Button from "app/components/ui/Button";
import Header from "app/styles/Header";
import CreateProject from "app/components/CreateProjectModal";
import useModalState from "app/hooks/useModalState";
import Table from "app/components/ui/Table";
import Page from "app/components/ui/Page";
import Badge from "app/components/ui/Badge";
import Menu from "app/components/ui/Menu";
import Text from "app/styles/Text";
import LoaderContainer from "app/components/ui/LoaderContainer";
import PaginationWrapper from "app/components/PaginationWrapper";
import { useProject } from "app/providers/ProjectProvider";
import { ProjectItem } from "app/models/project";
import { capitalizeStr, formatDateStr } from "app/utils/helpers";

import EditProject from "./components/EditProjectModal";
import DeleteProject from "./components/DeleteProject";
import useGetProjects from "./hooks/useGetProjects";
import useSortProject from "./hooks/useSortProject";

export default function Projects() {
  const [selectedProject, setSelectedProject] = useState<ProjectItem>();

  const {
    isOpen: isCreateModalOpen,
    closeModal: closeCreateModal,
    openModal: openCreateModal,
  } = useModalState();

  const {
    isOpen: isEditModalOpen,
    closeModal: closeEditModal,
    openModal: openEditModal,
  } = useModalState();

  const {
    isOpen: isDeleteModalOpen,
    closeModal: closeDeleteModal,
    openModal: openDeleteModal,
  } = useModalState();

  const {
    isLoading,
    error,
    getProjects,
    projects,
    gotoNextPage,
    gotoPreviousPage,
    totalPages,
    currentPage,
  } = useGetProjects();

  const deleteProject = (p: ProjectItem) => {
    setSelectedProject(p);
    openDeleteModal();
  };

  const editProject = (p: ProjectItem) => {
    setSelectedProject(p);
    openEditModal();
  };

  const {
    sortProject,
    projects: sortedProjects,
    sortConfig,
  } = useSortProject(projects || []);

  return (
    <Page>
      <Container>
        <div className="title">
          <Header tw="text-[2.8rem]">Projects</Header>

          <Button variant="primary" icon={<Plus />} onClick={openCreateModal}>
            Create new project
          </Button>
        </div>

        <LoaderContainer
          loading={isLoading}
          error={!!error}
          errorMessage={error}
          onRetry={getProjects}
        >
          {sortedProjects && (
            <>
              <Table
                headings={[
                  {
                    content: (
                      <div>
                        Projects
                        <button onClick={() => sortProject("name")}>
                          {sortConfig.key === "name" &&
                          sortConfig.direction === "ascending" ? (
                            <Arrow tw="mb-[-3px] ml-[4px]" />
                          ) : (
                            <Arrow tw="mb-[-3px] ml-[4px] rotate-180" />
                          )}
                        </button>
                      </div>
                    ),
                  },
                  {
                    content: (
                      <div>
                        Members
                        <button onClick={() => sortProject("member_count")}>
                          {sortConfig.key === "member_count" &&
                          sortConfig.direction === "ascending" ? (
                            <Arrow tw="mb-[-3px] ml-[4px]" />
                          ) : (
                            <Arrow tw="mb-[-3px] ml-[4px] rotate-180" />
                          )}
                        </button>
                      </div>
                    ),
                  },
                  {
                    content: "Your role",
                  },
                  {
                    content: (
                      <div>
                        Creation date
                        <button onClick={() => sortProject("created_at")}>
                          {sortConfig.key === "created_at" &&
                          sortConfig.direction === "ascending" ? (
                            <Arrow tw="mb-[-3px] ml-[4px]" />
                          ) : (
                            <Arrow tw="mb-[-3px] ml-[4px] rotate-180" />
                          )}
                        </button>
                      </div>
                    ),
                  },
                  {
                    content: "",
                  },
                ]}
              >
                {sortedProjects.map((project) => (
                  <ProjectTile
                    key={project.id}
                    project={project}
                    deleteProject={deleteProject}
                    editProject={editProject}
                  />
                ))}
              </Table>

              {sortedProjects.length > 0 && (
                <PaginationWrapper
                  currentPage={currentPage}
                  totalPages={totalPages}
                  goToNext={gotoNextPage}
                  goToPrev={gotoPreviousPage}
                />
              )}

              <CreateProject
                open={isCreateModalOpen}
                closeModal={closeCreateModal}
              />

              {selectedProject && (
                <>
                  <EditProject
                    open={isEditModalOpen}
                    closeModal={closeEditModal}
                    project={selectedProject}
                  />

                  <DeleteProject
                    open={isDeleteModalOpen}
                    closeModal={closeDeleteModal}
                    project={selectedProject}
                  />
                </>
              )}
            </>
          )}
        </LoaderContainer>
      </Container>
    </Page>
  );
}

interface ProjectProps {
  project: ProjectItem;
  editProject: (project: ProjectItem) => void;
  deleteProject: (project: ProjectItem) => void;
}

const ProjectTile = (props: ProjectProps) => {
  const { project, deleteProject, editProject } = props;
  const profileProject = useProject();

  return (
    <Table.Row tw="py-[16px] hover:bg-gray50 cursor-pointer">
      <Table.Cell tw="w-[51%] h-[98px]">
        <div tw="flex gap-[12px] mr-[20px] items-center">
          <Logo tw="w-[32px] h-[32px] shrink-0" />

          <div>
            <Text
              mediumBold
              tw="text-gray900 mb-[4px] flex items-center gap-[6px]"
            >
              {capitalizeStr(project.name)}
              {project.id === profileProject?.id && (
                <Badge status="purple">Current project</Badge>
              )}
            </Text>
            <Text>{project.description}</Text>
          </div>
        </div>
      </Table.Cell>

      <Table.Cell tw="w-[10%]">
        {`${project.member_count} member${
          Number(project.member_count) > 1 ? "s" : ""
        }`}
      </Table.Cell>

      <Table.Cell tw="w-[8%] capitalize">
        <Badge status="success" outline>
          {project.user_role}
        </Badge>
      </Table.Cell>

      <Table.Cell tw="w-[15%]">
        {formatDateStr(project.created_at, "MMMM D, YYYY [at] hh:mma")}
      </Table.Cell>

      <Table.Cell tw="w-[5%] z-[999]">
        <Menu
          options={[
            {
              content: (
                <>
                  <Pencil />
                  Edit project
                </>
              ),
              onAction: () => editProject(project),
            },
            {
              divide: true,
              content: (
                <>
                  <Trash />
                  Delete project
                </>
              ),
              critical: true,
              onAction: () => deleteProject(project),
            },
          ]}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const Container = styled.div`
  ${tw`w-full`}

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${tw`mb-[17px]`}
  }

  .role {
    display: flex;
    align-items: center;
    padding: 2px 8px;
    border: 1.5px solid #039855;
    border-radius: 16px;
    color: #027a48;
    width: 53%;
  }
`;
