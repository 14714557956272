import styled from "@emotion/styled";

import Card from "app/components/ui/Card";
import Text from "app/styles/Text";
import tw from "twin.macro";
import SetupTwoFAModal from "./SetupTwoFAModal";
import Badge from "app/components/ui/Badge";
import DisableTwoFAModal from "./DisableTwoFAModal";
import { useProfile } from "app/providers/ProfileProvider";
import Profile from "app/models/profile";

export default function TwoFASection() {
  const profile = useProfile() as Profile;

  const hasTwoFA = profile.two_factor_enabled || false;

  return (
    <Container>
      <div tw="flex flex-col gap-[8px]">
        <Text bold big>
          Two-step authentication
        </Text>
        {!hasTwoFA ? (
          <Text subdued>
            Two-step authentication adds a layer of security to your account by
            using more than just password to log in.
          </Text>
        ) : (
          <div>
            <Text as="div" tw="flex gap-[4px] items-center">
              Authenticator app
              <Badge status="success" dot>
                Enabled
              </Badge>
            </Text>

            {/* <Text subdued tw="mt-[4px]">
              {`On since ${formatDateStr(profile.two)}`}
            </Text> */}
          </div>
        )}
      </div>

      <div>{!hasTwoFA ? <SetupTwoFAModal /> : <DisableTwoFAModal />}</div>
    </Container>
  );
}

const Container = styled(Card)`
  ${tw`p-[24px] mt-[20px] flex flex-col gap-[20px] bg-gray50 border-gray200`};
`;
