import * as React from "react";
import { ProfileProject } from "app/models/profile";
import storage from "app/lib/storage";
import { APP_PROJECT_ID_STORAGE_KEY } from "app/constants/variables";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";

const ProjectContext = React.createContext<ProfileProject | undefined>(
  undefined
);
ProjectContext.displayName = "ProjectContext";

const SetProjectContext = React.createContext<
  React.Dispatch<React.SetStateAction<ProfileProject | undefined>>
>(() => {});
SetProjectContext.displayName = "ProjectDispatch";

export default function ProjectProvider(props: React.PropsWithChildren<{}>) {
  const [project, setProject] = React.useState<ProfileProject>();

  useUpdatedEffect(() => {
    if (project?.id) {
      storage.set(APP_PROJECT_ID_STORAGE_KEY, project?.id);
    }
  }, [project]);

  return (
    <ProjectContext.Provider value={project}>
      <SetProjectContext.Provider value={setProject}>
        {props.children}
      </SetProjectContext.Provider>
    </ProjectContext.Provider>
  );
}

export const useProject = () => {
  const project = React.useContext(ProjectContext);
  return project;
};

export const useSetProject = () => {
  const setProject = React.useContext(SetProjectContext);
  return setProject;
};
