import Button from "app/components/ui/Button";
import PinInput from "app/components/ui/PinInput";
import Text from "app/styles/Text";
import useModifyTwoFA from "../../hooks/useModifyTwoFA";
import { useState } from "react";

interface Props {
  onConfirm: VoidFunction;
  onGoBack: VoidFunction;
  secret: string;
}
export default function VerificationForm(props: Props) {
  const { onGoBack: onCancel, onConfirm, secret } = props;

  const [code, setCode] = useState("");
  const { enable, isEnabling, enableError } = useModifyTwoFA();

  const enableTwoFa = () => {
    enable(
      { otp: code, secret },
      {
        onSuccess: onConfirm,
      }
    );
  };

  return (
    <>
      <div tw="p-[24px] pb-[32px] flex flex-col gap-[20px]">
        <Text subdued>
          Enter the 6-digit code from your two-factor authentication app to
          enable 2FA
        </Text>

        <PinInput
          fullWidth
          onPinCompleted={setCode}
          error={!!enableError}
          errorMessage={enableError}
        />
      </div>

      <div tw="p-[24px] flex gap-[12px] border-t border-t-gray200">
        <Button fullWidth variant="secondary" text="Back" onClick={onCancel} />

        <Button
          fullWidth
          variant="primary"
          text="Continue"
          onClick={enableTwoFa}
          loading={isEnabling}
        />
      </div>
    </>
  );
}
