import { Listbox, Transition } from "@headlessui/react";
import tw, { styled } from "twin.macro";
import clsx from "clsx";

import { ReactComponent as Logo } from "app/assets/images/logomark.svg";
import { ReactComponent as Chevron } from "app/assets/icons/chevron-down.svg";
import { ReactComponent as PlusIcon } from "app/assets/icons/plus-circle.svg";
import { ReactComponent as CheckIcon } from "app/assets/icons/check.svg";
import Text from "app/styles/Text";
import CreateProject from "./CreateProjectModal";
import useModalState from "app/hooks/useModalState";
import { ClassNames } from "@emotion/react";
import { useProfile } from "app/providers/ProfileProvider";
import { ProfileProject } from "app/models/profile";
import { useProject, useSetProject } from "app/providers/ProjectProvider";
import { capitalizeStr } from "app/utils/helpers";

export default function ProjectSelector() {
  const profile = useProfile();

  if (!profile) return <p></p>;

  return <LoadedContent projects={profile.projects} />;
}

interface Props {
  projects: ProfileProject[];
}

const LoadedContent = (props: Props) => {
  const { projects } = props;

  const project = useProject();
  const setProject = useSetProject();

  const { isOpen, closeModal, openModal } = useModalState();

  if (!project) return <p></p>;

  return (
    <Listbox
      as="div"
      tw="relative h-full"
      value={project}
      onChange={setProject}
      by="name"
    >
      <Listbox.Button as={ListBoxButton}>
        <Logo tw="w-[24px] h-[24px]" />

        <span>{project.name}</span>

        <Chevron className="dropdown" />
      </Listbox.Button>

      <ClassNames>
        {({ css }) => (
          <>
            {/* @ts-ignore */}
            <Transition
              enter={css([tw`transition duration-300 ease-out`])}
              enterFrom={css([tw`transform scale-95 opacity-0`])}
              enterTo={css([tw`transform scale-100 opacity-100`])}
              leave={css([tw`transition duration-150 ease-out`])}
              leaveFrom={css([tw`transform scale-100 opacity-100`])}
              leaveTo={css([tw`transform scale-95 opacity-0`])}
            >
              <Listbox.Options as={Dropdown}>
                {projects.map((project) => (
                  <Listbox.Option
                    as={ProjectOption}
                    key={project.name}
                    value={project}
                    className={({ active }) => clsx({ active })}
                  >
                    {({ selected }) => (
                      <>
                        <div>
                          <Logo tw="w-[32px] h-[32px] flex-shrink-0" />

                          <div tw="ml-[12px]">
                            <Text semiBold>{capitalizeStr(project.name)}</Text>

                            <Text subdued>{project.role.name}</Text>
                          </div>
                        </div>

                        {selected && <CheckIcon />}
                      </>
                    )}
                  </Listbox.Option>
                ))}

                <button onClick={openModal}>
                  <PlusIcon />
                  Create new project
                </button>
              </Listbox.Options>
            </Transition>
            <CreateProject open={isOpen} closeModal={closeModal} />
          </>
        )}
      </ClassNames>
    </Listbox>
  );
};

const ListBoxButton = styled.button`
  ${tw`h-full flex items-center text-textPrimary`};

  span {
    ${tw`flex-grow font-semibold text-base text-left mx-[8px] truncate`};
  }

  svg.dropdown {
    ${tw`w-[20px] h-[20px]`};

    path {
      ${tw`stroke-current`};
    }
  }
`;

const Dropdown = styled.div`
  ${tw`w-[240px] bg-white absolute shadow-lg border border-gray200 mt-[2px] rounded-[8px]`};

  > button {
    ${tw`flex items-center h-[54px] px-[16px] text-base font-medium`};

    svg {
      ${tw`mr-[8px] w-[16px] h-[16px]`};
    }
  }
`;

const ProjectOption = styled.button`
  ${tw`w-full h-[64px] flex items-center border-b border-b-gray200 px-[16px] first-of-type:rounded-t-[8px]`};

  &.active {
    ${tw`bg-gray50`};
  }

  > div {
    ${tw`flex-grow flex items-center text-left`};
  }

  > svg {
    ${tw`w-[16px] h-[16px] flex-shrink-0`};
  }
`;
