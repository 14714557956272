import { PropsWithChildren } from "react";
import tw from "twin.macro";
import styled from "@emotion/styled";
import * as Yup from "yup";

import Button from "app/components/ui/Button";
import TextField from "app/components/ui/TextField";
import Text from "app/styles/Text";
import Card from "app/components/ui/Card";
import { useForm } from "react-hook-form";
import { UpdateProfilePayload } from "app/api/profile/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { useProfile } from "app/providers/ProfileProvider";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import useUpdateProfile from "../hooks/useUpdateProfile";

const schema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
});

export default function ProfileForm() {
  const profile = useProfile();

  const { update, isUpdating } = useUpdateProfile();

  const {
    setValue,
    formState: { errors, isDirty },
    register,
    handleSubmit,
  } = useForm<UpdateProfilePayload>({
    resolver: yupResolver(schema),
    values: {
      first_name: profile?.first_name || "",
      last_name: profile?.last_name || "",
    },
  });

  useUpdatedEffect(() => {
    if (profile) {
      setValue("first_name", profile.first_name);
      setValue("last_name", profile.last_name);
    }
  }, [profile]);

  const onSubmit = handleSubmit((v) => update(v));

  return (
    <Card>
      <Form onSubmit={onSubmit}>
        <FormFieldsContainer>
          <ProfileFieldTile
            title="First name"
            description="As they appear on your government-issued ID"
          >
            <TextField
              label="First name"
              labelHidden
              placeholder="Oluwatobi"
              {...register("first_name")}
              error={!!errors.first_name}
              errorMessage={errors.first_name?.message}
            />
          </ProfileFieldTile>

          <ProfileFieldTile
            title="Last name"
            description="As they appear on your government-issued ID"
          >
            <TextField
              label="Last name"
              labelHidden
              placeholder="Mayowa"
              {...register("last_name")}
              error={!!errors.last_name}
              errorMessage={errors.last_name?.message}
            />
          </ProfileFieldTile>

          <ProfileFieldTile title="Email">
            <TextField
              label="Email"
              labelHidden
              placeholder="tobi@busha.co"
              value={profile?.email || ""}
              disabled
              readOnly
            />
          </ProfileFieldTile>
        </FormFieldsContainer>

        <ActionContainers>
          <Button variant="secondary" padded>
            Cancel
          </Button>

          <Button
            variant="primary"
            padded
            type="submit"
            loading={isUpdating}
            disabled={!isDirty}
          >
            Save
          </Button>
        </ActionContainers>
      </Form>
    </Card>
  );
}

interface ProfileFieldTileProps {
  title: string;
  description?: string;
}

const ProfileFieldTile = (props: PropsWithChildren<ProfileFieldTileProps>) => {
  const { title, description, children } = props;

  return (
    <Field>
      <div className="text">
        <Text mediumBold tw="text-gray700">
          {title}
        </Text>
        {description && <Text subdued> {description} </Text>}
      </div>

      <div tw="w-[512px] max-w-full">{children}</div>
    </Field>
  );
};

const Form = styled.form`
  ${tw`p-[24px]`}
`;

const FormFieldsContainer = styled.div`
  ${tw` flex flex-col gap-[36px]`}
`;

const Field = styled.div`
  ${tw`flex gap-[32px] items-center`};

  .text {
    ${tw`flex flex-col w-[320px] max-w-full`};
  }
`;

const ActionContainers = styled.div`
  ${tw`flex justify-end gap-x-[12px] mt-[16px] pt-[16px] border-t border-t-neutral`};
`;
