import styled from "@emotion/styled";
import tw from "twin.macro";
import { useState } from "react";

import Tabs from "app/components/ui/Tabs";
import TextField from "app/components/ui/TextField";
import Addresses from "./Addresses";
import Header from "app/styles/Header";

interface Props {
  openModal: () => void;
}

export default function WalletInfo(props: Props) {
  const [active, setActive] = useState(0);

  const { openModal } = props;

  return (
    <Tabs
      tabs={[{ label: "Addresses" }, { label: "Transactions" }]}
      selected={active}
      onSelect={setActive}
      tw="mt-[33px]"
    >
      <Container>
        <div tw="flex items-center justify-between my-[16px]">
          <div tw="w-[480px]">
            <TextField
              label="search"
              placeholder="Search wallets"
              labelHidden
            />
          </div>
        </div>
        {active === 0 && <Addresses openModal={openModal} />}
        {active === 1 && <Header>Transactions</Header>}
      </Container>
    </Tabs>
  );
}

const Container = styled.div`
  ${tw`mt-[40px]`}

  .details {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 40px 0;

    > div {
      display: flex;
      flex-direction: column;
      gap: 4px;

      p {
        width: 661px;

        &:first-of-type {
          ${tw`text-gray700`}
        }

        &:last-of-type {
          ${tw`text-gray600`}
        }
      }
    }
  }
`;
