import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";

import { resetPassword } from "app/api/auth";
import parseError from "app/lib/parseError";

const useForgotPassword = () => {
  const { isLoading, data, mutate, error } = useMutation(resetPassword);

  return {
    isLoading,
    response: data?.data,
    resetPassword: mutate,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
  };
};

export default useForgotPassword;
