import { ReactNode, useState } from "react";
import { NavLink } from "react-router-dom";
import tw, { css, styled } from "twin.macro";

import appLinks from "app/constants/appLinks";
import Text from "app/styles/Text";

import { ReactComponent as SettingsIcon } from "app/assets/icons/settings.svg";
import { ReactComponent as NotificationsIcon } from "app/assets/icons/notifications.svg";
import Toggle from "./ui/Toggle";
import TestBanner from "./ui/TestBanner";
import ProfileDropdown from "./ProfileDropdown";

interface Props {
  projectSelector: ReactNode;
}
export default function DashboardTopNav(props: Props) {
  const { projectSelector } = props;
  const [isTest, setIsTest] = useState(false);

  return (
    <Container>
      <NavigationSection tw="bg-gray50 relative z-10">
        <div>{projectSelector}</div>

        <div tw="flex gap-[16px]">
          <NotificationsIcon css={iconStyles} />

          <IconLink to="/settings">
            <SettingsIcon css={iconStyles} />
          </IconLink>

          <ProfileDropdown />
        </div>
      </NavigationSection>

      <NavigationSection>
        <Links>
          {appLinks.map(({ label, url, exact }) => {
            return (
              <li key={label}>
                <Link to={url} end={exact ?? true}>
                  {label}
                </Link>
              </li>
            );
          })}
        </Links>

        <Links tw="flex-grow-0">
          <Link to="/developers">Developers</Link>

          <Toggle
            labelLeft
            label={
              <Text css={[isTest ? tw`text-attention` : tw`text-textPrimary`]}>
                Test data
              </Text>
            }
            checked={isTest}
            onChange={setIsTest}
          />
        </Links>
      </NavigationSection>

      <TestBanner show={isTest} />
    </Container>
  );
}

const Container = styled.nav`
  ${tw`w-full h-full border-y border-y-gray200 relative`};
`;

const iconStyles = css`
  ${tw`w-[16px] h-[16px] text-textSecondary`};

  > path {
    ${tw`fill-current`};
  }
`;

const NavigationSection = styled.div`
  ${tw`px-[32px] h-[40px] flex justify-between items-center`};
`;

const Links = styled.ul`
  ${tw`flex items-center gap-[4px] flex-grow`};
`;

const Link = styled(NavLink)`
  ${tw`rounded-[40px] px-[10px] py-[2px] flex items-center text-base leading-[20px] text-textPrimary font-medium transition-colors hover:bg-gray100`};

  svg {
    ${tw`w-[24px] h-[24px] mr-[12px]`};

    path {
      ${tw`stroke-current`};
    }
  }

  &.active {
    ${tw`bg-brand text-white`};
  }
`;

const IconLink = styled(NavLink)`
  &.active {
    svg {
      ${tw`text-primary600`};
    }
  }
`;
