import { PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";
import tw, { styled } from "twin.macro";

import NavigationItem from "app/models/navigationItem";
import SubHeader from "app/styles/SubHeader";

interface Props {
  title: string;
  links: NavigationItem[];
}
export default function SideNavigation(props: PropsWithChildren<Props>) {
  const { title, links, children } = props;

  return (
    <div tw="w-full h-full flex gap-[40px] overflow-x-hidden overflow-hidden">
      <aside tw="w-[155px] sticky left-0">
        <SubHeader bold>{title}</SubHeader>

        <nav tw="flex flex-col gap-[4px] mt-[10px] py-[8px] border-l border-l-[rgba(108, 140, 171, 0.15)]">
          {links.map((link) => (
            <Link key={link.label} to={link.url} end={link.exact ?? true}>
              {link.label}
            </Link>
          ))}
        </nav>
      </aside>

      <section tw="flex-grow overflow-auto">{children}</section>
    </div>
  );
}

const Link = styled(NavLink)`
  ${tw`relative inline-block h-[24px] px-[12px] text-base text-textSecondary`};

  &::before {
    ${tw`content-[""] block h-full w-[2px] absolute left-[-1px]`};
  }

  &.active {
    ${tw`font-semibold text-primary800 before:bg-primary700`};
  }
`;
