import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import { getWebhookEndpoints } from "app/api/webhook";
import { ProfileProject } from "app/models/profile";
import { useProject } from "app/providers/ProjectProvider";
import useQueryParams from "app/hooks/useQueryParams";
import { GetWebhooksParams } from "app/api/webhook/types";

const useGetWebhooks = () => {
  const project = useProject() as ProfileProject;
  const { queryParams, updateQueryParams } = useQueryParams<GetWebhooksParams>({
    page: 1,
  });

  const { isLoading, isFetching, error, refetch, status, data } = useQuery(
    ["webhooks", project.id, ...Object.values(queryParams)],
    () => getWebhookEndpoints(project.id, queryParams)
  );

  const gotoNextPage = () => {
    if (data) {
      updateQueryParams("page", data.data.page + 1);
    }
  };

  const gotoPreviousPage = () => {
    if (data) {
      updateQueryParams("page", data.data.page - 1);
    }
  };

  return {
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getWebhooks: refetch,
    webhooks: data?.data.data,
    status,
    gotoNextPage,
    gotoPreviousPage,
    currentPage: data?.data.page || 1,
    totalPages: data?.data.total_pages || 1,
  };
};

export default useGetWebhooks;
