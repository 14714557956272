import { AxiosError } from "axios";
import { useInfiniteQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import { getCryptocurrencies } from "app/api/cryptocurrency";

const useGetInfiniteCryptoCurrencies = () => {
  const {
    isLoading,
    isFetching,
    error,
    refetch,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    data,
  } = useInfiniteQuery(
    ["crypto-currencies"],
    ({ pageParam = 1 }) => getCryptocurrencies({ page: pageParam }),

    {
      getNextPageParam: (lastPage, _) =>
        lastPage.data.page < lastPage.data.total_pages
          ? lastPage.data.page + 1
          : undefined,
    }
  );

  return {
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    currencies: data?.pages.map((page) => page.data.data).flat(),
    getCurrencies: refetch,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  };
};

export default useGetInfiniteCryptoCurrencies;
