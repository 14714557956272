import Modal from "app/components/ui/Modal";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";

import useAPIKeys from "../hooks/useAPIKeys";

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  apiKey: string;
  isActive: boolean;
}

export default function ModifyAPIKeyModal(props: Props) {
  const { isOpen, onClose, apiKey, isActive } = props;

  const { isDeactivating, deactivate, isActivating, activate } = useAPIKeys();

  const processAction = () => {
    if (isActive) {
      deactivate(apiKey, {
        onSuccess: onClose,
      });
    } else {
      activate(apiKey, {
        onSuccess: onClose,
      });
    }
  };

  return (
    <Modal
      title={`${isActive ? "Disable" : "Enable"} API key`}
      open={isOpen}
      onClose={onClose}
    >
      {isActive && (
        <div tw="px-[24px] pt-[20px]">
          <Text subdued tw="leading-[20px]">
            Disabling an API Key is temporary, you can always re-enable.
          </Text>
        </div>
      )}
      {isActive && <div tw="mt-[20px] border-t border-t-gray200"></div>}
      <div tw="px-[24px] mt-[20px]">
        <Text tw="leading-[20px]" subdued>
          {isActive
            ? `If any resources were created by “Groot main” API Key, they may be
          deleted soon after it is disabled. All code that uses this project's
          credentials to call this API will fail.`
            : `Are you sure you want to enable the API Key? It is recommended that you delete the existing key and create a new one if you believe the key has been compromised.`}
        </Text>
      </div>

      <div tw="mt-[32px] flex gap-[12px] p-[24px] border-t border-t-gray200">
        <Button fullWidth variant="secondary" text="Cancel" onClick={onClose} />

        <Button
          fullWidth
          variant={isActive ? "destructive" : "primary"}
          text={`${isActive ? "Disable" : "Enable"} API key`}
          onClick={processAction}
          loading={isActivating || isDeactivating}
        />
      </div>
    </Modal>
  );
}
