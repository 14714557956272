import { useLocation, useNavigate } from "react-router-dom";

import AuthPageContainer from "app/components/AuthPageContainer";
import LoginForm from "./components/LoginForm";
import { LoginResponse } from "app/api/auth/types";
import { useSetToken } from "app/providers/TokenProvider";
import { HOME_PATH } from "app/constants/variables";

export default function Login() {
  const setAppTokens = useSetToken();

  const navigate = useNavigate();
  const location = useLocation();

  const onLoginDone = (r: LoginResponse) => {
    const loginUser = () => {
      setAppTokens({
        access_token: r.token,
      });

      navigate(location.state ? location.state.from || HOME_PATH : HOME_PATH, {
        replace: true,
      });
    };

    if (r.token) {
      loginUser();
      return;
    }
  };

  return (
    <AuthPageContainer
      title="Log in to Bolt"
      description="Welcome back! Please enter your details."
    >
      <LoginForm onSuccess={onLoginDone} />
    </AuthPageContainer>
  );
}
