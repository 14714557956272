import { BaseHTMLAttributes } from "react";
import tw, { styled } from "twin.macro";
import clsx from "clsx";

import Text from "app/styles/Text";

interface Props extends BaseHTMLAttributes<HTMLDivElement> {
  outline?: boolean;
  dot?: boolean;
  trailing?: boolean;
  leading?: boolean;
  className?: string;
  icon?: React.ReactNode;
  iconOnly?: boolean;
  status:
    | "grey"
    | "primary"
    | "error"
    | "warning"
    | "success"
    | "blueGray"
    | "purple"
    | "orange";
}

export default function Badge(props: React.PropsWithChildren<Props>) {
  const {
    children,
    status,
    className,
    outline,
    dot,
    icon,
    leading,
    trailing,
    iconOnly,
    ...rest
  } = props;

  return (
    <Container
      className={clsx(
        status,
        {
          outline,
          iconOnly,
        },
        className
      )}
      {...rest}
    >
      <Text small mediumBold tw="flex items-center gap-[5px]">
        {iconOnly ? (
          icon
        ) : (
          <>
            {dot && (
              <span tw="border-[3px] border-current w-[6px] h-[6px] rounded-[100%]"></span>
            )}

            {leading && icon}

            {children}

            {trailing && icon}
          </>
        )}
      </Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 16px;
  width: fit-content;

  svg {
    width: 12px;
    height: 12px;
    align-self: center;

    path {
      ${tw`stroke-current`}
    }
  }

  &.primary {
    ${tw`bg-gray50 text-primary700`};
  }

  &.grey {
    ${tw`bg-gray100 text-gray700`}
  }

  &.error {
    ${tw`bg-red50 text-red700`}
  }

  &.warning {
    ${tw`bg-warning50 text-warning700`};
  }

  &.success {
    ${tw`bg-success50 text-success700`};
  }

  &.blueGray {
    ${tw`bg-grayBlue50 text-grayBlue700`};
  }

  &.purple {
    ${tw`bg-purple50 text-purple700`};
  }

  &.orange {
    ${tw`bg-orangeDark50 text-orange700`};
  }

  &.outline {
    border: 1.5px solid;
    background: #fff;
  }

  &.iconOnly {
    border-radius: 100%;
    padding: 4px;
    width: 20px;
    height: 20px;
  }
`;
