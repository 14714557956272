import { debounce } from "lodash";
import { ChangeEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import Tabs from "app/components/ui/Tabs";
import TextField from "app/components/ui/TextField";
import Table from "app/components/ui/Table";
import Text from "app/styles/Text";
import Badge from "app/components/ui/Badge";
import Menu from "app/components/ui/Menu";
import Pagination from "app/components/ui/Pagination";
import useCryptoCurrencies from "app/hooks/useCryptoCurrencies";
import useUpdatedEffect from "app/hooks/useUpdatedEffect";
import LoaderContainer from "app/components/ui/LoaderContainer";
import useWalletActions from "app/hooks/useWalletActions";
import Paths from "app/utils/paths";
import { GetWalletsParams, GetWalletsResponse } from "app/api/wallets/types";
import { formatMoneyNoCurrency } from "app/lib/money";
import { WalletsItem } from "app/models/wallet";

import CreateWalletModal from "./CreateWalletModal";

interface Props {
  data: GetWalletsResponse;
  query: GetWalletsParams;
  setQuery: <K extends keyof GetWalletsParams>(
    key: K,
    value: GetWalletsParams[K]
  ) => void;
  goToNextPage: VoidFunction;
  goToPreviousPage: VoidFunction;
  isLoading: boolean;
}

export default function WalletDepositTables(props: Props) {
  const { data, query, setQuery, goToNextPage, goToPreviousPage, isLoading } =
    props;

  const [queryString, setQueryString] = useState(query.q || "");

  const debouncedQueryUpdate = useMemo(
    () => debounce((searchString) => setQuery("q", searchString), 1000),
    [setQuery]
  );

  // const debouncedQueryUpdate = useCallback(
  //   (searchString: string) => setQuery("q", searchString),
  //   [setQuery]
  // );

  useUpdatedEffect(() => {
    debouncedQueryUpdate(queryString);
  }, [queryString]);

  const updateStatus = (n: number) => {
    setQuery("archived", n === 0 ? false : n === 1 ? true : undefined);
  };

  const handleChangeInQueryString = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setQueryString(value);
  };

  const selected =
    query.archived === undefined
      ? 2
      : query.archived.toString() === "true"
      ? 1
      : 0;

  return (
    <Tabs
      tabs={[{ label: "Active" }, { label: "Archive" }, { label: "All" }]}
      selected={selected}
      onSelect={updateStatus}
      tw="mt-[32px]"
    >
      <LoaderContainer loading={isLoading}>
        <div tw="flex items-center justify-between my-[16px]">
          <div tw="w-[480px]">
            <TextField
              label="search"
              placeholder="Search wallets"
              labelHidden
              value={queryString}
              onChange={handleChangeInQueryString}
            />
          </div>

          <CreateWalletModal />
        </div>
        <Table
          headings={[
            { content: "Assets" },
            { content: "Status" },
            { content: "Pending balance" },
            { content: "Total balance" },
            { content: "" },
          ]}
        >
          {data.data.length > 0 ? (
            data.data.map((wallet, index) => {
              return (
                <WalletDepositTableRow
                  key={`${wallet.id}-${index}`}
                  wallet={wallet}
                />
              );
            })
          ) : (
            <center>
              <p> No deposit </p>
            </center>
          )}
        </Table>

        {data.data.length > 0 && (
          <Pagination
            currentPage={data.page.toString()}
            totalPages={data.total_pages.toString()}
            previousPageAction={goToPreviousPage}
            nextPageAction={goToNextPage}
            disableNext={!(data.total_pages > data.page)}
            disablePrevious={!(data.page > 1)}
          />
        )}
      </LoaderContainer>
    </Tabs>
  );
}

interface WalletDepositTableRowProps {
  wallet: WalletsItem;
}

const WalletDepositTableRow = (props: WalletDepositTableRowProps) => {
  const { wallet } = props;
  const assetIDWithoutTest = wallet.asset_id.split("_")[0];

  const navigate = useNavigate();
  const { getCurrency } = useCryptoCurrencies();

  const navigateToDetailsView = () => {
    navigate(`/wallets/deposits/${wallet.id}`);
  };

  const currency = getCurrency(assetIDWithoutTest.toUpperCase());

  const metaData = useMemo(() => {
    const pendingBalancePrice =
      Number(wallet.pending_balance) * Number(currency?.price || 1);
    const totalBalancePrice =
      Number(wallet.total_balance) * Number(currency?.price || 1);
    const priceCurrency = currency?.price_currency || assetIDWithoutTest;

    return {
      pendingBalancePrice,
      totalBalancePrice,
      priceCurrency,
    };
  }, [
    assetIDWithoutTest,
    currency?.price,
    currency?.price_currency,
    wallet.pending_balance,
    wallet.total_balance,
  ]);

  const { archive, unArchive, isArchiving, isUnarchiving } = useWalletActions();

  const modifyWalletStatus = () => {
    if (wallet.archived) {
      unArchive(wallet.id);
    } else {
      archive(wallet.id);
    }
  };

  return (
    <Table.Row>
      <Table.Cell
        tw="w-[40%] hover:cursor-pointer"
        onClick={navigateToDetailsView}
      >
        <div tw="flex gap-[12px]">
          <img
            src={Paths.coinSVG(assetIDWithoutTest.toUpperCase())}
            alt={assetIDWithoutTest}
            tw="rounded-full w-[40px] h-[40px]"
          />
          <div>
            <Text mediumBold> {wallet.asset_id} </Text>
            <Text subdued tw="capitalize">
              {wallet.name || assetIDWithoutTest.toUpperCase()}
            </Text>
          </div>
        </div>
      </Table.Cell>

      <Table.Cell>
        <Badge outline status={wallet.archived ? "blueGray" : "success"}>
          {wallet.archived ? "Archived" : "Active"}
        </Badge>
      </Table.Cell>

      <Table.Cell>
        <Text mediumBold>
          {`${formatMoneyNoCurrency(
            Number(wallet.pending_balance),
            "crypto"
          )} ${wallet.asset_id}`}
        </Text>

        <Text subdued>
          {`${formatMoneyNoCurrency(
            Number(metaData.pendingBalancePrice),
            "fiat"
          )} ${metaData.priceCurrency}`}
        </Text>
      </Table.Cell>

      <Table.Cell>
        <Text mediumBold>{`${formatMoneyNoCurrency(
          Number(wallet.total_balance),
          "crypto"
        )} ${wallet.asset_id}`}</Text>

        <Text subdued>
          {`${formatMoneyNoCurrency(
            Number(metaData.totalBalancePrice),
            "fiat"
          )} ${metaData.priceCurrency}`}
        </Text>
      </Table.Cell>

      <Table.Cell>
        <Menu
          options={[
            {
              content: wallet.archived ? "Unarchive" : "Archive",
              onAction: modifyWalletStatus,
              critical: !wallet.archived,
              disabled: isArchiving || isUnarchiving,
            },
          ]}
        />
      </Table.Cell>
    </Table.Row>
  );
};
