import { Link } from "react-router-dom";
import tw, { styled } from "twin.macro";

import Page from "app/components/ui/Page";
import LoaderContainer from "app/components/ui/LoaderContainer";
import NetworkLoader from "app/components/ui/NetworkLoader";

import WebhookEvents from "./components/WebhookEvents";
import WebhookInfo from "./components/WebhookInfo";
import useGetWebhook from "./hooks/useGetWebhook";

export default function WebhookDetails() {
  const { isLoading, isFetching, webhook, retry, error } = useGetWebhook();

  return (
    <Page
      title={
        <PageHeader>
          <Link to="/developers/webhooks">Webhooks</Link>

          {webhook && <WebhookInfo webhook={webhook} />}
        </PageHeader>
      }
    >
      <LoaderContainer
        loading={isLoading}
        error={!!error}
        errorMessage={error}
        onRetry={retry}
      >
        {isFetching && <NetworkLoader />}
        {webhook && <WebhookEvents />}
      </LoaderContainer>
    </Page>
  );
}

const PageHeader = styled.div`
  ${tw`w-full flex flex-col gap-[16px]`};

  > a {
    ${tw`text-base text-primary700 font-semibold`};
  }
`;
