import { Navigate, RouteObject } from "react-router-dom";

import { HOME_PATH, LOGIN_PATH, SIGN_OUT_PATH } from "app/constants/variables";

import ProfileProvider from "app/providers/ProfileProvider";
import DashboardLayout from "app/components/DashboardLayout";
import Header from "app/styles/Header";
import Login from "app/views/Login";
import Signout from "app/views/Signout";
import ForgotPassword from "app/views/ForgotPassword";
import ProjectSelector from "app/components/ProjectSelector";
import Invite from "app/views/Invite";
import DashboardTopNav from "app/components/DashboardTopNav";
import Projects from "app/views/Projects";
import DevelopersRoutes from "app/routes/components/DevelopersRoutes";
import APIKeys from "app/views/APIKeys";
import Webhooks from "app/views/Webhooks";
import SettingsRoutes from "./components/SettingsRoutes";
import WebhookDetails from "app/views/WebhookDetails";
import Profile from "app/views/Profile";
import ChangePassword from "app/views/ChangePasswords";
import Members from "app/views/Members";
import WalletsRoutes from "./components/WalletsRoutes";
import WalletDeposits from "app/views/WalletDeposits";
import WalletDetails from "app/views/WalletDetails";

const routesObject: RouteObject[] = [
  {
    path: "/accept-invitation",
    element: <Invite />,
  },
  {
    path: `/${SIGN_OUT_PATH}`,
    element: <Signout />,
  },
  {
    path: "forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "change-password",
    element: <ChangePassword />,
  },
  {
    path: `/${LOGIN_PATH}`,
    element: <Login />,
  },
  {
    element: (
      <ProfileProvider>
        {/* <AuthRoutesWrapper> */}
        <DashboardLayout
          topnav={<DashboardTopNav projectSelector={<ProjectSelector />} />}
        />
        {/* </AuthRoutesWrapper> */}
      </ProfileProvider>
    ),
    children: [
      {
        path: HOME_PATH,
        element: <Header semiBold>Welcome back, Abolaji</Header>,
      },
      {
        path: "wallets",
        element: <WalletsRoutes />,
        children: [
          { index: true, element: <Navigate to="deposits" /> },
          { path: "deposits", element: <WalletDeposits /> },
          {
            path: "*",
            element: <Header semiBold> 404 👀</Header>,
          },
        ],
      },
      { path: "wallets/deposits/:id", element: <WalletDetails /> },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "settings",
        element: <SettingsRoutes />,
        children: [
          { index: true, element: <Navigate to="projects" /> },
          {
            path: "projects",
            element: <Projects />,
          },
          {
            path: "members",
            element: <Members />,
          },
        ],
      },
      {
        path: "developers",
        element: <DevelopersRoutes />,
        children: [
          { index: true, element: <Navigate to="api-keys" /> },
          { path: "api-keys", element: <APIKeys /> },
          { path: "webhooks", element: <Webhooks /> },
          {
            path: "*",
            element: <Header semiBold> 404 👀</Header>,
          },
        ],
      },
      { path: "developers/webhooks/:id", element: <WebhookDetails /> },
      {
        path: "*",
        element: <Header semiBold> 404 👀</Header>,
      },
    ],
  },
];

export default routesObject;
