import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";
import { useState } from "react";

import Badge from "app/components/ui/Badge";
import Header from "app/styles/Header";
import Text from "app/styles/Text";
import Menu from "app/components/ui/Menu";
import useModalState from "app/hooks/useModalState";
import { WebhookEndpoint } from "app/models/webhook";

import ActionModals from "./ActionModals";

export type actionModalType = "enable" | "disable" | "delete";

interface Props {
  webhook: WebhookEndpoint;
}

export default function WebhookInfo(props: Props) {
  const { webhook } = props;

  const { isOpen, closeModal, openModal } = useModalState();

  const [action, setAction] = useState<actionModalType>("enable");

  const showModal = (a: actionModalType) => () => {
    openModal();
    setAction(a);
  };

  return (
    <Container>
      <div tw="flex items-center justify-between border-b border-b-gray200">
        <Header medium> {webhook.url} </Header>

        <Menu
          options={[
            {
              content:
                webhook.status === "enabled" ? "Disable..." : "Enable...",
              onAction:
                webhook.status === "enabled"
                  ? showModal("disable")
                  : showModal("enable"),
            },
            {
              content: "Delete...",
              critical: true,
              onAction: showModal("delete"),
            },
          ]}
        />
      </div>

      <div tw="flex gap-[24px] divide-x divide-gray200">
        <div>
          <Text subdued mediumBold tw="mb-[8px]">
            Status
          </Text>

          <Badge
            status={webhook.status === "enabled" ? "success" : "error"}
            tw="capitalize"
          >
            {webhook.status}
          </Badge>
        </div>

        {/* <div tw="pl-[24px]">
          <Text subdued mediumBold tw="mb-[8px]">
            Listening for
          </Text>

          <Badge status="grey">24 events</Badge>
        </div> */}

        <div tw="pl-[24px]">
          <Text subdued mediumBold tw="mb-[8px]">
            API version
          </Text>

          <Link className="webhook-link" to="">
            {webhook.api_version}
          </Link>
        </div>

        <div tw="pl-[24px]">
          <Text subdued mediumBold tw="mb-[8px]">
            Configuration
          </Text>

          <Link className="webhook-link" to="">
            View logs
          </Link>
        </div>
      </div>

      <ActionModals isOpen={isOpen} close={closeModal} type={action} />
    </Container>
  );
}

const Container = styled.div`
  ${tw`w-full flex flex-col gap-[16px]`};

  a.webhook-link {
    ${tw`text-base text-primary700 font-medium border-b border-b-current`};
  }
`;
