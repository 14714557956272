import { ChangeEvent, useState } from "react";

import Button from "app/components/ui/Button";
import Modal from "app/components/ui/Modal";
import TextField from "app/components/ui/TextField";
import useModalState from "app/hooks/useModalState";
import Text from "app/styles/Text";

import useModifyTwoFA from "../hooks/useModifyTwoFA";

export default function DisableTwoFAModal() {
  const { isOpen, openModal, closeModal } = useModalState();
  const [password, setPassword] = useState("");

  const updatePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);
  };

  const { isDisabling, disable } = useModifyTwoFA();

  const disableTwoFA = () => {
    disable(
      { password },
      {
        onSuccess: closeModal,
      }
    );
  };

  return (
    <>
      <Button padded variant="secondary" onClick={openModal}>
        Disable authentication
      </Button>

      <Modal
        open={isOpen}
        onClose={closeModal}
        title="Turn off 2-Step Verification?"
      >
        <div tw="px-[24px] pt-[24px]">
          <Text subdued tw="pb-[20px] border-b border-b-gray200">
            Turning off 2-Step Verification will remove the extra security on
            your account, and you'll only use your password to sign in.
          </Text>

          <Text subdued tw="mt-[20px] mb-[16px]">
            Enter your password to disable Two step authentication.
          </Text>

          <TextField
            type="password"
            label="Password"
            value={password}
            onChange={updatePassword}
          />
        </div>

        <div tw="mt-[32px] p-[24px] border-t border-t-gray200 flex gap-[12px]">
          <Button
            fullWidth
            variant="secondary"
            text="Keep 2FA"
            onClick={closeModal}
          />

          <Button
            fullWidth
            variant="primary"
            text="Disable 2FA"
            onClick={disableTwoFA}
            loading={isDisabling}
          />
        </div>
      </Modal>
    </>
  );
}
