import { useMutation, useQueryClient } from "@tanstack/react-query";
import { disableTwoFA, enableTwoFA } from "app/api/profile";
import { BaseResponse } from "app/api/types";
import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import { AxiosError, AxiosResponse } from "axios";

const useModifyTwoFA = () => {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const handleSuccess = (response: AxiosResponse<BaseResponse>) => {
    queryClient.invalidateQueries(["profile"]);
    showToast("", response.data.message || "", "success");
  };

  const handleError = (error: AxiosError<{}>) => {
    const errorMessage = parseError(error) as string;
    showToast("", errorMessage || "", "error");
  };

  const {
    isLoading: isEnabling,
    mutate: enable,
    error: enableError,
  } = useMutation(enableTwoFA, {
    onSuccess: handleSuccess,
  });

  const { isLoading: isDisabling, mutate: disable } = useMutation(
    disableTwoFA,
    {
      onError: handleError,
      onSuccess: handleSuccess,
    }
  );

  return {
    isEnabling,
    enable,
    enableError: enableError
      ? (parseError(enableError as AxiosError<{}>) as string)
      : undefined,
    isDisabling,
    disable,
  };
};

export default useModifyTwoFA;
