import * as Yup from "yup";
import tw, { styled } from "twin.macro";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import Modal from "app/components/ui/Modal";
import TextField from "app/components/ui/TextField";
import Button from "app/components/ui/Button";
import useCreateProject from "app/hooks/useCreateProject";
import { CreateProjectPayload } from "app/api/project/types";

import TextArea from "./ui/TextArea";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string()
    .required("Description is required")
    .max(40, "Cannot exceed 40 characters"),
});

interface Props {
  open: boolean;
  closeModal: () => void;
}

export default function CreateProject(props: Props) {
  const { open, closeModal } = props;

  const { createProject, isCreating } = useCreateProject();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateProjectPayload>({
    values: {
      name: "",
      description: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = handleSubmit((v) =>
    createProject(
      {
        name: v.name,
        description: v.description,
      },
      {
        onSuccess: closeModal,
      }
    )
  );

  return (
    <Modal
      open={open}
      onClose={closeModal}
      title="Create a new project"
      subTitle="Enter details for a new project"
    >
      <ModalContent onSubmit={onSubmit}>
        <div className="main">
          <TextField
            label="Project name"
            containerClassName="input"
            placeholder="Groot"
            {...register("name")}
            error={!!errors.name}
            errorMessage={errors.name?.message}
          />

          <TextArea
            label="Project Description"
            id="area"
            assistiveText="40 Characters left"
            containerClassName="textarea"
            {...register("description")}
            error={!!errors.description}
            errorMessage={errors.description?.message}
            placeholder="Store, buy and sell crypto assets"
          />

          {/* <Card tw="rounded-[12px] w-full">
            <div className="upload">
              <Upload />

              <Text>
                <button>Add a project logo</button> or drag and drop
              </Text>

              <Text small>SVG, PNG, JPG or GIF (max. 800x400px)</Text>
            </div>
          </Card> */}
        </div>

        <div className="action">
          <Button
            variant="primary"
            fullWidth
            type="submit"
            loading={isCreating}
          >
            Create project
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.form`
  .main {
    padding: 20px 24px 32px 24px;

    .upload {
      padding: 16px 24px;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        ${tw`text-gray600`}
      }

      button {
        font-weight: 600;
        font-size: 14px;
        ${tw`text-primary900`}
      }

      svg {
        margin-bottom: 12px;
        ${tw`w-[40px] h-[40px]`}
      }
    }

    .textarea {
      margin: 16px 0;
    }

    .check {
      margin-top: 20px;
      > p {
        width: 100%;
      }
    }

    .input {
      > label {
        font-weight: 500;
        font-size: 14px;
      }
    }

    .consent {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-bottom: 1px solid #eaecf0;
      padding-bottom: 16px;
      p {
        ${tw`text-gray600`}
      }
    }
  }
  .action {
    padding: 24px;
    border-top: 1px solid #eaecf0;
  }
`;
