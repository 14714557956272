import { styled } from "twin.macro";
import Modal from "app/components/ui/Modal";
import Text from "app/styles/Text";
import Button from "app/components/ui/Button";
import SubHeader from "app/styles/SubHeader";
import TextField from "app/components/ui/TextField";
import ListBox from "app/components/ui/ListBox";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ProfileProject } from "app/models/profile";
import Role from "app/models/role";
import Member from "app/models/member";
import useProjectMemberActions from "../hooks/useProjectMemberActions";

interface Props {
  open: boolean;
  closeModal: () => void;
  roles: Role[];
  project: ProfileProject;
  selectedMember: Member;
}

type formPayload = {
  role_id: string;
};

const schema = Yup.object().shape({
  role_id: Yup.string().required("Role is required"),
});

export default function EditMember(props: Props) {
  const { open, closeModal, roles, selectedMember, project } = props;
  const { isChangingMemberRole, changeMemberRole } = useProjectMemberActions();

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<formPayload>({
    resolver: yupResolver(schema),
    values: {
      role_id: selectedMember?.role.id,
    },
  });

  const onSubmit = handleSubmit((v) =>
    changeMemberRole({
      role_id: v.role_id,
      id: project.id,
      member_id: selectedMember?.id,
    })
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        title={
          <img
            src="https://res.cloudinary.com/dhm8wnpd6/image/upload/v1684420523/Avatar_dcb9gf.svg"
            alt="Edit member"
          />
        }
        tw="overflow-visible"
      >
        <ModalContent onSubmit={onSubmit}>
          <div className="main">
            <div className="header">
              <SubHeader medium>Edit member</SubHeader>
              <Text subdued>Change name or role of this member</Text>
            </div>

            <div tw="flex flex-col gap-[16px]">
              <div className="name">
                <TextField
                  label="First name"
                  containerClassName="input"
                  disabled
                  value={selectedMember?.first_name}
                />
                <TextField
                  label="Last name"
                  containerClassName="input"
                  disabled
                  value={selectedMember?.last_name}
                />
              </div>

              <TextField
                label="Email"
                containerClassName="input"
                value={selectedMember?.email}
                disabled
              />

              <Controller
                name="role_id"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <ListBox
                    placeholder="Admin"
                    label="Role"
                    error={!!errors.role_id}
                    errorMessage={errors.role_id?.message}
                    options={roles.map((c) => ({
                      label: c.name,
                      value: c.id,
                    }))}
                    containerClassName="listbox"
                    value={value}
                    onChange={(i) => onChange(i.value)}
                  />
                )}
              />
            </div>
          </div>

          <div className="action">
            <Button
              variant="secondary"
              fullWidth
              type="reset"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              fullWidth
              type="submit"
              loading={isChangingMemberRole}
            >
              Save changes
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}

const ModalContent = styled.form`
  .main {
    padding: 16px 24px 24px 24px;

    .header {
      padding-bottom: 20px;
    }

    .name {
      display: flex;
      gap: 10px;
    }

    .listbox {
      margin-bottom: 8px;
    }

    .input {
      > label {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .action {
    display: flex;
    gap: 12px;
    padding: 24px;
    border-top: 1px solid #eaecf0;
  }
`;
