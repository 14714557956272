import Header from "app/styles/Header";
import Text from "app/styles/Text";
import tw from "twin.macro";

import { formatMoneyNoCurrency } from "app/lib/money";
import { BalanceSummary } from "app/models/balance";

interface Props {
  summary: BalanceSummary;
}

export default function Balances(props: Props) {
  const { summary } = props;

  return (
    <LayoutContainer>
      <Container>
        <BalanceSection>
          <Text medium>Total Balance</Text>

          <Header semiBold tw="text-[2.4rem] leading-[32px]">
            {`${summary.balance_currency} ${formatMoneyNoCurrency(
              Number(summary.total_balance),
              "fiat"
            )}`}
          </Header>

          <Text mediumBold>
            <span tw="text-success700">{`${summary.change_24h}% `}</span>
            vs last day
          </Text>
        </BalanceSection>

        <BalanceSection>
          <Text medium>Pending Balance</Text>

          <Header semiBold tw="text-[2.4rem] leading-[32px]">
            {`${summary.balance_currency} ${formatMoneyNoCurrency(
              Number(summary.pending_balance),
              "fiat"
            )}`}
          </Header>

          <Text mediumBold>
            <span tw="text-success700">{`${summary.change_24h}% `}</span>
            vs last day
          </Text>
        </BalanceSection>

        <BalanceSection>
          <Text medium>Available Balance</Text>

          <Header semiBold tw="text-[2.4rem] leading-[32px]">
            {`${summary.balance_currency} ${formatMoneyNoCurrency(
              Number(summary.balance),
              "fiat"
            )}`}
          </Header>

          <Text mediumBold>
            <span tw="text-success700">{`${summary.change_24h}% `}</span>
            vs last day
          </Text>
        </BalanceSection>
      </Container>
    </LayoutContainer>
  );
}

const LayoutContainer = tw.div`border border-gray200 rounded-[8px]`;

const Container = tw.div` flex divide-x-[1px] divide-gray200`;

const BalanceSection = tw.div`px-[16px] py-[20px] grow flex flex-col gap-[16px]`;
