import { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import { BaseResponse } from "app/api/types";
import { archiveWallet, unArchiveWallet } from "app/api/wallets";
import { ProfileProject } from "app/models/profile";
import { useProject } from "app/providers/ProjectProvider";
import { useToast } from "app/providers/ToastProvider";

const useWalletActions = () => {
  const project = useProject() as ProfileProject;
  const showToast = useToast();
  const queryClient = useQueryClient();

  const handleSuccess = (res: AxiosResponse<BaseResponse>) => {
    queryClient.invalidateQueries(["wallets"]);
    showToast("", res.data.message || "", "success");
  };

  const handleError = (err: AxiosError<{}>) => {
    const errorMessage = parseError(err) as string;
    showToast("", errorMessage, "error");
  };

  const { isLoading: isArchiving, mutate: archive } = useMutation(
    (id: string) => archiveWallet(project.id, id),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  const { isLoading: isUnarchiving, mutate: unArchive } = useMutation(
    (walletId: string) => unArchiveWallet(project.id, walletId),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  return {
    isArchiving,
    archive,

    isUnarchiving,
    unArchive,
  };
};

export default useWalletActions;
