import { Switch } from "@headlessui/react";
import Text from "app/styles/Text";
import { isString } from "app/utils/helpers";
import { ReactNode } from "react";
import tw from "twin.macro";

interface Props {
  label?: ReactNode;
  supportingText?: string;
  medium?: boolean;
  labelLeft?: boolean;
  name?: string;
  disabled?: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export default function Toggle(props: Props) {
  const {
    label,
    supportingText,
    medium,
    labelLeft,
    name,
    disabled,
    checked,
    onChange,
  } = props;

  return (
    <Switch.Group>
      <div tw="flex items-start gap-[8px]">
        <Switch
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          name={name}
          css={[
            checked
              ? tw` bg-primary600 hover:bg-primary700`
              : tw`bg-gray100 hover:bg-gray200`,
            medium ? tw`h-[24px] w-[44px]` : tw`h-[20px] w-[36px]`,
            tw`relative order-2 inline-flex items-center rounded-[12px] transition-colors focus:outline-none focus:ring-[4px] focus:ring-[#F4EBFF] focus:ring-offset-0 disabled:bg-gray100`,
          ]}
        >
          <span
            css={[
              checked
                ? [medium ? tw`translate-x-[22px]` : tw`translate-x-[18px]`]
                : tw`translate-x-[2px]`,
              medium ? tw`h-[20px] w-[20px]` : tw`h-[16px] w-[16px]`,
              tw`inline-block transform rounded-full bg-white transition-transform origin-right`,
            ]}
          />
        </Switch>

        {label ? (
          <Switch.Label css={[labelLeft ? tw`order-1` : tw`order-2`]}>
            {isString(label) ? (
              <div tw="flex flex-col">
                <Text mediumBold tw="text-gray700 text-base">
                  {label}
                </Text>

                {supportingText ? <Text subdued>{supportingText}</Text> : null}
              </div>
            ) : (
              label
            )}
          </Switch.Label>
        ) : null}
      </div>
    </Switch.Group>
  );
}
