import tw, { styled } from "twin.macro";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { ClassNames } from "@emotion/react";

import Text from "app/styles/Text";
import { ReactComponent as Selected } from "app/assets/icons/check.svg";
import { ReactComponent as DropdownIcon } from "app/assets/icons/chevron-down.svg";
import SelectOption from "app/models/selectOption";

interface Props {
  label?: string;
  labelHidden?: boolean;
  placeholder: string;
  value?: string;
  options: SelectOption[];
  onChange?: (i: SelectOption) => void;
  disabled?: boolean;
  assistiveText?: string;
  error?: boolean;
  errorMessage?: string;
  containerClassName?: string;
}

export default function ListBox(props: Props) {
  const {
    value,
    onChange,
    labelHidden = false,
    options,
    label,
    placeholder,
    disabled,
    assistiveText,
    error,
    errorMessage,
    containerClassName,
  } = props;

  const selected = options?.find((o) => o.value === value);

  return (
    <Container className={containerClassName}>
      <label className={clsx({ hidden: labelHidden })}>{label}</label>

      {/* @ts-ignore */}
      <Listbox value={selected} onChange={onChange} disabled={disabled}>
        <div tw="relative">
          {/* @ts-ignore */}
          <Listbox.Button
            as={InputContainer}
            className={clsx({ error, disabled })}
            tabIndex={0}
          >
            {selected ? (
              <Text medium mediumBold>
                {selected.label}
              </Text>
            ) : (
              <Text tw="text-gray500" medium mediumBold>
                {placeholder}
              </Text>
            )}

            <DropdownIcon className="dropdown" />
          </Listbox.Button>

          <ClassNames>
            {({ css }) => (
              <>
                {/* @ts-ignore */}
                <Transition
                  as={Fragment}
                  enter={css([tw`transition duration-100 ease-out`])}
                  enterFrom={css([tw`transform scale-95 opacity-0`])}
                  enterTo={css([tw`transform scale-100 opacity-100`])}
                  leave={css([tw`transition duration-75 ease-out`])}
                  leaveFrom={css([tw`transform scale-100 opacity-100`])}
                  leaveTo={css([tw`transform scale-95 opacity-0`])}
                >
                  {/* @ts-ignore */}
                  <Listbox.Options as={Dropdown}>
                    {options.map((option) => (
                      // @ts-ignore
                      <Listbox.Option
                        as={Fragment}
                        key={option.value}
                        value={option}
                      >
                        {({ selected }) => (
                          <Option key={option.value}>
                            <Text medium mediumBold>
                              {option.label}
                            </Text>

                            {selected && <Selected className="selected-icon" />}
                          </Option>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </>
            )}
          </ClassNames>
        </div>
      </Listbox>

      {!!assistiveText ? (
        <Text className="assistive-text">{assistiveText}</Text>
      ) : null}

      {!!errorMessage ? (
        <Text className="error-message">{errorMessage}</Text>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  > label {
    ${tw`block mb-[6px] text-base font-medium text-gray700`};

    &.hidden {
      ${tw`hidden`};
    }
  }

  > p {
    ${tw`mt-[8px]`};

    &.assistive-text {
      ${tw`text-gray600`};
    }

    &.error-message {
      ${tw`text-red700`};
    }
  }
`;

const InputContainer = styled.div`
  ${tw`px-[14px] py-[8px] flex justify-between items-center cursor-pointer w-full h-[40px]`}
  ${tw`shadow-xs transition-colors rounded-[8px] border border-gray300 active:border-primary700 focus:border-primary700`}
  
  .dropdown {
    ${tw`w-[20px] h-[20px] text-gray500`};
    path {
      ${tw`stroke-current`};
    }
  }

  &.error {
    ${tw`border-red500 shadow-xsError100`};
  }

  &.disabled {
    ${tw`bg-gray50 shadow-xs border-gray300`};
  }
`;

const Dropdown = styled.div`
  ${tw`w-full rounded-[8px] border border-gray200 bg-white`};
  ${tw`min-h-[152px] mt-[8px] absolute z-10 w-full py-[12px] px-[6px]`};
  ${tw`shadow-lg`};
  ${tw`max-h-[300px] overflow-y-auto`}
`;

const Option = styled.div`
  ${tw`flex justify-between items-center px-[8px] py-[10px]`};

  ${tw`hover:bg-gray50 rounded-[6px]`};

  .selected-icon {
    ${tw`w-[20px] h-[20px] flex-shrink-0 text-primary600`};

    path {
      ${tw`stroke-current`};
    }
  }
`;
