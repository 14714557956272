import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import { getBalanceSummary } from "app/api/wallets";
import { ProfileProject } from "app/models/profile";
import { WalletType } from "app/models/wallet";
import { useProject } from "app/providers/ProjectProvider";

interface Props {
  type: WalletType;
}

const useGetBalanceSummary = (props: Props) => {
  const { type } = props;
  const project = useProject() as ProfileProject;

  const { isLoading, isFetching, error, refetch, data } = useQuery(
    ["balance-summary", type],
    () => getBalanceSummary(project.id, { type })
  );

  return {
    isLoading,
    isFetching,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    getSummary: refetch,
    summary: data?.data.data.find(
      (s) => s.type.toLowerCase() === type.toLowerCase()
    ),
  };
};

export default useGetBalanceSummary;
