import { useQuery } from "@tanstack/react-query";
import { getRoles } from "app/api/roles";
import parseError from "app/lib/parseError";
import { AxiosError } from "axios";

const useGetRoles = () => {
  const { data, error, refetch, isLoading } = useQuery(["roles"], getRoles);

  return {
    roles: data?.data.data,
    rolesError: error
      ? (parseError(error as AxiosError<{}>) as string)
      : undefined,
    getRoles: refetch,
    isRolesLoading: isLoading,
  };
};

export default useGetRoles;
