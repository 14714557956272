import { Outlet } from "react-router-dom";
import SideNavigation from "../../components/ui/SideNavigation";

export default function DevelopersRoutes() {
  return (
    <SideNavigation
      title="Developers"
      links={[
        { url: "api-keys", label: "API Keys" },
        { url: "webhooks", label: "Webhooks", exact: false },
      ]}
    >
      <Outlet />
    </SideNavigation>
  );
}
