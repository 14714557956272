import { ChangeEvent, useState } from "react";
import tw from "twin.macro";

import Button from "app/components/ui/Button";
import Modal from "app/components/ui/Modal";
import TextField from "app/components/ui/TextField";
import useModalState from "app/hooks/useModalState";
import { ReactComponent as Plus } from "app/assets/icons/plus.svg";

import useAPIKeys from "../hooks/useAPIKeys";

interface Props {
  isInitial?: boolean;
}
export default function CreateAPIKeyModal({ isInitial = false }: Props) {
  const { isOpen, openModal, closeModal } = useModalState();
  const [name, setName] = useState("");

  const { create, isCreating } = useAPIKeys();

  const handleChangeInName = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);
  };

  const createAPIKey = () => {
    create(
      { name },
      {
        onSuccess: () => {
          setName("");
          closeModal();
        },
      }
    );
  };

  return (
    <>
      <Button
        type="button"
        variant="primary"
        icon={<Plus />}
        text={isInitial ? "Generate API key" : "Create new key"}
        onClick={openModal}
        css={isInitial ? tw`w-[210px]` : undefined}
      />

      <Modal
        title="Create API Key"
        subTitle="Set up your webhook endpoint to receive live events from Raven"
        open={isOpen}
        onClose={closeModal}
      >
        <div tw="p-[24px] flex flex-col gap-[32px]">
          <TextField label="Name" value={name} onChange={handleChangeInName} />

          <div tw="flex gap-[12px]">
            <Button
              fullWidth
              variant="secondary"
              text="Cancel"
              onClick={closeModal}
            />

            <Button
              fullWidth
              variant="primary"
              text="Create API Key"
              disabled={!name}
              loading={isCreating}
              onClick={createAPIKey}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
