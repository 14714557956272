import { ProjectItem } from "app/models/project";
import React from "react";

const useSortProject = (projects: ProjectItem[]) => {
  const [sortConfig, setSortConfig] = React.useState<{
    key: keyof ProjectItem;
    direction: "ascending" | "descending";
  }>({
    key: "name",
    direction: "ascending",
  });

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...projects];

    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (sortConfig.key === "member_count") {
          if (Number(a[sortConfig.key]) < Number(b[sortConfig.key])) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (Number(a[sortConfig.key]) > Number(b[sortConfig.key])) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        }

        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }

        return 0;
      });
    }

    return sortableItems;
  }, [projects, sortConfig]);

  const sortProject = (type: keyof ProjectItem) => {
    let direction: "ascending" | "descending" = "ascending";

    if (sortConfig.key === type && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    setSortConfig({ key: type, direction });
  };

  return { projects: sortedItems, sortProject, sortConfig };
};

export default useSortProject;
