import { APP_THEME_STORAGE_KEY, LOGIN_PATH } from "app/constants/variables";
import history from "./history";
import storage from "./storage";

export interface LocationState {
  from?: string;
  logout?: boolean;
}

export default function logout(from = "/") {
  const appTheme = storage.get(APP_THEME_STORAGE_KEY);

  storage.clear();

  if (appTheme) {
    storage.set(APP_THEME_STORAGE_KEY, appTheme);
  }

  history.replace(`/${LOGIN_PATH}`, { from });
}
