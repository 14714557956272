import tw from "twin.macro";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ClassNames } from "@emotion/react";
import { Link } from "react-router-dom";

import useLogin from "../hooks/useLogin";
import Button from "app/components/ui/Button";
import CheckBox from "app/components/ui/CheckBox";
import TextField from "app/components/ui/TextField";
import { LoginPayload, LoginResponse } from "app/api/auth/types";

const schema = Yup.object().shape({
  email: Yup.string().required("Email is required").email(),
  password: Yup.string().required("Password is required"),
});

interface Props {
  onSuccess: (d: LoginResponse) => void;
}
export default function LoginForm(props: Props) {
  const { onSuccess: onDone } = props;

  const { isLoading, login } = useLogin();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<LoginPayload>({
    resolver: yupResolver(schema),
    values: { email: "", password: "" },
  });

  const onSubmit = handleSubmit((v) =>
    login(v, {
      onSuccess(data) {
        onDone(data.data);
      },
    })
  );

  return (
    <form tw="w-full" onSubmit={onSubmit}>
      <TextField
        label="Email"
        type="email"
        placeholder="youremail@example.com"
        {...register("email")}
        error={!!errors.email}
        errorMessage={errors.email?.message}
      />

      <ClassNames>
        {({ css }) => (
          <TextField
            containerClassName={css(tw`mt-[20px]`)}
            label="Password"
            type="password"
            placeholder="Enter passsword"
            {...register("password")}
            error={!!errors.password}
            errorMessage={errors.password?.message}
          />
        )}
      </ClassNames>

      <div tw="mt-[24px] flex justify-between">
        <CheckBox label="Remember for 30 days" {...register("remember_me")} />

        <Link
          to="/forgot-password"
          tw="font-semibold text-base text-primary900 hover:text-primary800"
        >
          Forgot password
        </Link>
      </div>

      <Button
        fullWidth
        type="submit"
        variant="primary"
        tw="mt-[24px]"
        loading={isLoading}
      >
        Log in
      </Button>
    </form>
  );
}
