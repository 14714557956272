import { useState } from "react";

import Button from "app/components/ui/Button";
import Modal from "app/components/ui/Modal";
import useModalState from "app/hooks/useModalState";
import CodeView from "./CodeView";
import VerificationForm from "./VerificationForm";

type viewType = "code" | "form";

export default function SetupTwoFAModal() {
  const { isOpen, openModal, closeModal } = useModalState();

  const [view, setView] = useState<viewType>("code");
  const [secret, setSecret] = useState("");

  const openForm = (d: string) => {
    setSecret(d);
    setView("form");
  };

  return (
    <>
      <Button padded variant="secondary" onClick={openModal}>
        Set up two-step authentication
      </Button>

      <Modal
        open={isOpen}
        onClose={closeModal}
        title="Use an authenticator app"
      >
        {view === "code" && (
          <CodeView onCancel={closeModal} onContinue={openForm} />
        )}

        {view === "form" && secret && (
          <VerificationForm
            onGoBack={() => setView("code")}
            onConfirm={() => {
              closeModal();
            }}
            secret={secret}
          />
        )}
      </Modal>
    </>
  );
}
