import { Popover, Transition } from "@headlessui/react";
import tw, { css } from "twin.macro";
import clsx from "clsx";

import { ReactComponent as PersonIcon } from "app/assets/icons/person.svg";
import { Link, NavLink } from "react-router-dom";
import { useProfile } from "app/providers/ProfileProvider";
import Text from "app/styles/Text";
import { SIGN_OUT_PATH } from "app/constants/variables";
import { ClassNames } from "@emotion/react";
import { Fragment } from "react";

export default function ProfileDropdown() {
  const profile = useProfile();

  return (
    <Popover tw="relative">
      <NavLink to="/profile" onClick={(e) => e.preventDefault()}>
        {({ isActive }) => (
          <Popover.Button as="button" tw="outline-none block">
            <PersonIcon
              css={iconStyles}
              className={clsx({ active: isActive })}
            />
          </Popover.Button>
        )}
      </NavLink>
      <ClassNames>
        {({ css }) => (
          <>
            {/* @ts-ignore */}
            <Transition
              as={Fragment}
              enter={css([tw`transition duration-100 ease-out`])}
              enterFrom={css([tw`transform scale-95 opacity-0`])}
              enterTo={css([tw`transform scale-100 opacity-100`])}
              leave={css([tw`transition duration-75 ease-out`])}
              leaveFrom={css([tw`transform scale-100 opacity-100`])}
              leaveTo={css([tw`transform scale-95 opacity-0`])}
            >
              <Popover.Panel tw="w-[240px] absolute right-0 z-10 mt-[11px] bg-white border border-gray200 shadow-lg rounded-[8px]">
                {({ close }) => (
                  <>
                    {profile && (
                      <div tw="py-[12px] px-[16px]">
                        <Text semiBold tw="text-gray700">
                          {`${profile.last_name} ${profile.first_name}`}
                        </Text>

                        <Text subdued>Viewer</Text>
                      </div>
                    )}

                    <div tw="border-t border-t-gray200">
                      <Link
                        to="/profile"
                        onClick={() => close()}
                        css={linkStyles}
                      >
                        Profile
                      </Link>

                      <Link
                        to={`/${SIGN_OUT_PATH}`}
                        css={linkStyles}
                        className="signout"
                      >
                        Log out
                      </Link>
                    </div>
                  </>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </ClassNames>
    </Popover>
  );
}

const iconStyles = css`
  ${tw`w-[16px] h-[16px] text-textSecondary`};

  > path {
    ${tw`fill-current`};
  }

  &.active {
    ${tw`text-primary600`};
  }
`;

const linkStyles = css`
  ${tw`block text-base text-gray700 py-[9px] px-[16px]`};

  &.signout {
    ${tw`text-critical`};
  }
`;
