import tw from "twin.macro";
import * as Yup from "yup";
import { ClassNames } from "@emotion/react";
import { Link, useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import TextField from "app/components/ui/TextField";
import Button from "app/components/ui/Button";
import Text from "app/styles/Text";

import useChangePassword from "../hooks/useChangePassword";

const schema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Must be at least 8 characters"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Passwords don't match")
    .required("Confirm Password is required"),
});

interface Props {
  onSuccess: () => void;
}

export default function NewPasswordsForm(props: Props) {
  const { onSuccess } = props;

  const { isLoading, changePassword, error } = useChangePassword();

  const [searchParams] = useSearchParams();

  const passwordToken = searchParams.get("token");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{ password: string; confirm_password: string }>({
    resolver: yupResolver(schema),
    values: {
      password: "",
      confirm_password: "",
    },
  });

  const onSubmit = handleSubmit((v) =>
    changePassword(
      {
        password: v.password,
        password_token: passwordToken as string,
      },
      {
        onSuccess: (_) => onSuccess(),
      }
    )
  );

  return (
    <form tw="w-full" onSubmit={onSubmit}>
      <TextField
        label="New password"
        type="password"
        assistiveText="Must be at least 8 characters"
        placeholder="*********"
        {...register("password")}
        error={!!errors.password}
        errorMessage={errors.password?.message || error}
      />
      <ClassNames>
        {({ css }) => (
          <TextField
            label="Confirm new password"
            type="password"
            containerClassName={css(tw`mt-[20px]`)}
            placeholder="********"
            {...register("confirm_password")}
            error={!!errors.confirm_password}
            errorMessage={errors.confirm_password?.message}
          />
        )}
      </ClassNames>
      <Button
        type="submit"
        variant="primary"
        fullWidth
        tw="mt-[24px]"
        loading={isLoading}
      >
        Set new password
      </Button>

      <div tw="flex mt-[32px] justify-center">
        <Text subdued>
          Remembered password?{" "}
          <Link
            to="/login"
            tw="font-semibold text-base text-primary900 hover:text-primary800"
          >
            Back to log in
          </Link>
        </Text>
      </div>
    </form>
  );
}
