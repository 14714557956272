import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { useParams } from "react-router-dom";

import parseError from "app/lib/parseError";
import { BaseResponse } from "app/api/types";
import {
  activateWebhookEndpoint,
  deactivateWebhookEndpoint,
  deleteWebhookEndpoint,
  resendWebhook,
} from "app/api/webhook";
import { ProfileProject } from "app/models/profile";
import { useProject } from "app/providers/ProjectProvider";
import { useToast } from "app/providers/ToastProvider";

const useModifyWebhook = () => {
  const { id } = useParams<{ id: string }>();
  const project = useProject() as ProfileProject;
  const queryClient = useQueryClient();
  const showToast = useToast();

  const handleSuccess = (
    res: AxiosResponse<BaseResponse>,
    eventId?: string
  ) => {
    queryClient.invalidateQueries(["webhooks", id]);
    if (!!eventId) {
      queryClient.invalidateQueries(["webhook-events", id]);
    }
    showToast("", res.data.message || "", "success");
  };

  const handleError = (error: AxiosError<{}>) => {
    const errorMessage = parseError(error) as string;
    showToast("", errorMessage, "error");
  };

  const { isLoading: isEnabling, mutate: enable } = useMutation(
    () => activateWebhookEndpoint(project.id, id as string),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  const { isLoading: isDisabling, mutate: disable } = useMutation(
    () => deactivateWebhookEndpoint(project.id, id as string),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  const { isLoading: isDeleting, mutate: deleteHook } = useMutation(
    () => deleteWebhookEndpoint(project.id, id as string),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  const { isLoading: isResendingEvent, mutate: resendWebhookEvent } =
    useMutation((eventID: string) => resendWebhook(id as string, eventID), {
      onSuccess: (res, v) => handleSuccess(res, v),
      onError: handleError,
    });

  return {
    isEnabling,
    enable,

    //
    isDisabling,
    disable,

    //
    isDeleting,
    deleteHook,

    //
    isResendingEvent,
    resendWebhookEvent,
  };
};

export default useModifyWebhook;
