import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import parseError from "app/lib/parseError";
import { useToast } from "app/providers/ToastProvider";
import { deleteProject, updateProject } from "app/api/project";

const useProjectActions = () => {
  const showToast = useToast();
  const queryClient = useQueryClient();

  const handleSuccess = (message: string) => {
    queryClient.invalidateQueries(["projects"]);
    queryClient.invalidateQueries(["profile"]);
    showToast("", message, "success");
  };

  const handleError = (error: AxiosError<{}>) => {
    const errorMessage = parseError(error) as string;
    showToast("", errorMessage, "error");
  };

  const { isLoading: isDeleting, mutate: deleteSelectedProject } = useMutation(
    deleteProject,
    {
      onSuccess: (d) => handleSuccess(d.data.message || ""),
      onError: handleError,
    }
  );

  const { isLoading: isUpdating, mutate: updateSelectedProject } = useMutation(
    updateProject,
    {
      onSuccess: (_, v) =>
        handleSuccess(`Project ${v.name} updated successfully`),
      onError: handleError,
    }
  );

  return {
    isDeleting,
    deleteSelectedProject,
    isUpdating,
    updateSelectedProject,
  };
};

export default useProjectActions;
