import tw, { styled } from "twin.macro";

import { COIN_IMG_BASE_URL } from "app/constants/variables";
import { ReactComponent as QrCode } from "app/assets/icons/qr-code.svg";

import Button from "app/components/ui/Button";
import Header from "app/styles/Header";
import Text from "app/styles/Text";
import WalletInfo from "./components/WalletInfo";
import useModalState from "app/hooks/useModalState";
import QRCodeModal from "./components/QRCodeModal";
import Page from "app/components/ui/Page";

export default function WalletDetails() {
  const { isOpen, openModal, closeModal } = useModalState();

  return (
    <Page>
      <Container>
        <div className="header">
          <div className="left">
            <img
              src={`${COIN_IMG_BASE_URL}/BTC.svg`}
              alt="Bitcoin"
              tw="rounded-full w-[64px] h-[64px]"
            />
            <div>
              <Header medium semiBold>
                Bitcoin
              </Header>
              <Text medium>Deposit Wallet</Text>
            </div>
          </div>

          <div className="right">
            <Button variant="secondary" onClick={openModal}>
              <QrCode />
              Show QR code
            </Button>
          </div>
        </div>
        <WalletInfo openModal={openModal} />
      </Container>
      <QRCodeModal isOpen={isOpen} close={closeModal} />
    </Page>
  );
}

const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    ${tw`border-b border-b-gray200 pb-[20px]`}

    .left {
      display: flex;
      align-items: center;
      gap: 20px;

      h1 {
        ${tw`text-gray900`}
      }

      p {
        ${tw`text-gray600`}
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    .right {
      display: flex;
      gap: 12px;
    }
  }
`;
