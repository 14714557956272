import Page from "app/components/ui/Page";
import Balances from "app/components/Balances";
import LoaderContainer from "app/components/ui/LoaderContainer";
import useCryptoCurrencies from "app/hooks/useCryptoCurrencies";
import useGetBalanceSummary from "app/hooks/useGetBalanceSummary";
import NetworkLoader from "app/components/ui/NetworkLoader";

import WalletDepositTables from "./components/WalletDepositsTable";
import useGetWalletDeposits from "./hooks/useGetWalletDeposits";

export default function WalletDeposits() {
  const {
    isLoading: isLoadingDeposits,
    isFetching,
    data,
    error: depositsError,
    getDeposits,
    updateQueryParams,
    queryParams,
    goToNextPage,
    goToPreviousPage,
  } = useGetWalletDeposits({ keepPreviousData: true });

  const {
    isLoading: isLoadingCurrencies,
    error: currenciesError,
    getCurrencies,
  } = useCryptoCurrencies();

  const {
    isLoading: isLoadingSummary,
    isFetching: isFetchingSummary,
    error: summaryError,
    getSummary,
    summary,
  } = useGetBalanceSummary({ type: "deposit" });

  const retry = () => {
    if (depositsError) {
      getDeposits();
    }
    if (currenciesError) {
      getCurrencies();
    }
    if (summaryError) {
      getSummary();
    }
  };

  return (
    <Page title="Deposits">
      <LoaderContainer
        loading={isLoadingDeposits || isLoadingCurrencies || isLoadingSummary}
        error={!!depositsError || !!currenciesError || !!summaryError}
        errorMessage={depositsError || currenciesError || summaryError}
        onRetry={retry}
      >
        {isFetchingSummary && <NetworkLoader />}
        {summary && <Balances summary={summary} />}
        {data && (
          <>
            <WalletDepositTables
              data={data}
              query={queryParams}
              setQuery={updateQueryParams}
              goToNextPage={goToNextPage}
              goToPreviousPage={goToPreviousPage}
              isLoading={isFetching}
            />
          </>
        )}
      </LoaderContainer>
    </Page>
  );
}
