import { AxiosError } from "axios";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import useQueryParams from "app/hooks/useQueryParams";
import parseError from "app/lib/parseError";
import { PaginationMeta } from "app/api/types";
import { getWebhookEvents } from "app/api/webhook";
import { GetWebhookEventsParams } from "app/api/webhook/types";

const useGetWebhookEvents = () => {
  const { id } = useParams<{ id: string }>();

  const { queryParams, updateQueryParams } =
    useQueryParams<GetWebhookEventsParams>({
      page: 1,
    });

  const { isLoading, isFetching, data, error, refetch } = useQuery(
    ["webhook-events", id, ...Object.values(queryParams)],
    () => getWebhookEvents(id as string, queryParams)
  );

  const goToNextPage = () => {
    if (data) {
      updateQueryParams("page", data.data.page + 1);
    }
  };

  const goToPreviousPage = () => {
    if (data) {
      updateQueryParams("page", data.data.page - 1);
    }
  };

  const pagination = useMemo(() => {
    if (data?.data) {
      const eventsData = data.data;
      return {
        page: eventsData.page,
        per_page: eventsData.per_page,
        total_pages: eventsData.total_pages,
        current_entries_size: eventsData.current_entries_size,
        offset: eventsData.offset,
        total_entries_size: eventsData.total_entries_size,
      } as PaginationMeta;
    }

    return null;
  }, [data?.data]);

  return {
    isLoading,
    isFetching,
    events: data?.data.data,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
    retry: refetch,
    pagination,
    goToNextPage,
    goToPreviousPage,
    queryParams,
    updateQueryParams,
  };
};

export default useGetWebhookEvents;
