import AuthPageContainer from "app/components/AuthPageContainer";
import InviteForm from "./components/InviteForm";

export default function Invite() {
  return (
    <AuthPageContainer
      title="Create a staff account"
      description="Finalize your details to gain access to Bolt."
    >
      <InviteForm />
    </AuthPageContainer>
  );
}
