import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";

import parseError from "app/lib/parseError";
import { createAccount } from "app/api/auth";
import { CreateAccountResponse } from "app/api/auth/types";
import { HOME_PATH } from "app/constants/variables";
import { useToast } from "app/providers/ToastProvider";
import { useSetToken } from "app/providers/TokenProvider";

const useInvite = () => {
  const navigate = useNavigate();
  const showToast = useToast();
  const setAppTokens = useSetToken();

  const handleSuccess = (d: AxiosResponse<CreateAccountResponse>) => {
    setAppTokens({
      access_token: d.data.token,
    });
    showToast("", "Account created successfully", "success");
    navigate(HOME_PATH, { replace: true });
  };

  const handleError = (error: AxiosError<any>) => {
    const errorMessage = parseError(error) as string;
    showToast("", errorMessage, "error");
  };

  const { isLoading, data, mutate, error } = useMutation(createAccount, {
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return {
    isLoading,
    response: data?.data,
    invite: mutate,
    error: error ? (parseError(error as AxiosError<{}>) as string) : undefined,
  };
};

export default useInvite;
