import tw, { styled } from "twin.macro";

import { ReactComponent as Barcode } from "app/assets/images/barcode.svg";

import Modal from "app/components/ui/Modal";
import Text from "app/styles/Text";
import CopyButton from "app/components/ui/CopyButton";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export default function QRCodeModal(props: Props) {
  const { isOpen, close } = props;

  return (
    <Modal
      open={isOpen}
      onClose={close}
      title="Bitcoin QR code"
      subTitle="Deposit address for BTC"
    >
      <ModalContent>
        <div className="content">
          <div className="code">
            <Barcode />
          </div>
        </div>

        <div className="address">
          <div tw="w-[320px]">
            <Text mediumBold small tw="text-gray500">
              BTC Address
            </Text>

            <Text tw="w-full break-words text-gray600">
              0bttx00b03d90cb88107f38e64e475b75eb23ccac79f8
            </Text>
          </div>

          <CopyButton text="0bttx00b03d90cb88107f38e64e475b75eb23ccac79f8" />
        </div>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.form`
  padding: 24px 36px 32px 24px;

  .content {
    .code {
      padding: 32px 120px 32px 120px;
    }
    ${tw`border border-gray200 rounded-t-[4px]`}
  }

  .address {
    display: flex;
    gap: 10px;
    ${tw`border border-gray200 p-[16px] justify-between rounded-b-[4px] `}
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .actions {
    display: flex;
    gap: 12px;
    margin-top: 32px;
  }
`;
