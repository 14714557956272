import { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import parseError from "app/lib/parseError";
import { BaseResponse } from "app/api/types";
import { createWebhookEndpoint } from "app/api/webhook";
import { CreateWebhookPayload } from "app/api/webhook/types";
import { ProfileProject } from "app/models/profile";
import { useProject } from "app/providers/ProjectProvider";
import { useToast } from "app/providers/ToastProvider";

const useCreateWebhook = () => {
  const project = useProject() as ProfileProject;
  const showToast = useToast();
  const queryClient = useQueryClient();

  const handleSuccess = (res: AxiosResponse<BaseResponse>) => {
    queryClient.invalidateQueries(["webhooks"]);
    showToast("", res.data.message || "", "success");
  };

  const handleError = (error: AxiosError<{}>) => {
    const errorMessage = parseError(error) as string;
    showToast("", errorMessage, "error");
  };

  const { isLoading, mutate } = useMutation(
    (payload: CreateWebhookPayload) =>
      createWebhookEndpoint(project.id, payload),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  );

  return {
    isCreating: isLoading,
    create: mutate,
  };
};

export default useCreateWebhook;
